import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { catchError, map, tap } from 'rxjs/operators';
import { StudyGenerationCommentModel } from 'src/app/study/manage-study/generation-comment.model';

@Injectable({
  providedIn: 'root'
})
export class StudyGenerationCommentService {

  constructor(private _http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  public createGenerationComment(studyGenerationComment: StudyGenerationCommentModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/study-generation-comments"
    let body = JSON.stringify(studyGenerationComment);

    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Added new Generation Comment"))
      );
  }

  public getGenerationComment(studyId: number, generationName: string): any {
    let url = ConfigurationSettings.REST_API_URL + "/study/" + studyId + "/generation/" + generationName + "/study-generation-comments";
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched Generation Comment")));
  }

  public updateGenerationComment(studyGenerationComment: StudyGenerationCommentModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/study-generation-comments"
    let body= JSON.stringify(studyGenerationComment);
    return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log(" Generation Comment"))
            );
    }

}
