
export class StudyAssayModel {
    StudyGenerationAssayId: number
    StudyId: number
    GenerationId: number
    Generation: string
    AssayId: number
    PassCriteria: string
    Comments: string
    StudyGenerationId: number
    AssayCategoryTypeDetailIds: number[]
}