
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../loading/res-loading.service';

import { RoleModel } from './role.model';
import { RoleService } from '../services/role-service/role.service'

@Component({
    selector: 'app-role',
    templateUrl: './role.component.html',
    styleUrls: ['./role.component.css'],
    providers: [RoleService, ConfirmationService, MessageService]
})

export class RoleComponent implements OnInit {

    constructor(private roleService: RoleService, private confirmationService: ConfirmationService, private messageService: MessageService) { }
    ngOnInit() {
        this.GetRoles();
        this.roleTableHeaders = [
            { field: 'RoleId', header: ' Role Id', display: 'none' },
            { field: 'Name', header: ' Name', display: 'table-cell' },
            { field: 'Description', header: ' Description', display: 'table-cell' },
            { field: 'IsActive', header: ' Is Active', display: 'table-cell' },
            { field: 'CreatedDate', header: ' Created Date', display: 'table-cell' },
            { field: 'CreatedBy', header: ' Created By', display: 'table-cell' },
            { field: 'ModifiedDate', header: ' Modified Date', display: 'table-cell' },
            { field: 'ModifiedBy', header: ' Modified By', display: 'table-cell' },
        ]
    }

    roleTableHeaders: any[]

    roles: RoleModel[];
    role = new RoleModel();
    selectedRole: RoleModel;
    serviceResponse: any;
    isEditable: boolean = false
    dialogHeaderText: any = "Add new";

    //Property to display dialog
    displayDialog: boolean = false;

    //Method to display dialog
    AddnewItem() {
        this.role = {};
        this.displayDialog = true;
        this.isEditable = false;
        this.dialogHeaderText = "Add new";
    }

    //Method to save dialog
    Save() {
        console.log(this.role);
        this.InsertRole(this.role)
        this.displayDialog = false;
        this.role = {};
    }

    Edit(data) {
        this.selectedRole = data;
        console.log(this.selectedRole);
        this.role = this.selectedRole;
        this.displayDialog = true;
        this.isEditable = true;
        this.dialogHeaderText = "Edit";
    }

    Delete() {
        console.log(this.role);
        this.deleteRole(this.role.RoleId);
        this.displayDialog = false;
        this.role = {};
    }

    Update() {
        this.role = this.selectedRole;
        this.UpdateRole(this.role);
        this.displayDialog = false;
        this.role = {};
    }

    //Method to cancel dialog
    Cancel() {
        this.displayDialog = false;
        this.role = {};
    }

    private InsertRole(role) {
        this.roleService.createRole(this.role).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    private GetRoles() {
        this.roleService.getRoles().subscribe(response => this.roles = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: error.message })
            },
            () => { console.log(this.roles) })
    }

    private UpdateRole(role) {
        this.roleService.updateRole(this.role).subscribe(res => this.serviceResponse = res,
            error => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: '', detail: error.message })

            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    private deleteRole(Id) {
        this.roleService.deleteRole(this.role.RoleId).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: error.message })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

}

