

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import{ PMOProgramModel } from '../../PMOPortal/PMOProgram.model';
import{ PMOProjectModel } from '../../PMOPortal/PMOProject.model';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class PMOPortalService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private _http:HttpClient) { }

  public getPrograms(searchTerm):any{
    let url = ConfigurationSettings.REST_API_URL + "/pmoportal/programs/" + searchTerm
    return this._http.get(url).pipe(
        tap(x => console.log("Fetched Programs")));
    }


    public getProjects(progamKey):any{
        let url = ConfigurationSettings.REST_API_URL + "/pmoportal/programs/" + progamKey + "/projects"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Projects")));
        }
    

}