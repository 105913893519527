import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import{ CropModel } from '../../Administrator/crop/crop.model';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class CropService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private _http:HttpClient) { }

  public getCrops():any{
    let url=ConfigurationSettings.REST_API_URL + "/crops"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Crops")));
    }
}