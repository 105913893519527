import { SearchableDropdownModel } from './searchableDropdownModel';

//------------------------------------------------
//Utility to convert model to Searcable dropdown dataset
//-----------------------------------------------
export class SearchableDropdownUtility {

    dropdownLabelValueList: SearchableDropdownModel[]
    dropdownModel: SearchableDropdownModel;

    //---------------------------------------------------
    //Method to convert model to PrimeNg P-dropdown data set
    //-----------------------------------------------------
    public ConvertToFilterDropdownModel(dropdownList: any[], labelName: string, labelId:string) {
        this.dropdownLabelValueList = [];
        if (dropdownList != null) {
            dropdownList.forEach(element => {
                this.dropdownModel = new SearchableDropdownModel();
                this.dropdownModel.label = element[labelName];
                this.dropdownModel.value = element[labelId];
                this.dropdownLabelValueList.push(this.dropdownModel);
            });
        }
        return this.dropdownLabelValueList;
    }
}