export class AssayCategoryTypeDetailModel{
    AssayCategoryTypeDetailId? :number
    AssayCategoryTypeId? :number
    Name? :string
    IsActive? :string
    CreatedDate? :Date
    CreatedBy? :number
    UserFullName? :string
    AssayCategoryTypeName? :string
    GeneSetId?: number
    GeneSetName?: string
}
