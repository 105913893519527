export class StudyKeyContactModel{
    StudyKeyContactId? :number
    StudyId? :number
    CreatedDate? :Date
    CreatedBy? :number
    Email? :string
    FirstName? :string
    LastName? :string
    DisplayName?:string
}
