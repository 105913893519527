import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AssayService } from 'src/app/services/assay-service/assay.service';
//import { and } from '@angular/router/src/utils/collection';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { GenerationConstants } from 'src/app/Constants/generation-constants';
import { StatusConstants } from 'src/app/Constants/status-constants';
import { StudyConstants } from 'src/app/Constants/study-constants';
import { UserFunctionConstants } from 'src/app/Constants/user-function-constants';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { LoadingService } from 'src/app/loading/res-loading.service';
import { SearchableDropdownModel } from 'src/app/services/UtilityService/searchableDropdownModel';
import { SearchableDropdownUtility } from 'src/app/services/UtilityService/searchableDropdownUtility';
import { PermissionService } from 'src/app/services/permission-service/permission.service';
import { StudyService } from 'src/app/services/study-service/study.service';
import { StudyAssayService } from 'src/app/services/study-service/studyAssay.service';
import { StudyKeyContactService } from 'src/app/services/studykeycontact-service/studykeycontact.service';
import { UserService } from 'src/app/services/user-service/user.service';
import { UserModel } from 'src/app/user/user.model';
import { ViewStudyCommentLogModel } from '../../view-study/view-study-comment-log.model';
import { StudyAssaySelectionModel } from '../study-assay-selection/study-assay-selection.model';
import { StudyGeneralInformation } from '../study-generalInformation.model';
import { StudyKeyContactModel } from '../study-keycontact.model';
import { StudyOwnerUpdateModel } from '../study-owner-update.model';
import { StudyStatusModel } from '../study-status.model';
import { RoleConstants } from 'src/app/Constants/role-constants';
import { TICAuthService } from 'src/app/services/auth-service/tic-auth.service';

@Component({
  selector: 'app-study-preview',
  templateUrl: './study-preview.component.html',
  styleUrls: ['./study-preview.component.scss'],
  providers: [AssayService, MessageService, StudyAssayService, StudyService, ConfirmationService, PermissionService, StudyKeyContactService, UserService],
})
export class StudyPreviewComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder, private assayService: AssayService, private messageService: MessageService, private studyAssayService: StudyAssayService,
    private studyService: StudyService, private confirmationService: ConfirmationService, private loader: LoadingService, private router: Router,
    private permissionService: PermissionService, private studyKeyContactService: StudyKeyContactService, private userService: UserService,
    private ticAuthService: TICAuthService) { }

  ngOnInit() {
    this.loader.setMessage("Please wait...");
    this.displayProgessIcon = true;
    this.GetStudyGeneralInformation(this.savedStudyId);
    this.GetStudyCommentLogs(this.savedStudyId);
    this.GetStudyProject(this.savedStudyId);
    this.GetStudyGenerations(this.savedStudyId);

    if (this.pageFrom != "Preview") {
      this.showActionPanel = false;
      this.displayCommentsLog = false;
    }
  }

  @Input() savedStudyId: number
  @Input() pageFrom: string
  @Output() studyEvengerRequestDeletionSuccessEvent= new EventEmitter<null>();
  @Input() generationName: string;

  displayCommentsLog: boolean = false;
  @Input() savedStudyGeneralInfo: StudyGeneralInformation;

  savedStudyAssays: StudyAssaySelectionModel[];

  isSubmitted: boolean = false;
  isValidStudy: boolean = true;
  showActionPanel: boolean = true;
  errorMessage: string;
  studyGenerationsData: any;


  getGenerationDetails(value){
    this.generationName = value;
  }

  isActiveCategory(name){
    if(this.generationName ===  name){
        return true;
    }
}
  /**
  * Method to fetch study general information
  * @param studyId
  */
  GetStudyGeneralInformation(studyId: number) {
    //this.loader.setMessage("Loading Study...")
    this.studyService.getStudyByStudyId(studyId).subscribe(response => this.savedStudyGeneralInfo = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {

        if(this.savedStudyGeneralInfo.IsStudyOwner && this.savedStudyGeneralInfo.Status == StatusConstants.NEEDMOREINFO){
          this.router.navigate(['/createstudy/' + this.savedStudyId]);
        }
        //Adding User full name property to display in textbox.
        this.savedStudyGeneralInfo.KeyContacts.forEach(element => {
          element.DisplayName = element.LastName + ", " + element.FirstName;
        });

        if (this.savedStudyGeneralInfo.Status == StatusConstants.NEEDMOREINFO && this.pageFrom == "Preview") {
          this.displayCommentsLog = true;
        }

        this.notifyParentOnUpdate.emit({ studyGeneralInfo: this.savedStudyGeneralInfo });

      })
  }

  GetStudyGenerations(studyId: number) {
    this.loader.setMessage("Loading Study Generations...")
    this.studyService.GetStudyGenerations(studyId).subscribe(response => this.studyGenerationsData = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },()=>{
        this.generationName = this.studyGenerationsData[0]?.GenerationName;
      })
  }


  pmoProject:any;
  displayTimeOutMessage:boolean = false;
  displayProgessIcon:boolean = false;
  GetStudyProject(projectKey){
    this.studyService.GetStudyProject(projectKey).subscribe(response => this.pmoProject = response,
      error => {
        this.displayTimeOutMessage = true;
        this.displayProgessIcon = false;
      },
      () => {
        this.displayProgessIcon = false;
      })
  }

  validationMessage: string
  Review() {
    this.isValidStudy = true;
    this.isSubmitted = true;
      this.confirmationService.confirm({
        message: 'Are you sure, do you want to submit this study for review?',
        header: 'Confirmation',
        accept: () => {
          this.createStudyStatus(this.prepareStudyStatus(this.savedStudyId, StatusConstants.SUBMITTED_FOR_REVIEW))
        },
        reject: () => {
        }
      });
  }

  /**
 *
 * @param studyId
 * @param status
 */
  prepareStudyStatus(studyId, status) {
    let studyStatus = new StudyStatusModel();
    studyStatus.StudyId = studyId,
      studyStatus.Status = status,
      studyStatus.Comments = null;

    return studyStatus;

  }

  /**
   *
   * @param studyStatusModel
   */
  private createStudyStatus(studyStatusModel: StudyStatusModel) {
    this.studyService.createStudyStatus(this.savedStudyGeneralInfo.StudyId, studyStatusModel).subscribe(res => res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while adding. Contact Administrator' })
      },
      () => {
        this.messageService.add({ severity: 'success', summary: '', detail: 'Study has been Submitted for Review' })
        setTimeout(() => {
          this.router.navigate(['/study']);
        }, 1000);
      });
  }


  @Output() PreviousButtonEvent = new EventEmitter<{ studyId: number, stepName:string }>();
  @Output() CloseButtonEvent = new EventEmitter<string>();

  @Output() notifyParentOnUpdate: EventEmitter<any> = new EventEmitter<{ studyGeneralInfo: StudyGeneralInformation }>();

  Previous() {
    this.PreviousButtonEvent.emit({ studyId: this.savedStudyId, stepName: StudyConstants.STUDY_PREVIEW_CONSTANT.toString() });
  }

  Close() {
    this.CloseButtonEvent.emit();
  }

  studyCommentLogs: ViewStudyCommentLogModel[];

  /**
 * Method to fetch commentlogs for study
 */
  private GetStudyCommentLogs(studyId) {
    this.studyService.getStudyCommentLogs(studyId).subscribe(response => this.studyCommentLogs = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      })
  }

  EditGenerations() {
    this.router.navigate(['/study/' + this.savedStudyId + '/editassay']);
  }

  EditGeneralInfo() {
    this.router.navigate(['/study/' + this.savedStudyId + '/editgeneralinfo']);
  }

  public userFunctions: typeof UserFunctionConstants = UserFunctionConstants;
  public statusConstants: typeof StatusConstants=StatusConstants;

  /**
* Method to check logged-in user is access to take appropriate action i.e. Review, Need More Info & Cancel
* these button will be displayed only when stusy is submitted for review
*/
  showUserActions(functionName: string) {
    if (this.savedStudyGeneralInfo) {
      if (this.permissionService.isUserPermittedToAccess(functionName) && this.savedStudyGeneralInfo.Status == StatusConstants.REVIEWED) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  /**
   * Method to show edit button for PI(who created study) to modify study keycontacts
   */
  showEditStudyKeyContacts(functionName: string) {
    if (this.pageFrom != "Preview" && this.savedStudyGeneralInfo.IsStudyOwner &&
    this.permissionService.isUserPermittedToAccess(functionName) && this.savedStudyGeneralInfo.Status == StatusConstants.REVIEWED) {
      return true;
    }
    else {
      return false;
    }

  }

  selectedUser: any;
  /**
 * Method to prepare study owner model for save
 */
  prepareStudyOwnerModel() {

    let studyOwnerUpdateModel = new StudyOwnerUpdateModel();
    studyOwnerUpdateModel.StudyId = this.savedStudyId;
    studyOwnerUpdateModel.UserId = this.selectedUser;

    return studyOwnerUpdateModel;
  }

  /**
     * Click event to mark favorite study
     * @param selectedStudy
     */
  markAsFav(selectedStudy: StudyGeneralInformation) {
    this.MarkStudyAsFavorite(selectedStudy.StudyId);
    this.savedStudyGeneralInfo.IsFavorite = true;
  }

  /**
   * Click event to unmark favorite study
   * @param selectedStudy
   */
  unmarkAsFav(selectedStudy: StudyGeneralInformation) {
    this.UnMarkStudyAsFavorite(selectedStudy.StudyId);
    this.savedStudyGeneralInfo.IsFavorite = false;
  }

  httpRequestRepsonse: any;
  /**
   * Method to call Service to mark study as Favorite
   * @param studyId
   */
  private MarkStudyAsFavorite(studyId) {
    this.studyService.markStudyAsFavorite(studyId).subscribe(response => this.httpRequestRepsonse = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        this.messageService.add({ severity: 'success', summary: '', detail: this.httpRequestRepsonse });
      })
  }
  /**
   * Method to call Service to unmark study as Favorite
   * @param studyId
   */
  private UnMarkStudyAsFavorite(studyId) {
    this.studyService.unmarkStudyAsFavorite(studyId).subscribe(response => this.httpRequestRepsonse = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        this.messageService.add({ severity: 'success', summary: '', detail: this.httpRequestRepsonse });
      })
  }


  /**
   * Method to reload study comments upon sucessful deletion of Eveger request
   */
  reloadStudyCommentsOnEvengerRequestDeletion(){
    this.studyEvengerRequestDeletionSuccessEvent.emit();
  }

  
}
