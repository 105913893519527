import { Component, OnInit } from '@angular/core';
import { SelectItem, MessageService } from 'primeng/api';
import { GenerationConstants } from 'src/app/Constants/generation-constants';
import { AssayService } from 'src/app/services/assay-service/assay.service';
import { AssayModel } from 'src/app/Administrator/assay/assay.model';
import { SearchableDropdownModel } from 'src/app/services/UtilityService/searchableDropdownModel';
import { CropModel } from 'src/app/Administrator/crop/crop.model';
import { SearchableDropdownUtility } from 'src/app/services/UtilityService/searchableDropdownUtility';
import { CropService } from 'src/app/services/crop-service/crop.service';
//import { toDate } from '@angular/common/src/i18n/format_date';
import { AssaySamplingReportService } from 'src/app/services/reports-service/assay-sampling-report.service';
import { DatePipe } from '@angular/common';
import { ConfigurationSettings } from 'src/app/configuration-settings';

@Component({
  selector: 'app-assay-sampling-report',
  templateUrl: './assay-sampling-report.component.html',
  styleUrls: ['./assay-sampling-report.component.scss'],
  providers: [AssayService, MessageService, CropService, AssaySamplingReportService, DatePipe]
})
export class AssaySamplingReportComponent implements OnInit {

  constructor(private assayService: AssayService, private messageService: MessageService,
    private cropService: CropService, private assaySamplingReportService: AssaySamplingReportService,
    private datePipe: DatePipe) {

  }
  types: SelectItem[];
  ngOnInit() {
    this.GetCrops();
    this.GetAssays();
    this.minToDate.setMonth(this.minToDate.getMonth() - ConfigurationSettings.EVENGER_REQUEST_DATE_CRITERIA_SEARCH_FROM_MONTHS);
    this.maxBackDate.setMonth(this.maxBackDate.getMonth() - ConfigurationSettings.EVENGER_REQUEST_DATE_CRITERIA_SEARCH_FROM_MONTHS);
  }

  fromDate: Date
  toDate: Date;
  maxBackDate = new Date();
  results: any[];
  isLateStageSelected: boolean;
  selectedGeneration: string;
  selectedCrop: SearchableDropdownModel;


  applyEarlyStageStyle: boolean = false;
  applyLateStageStyle: boolean = false;

  searchResults: any[];
  exportFileName: string;

  /**
   * Click event on selecttion of Stage
   * @param stage Stage name
   */
  onStageSelect(stage) {
    if (stage == 'Early') {
      this.applyEarlyStageStyle = true;
      this.applyLateStageStyle = false;
      this.isLateStageSelected = false;
    }
    else {
      this.applyLateStageStyle = true;
      this.applyEarlyStageStyle = false;
      this.isLateStageSelected = true;
    }
    this.filterAssaysByCropAndStage();
  }

  applyGenerationT0Style: boolean = false;
  applyGenerationTnStyle: boolean = false;

  /**
   * Click event on selection of Generation
   * @param generation GenerationName
   */
  onGenerationSelect(generation) {
    if (generation == GenerationConstants.T0) {
      this.applyGenerationT0Style = true;
      this.applyGenerationTnStyle = false;
      this.selectedGeneration = 'T0';
    }
    else {
      this.applyGenerationT0Style = false;
      this.applyGenerationTnStyle = true;
      this.selectedGeneration = 'Tn';
    }
  }

  assays: AssayModel[];
  assaysFilteredList: AssayModel[] = [];
  selectedAssay: AssayModel;
  //-----------------------------------------------------------------------------
  // Get list of Assay from Assays data table
  //-----------------------------------------------------------------------------
  private GetAssays() {
    this.assayService.getAssays().subscribe(response => this.assays = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        //this.assaysFilteredList = this.assays;
      })
  }

  cropList: SearchableDropdownModel[];
  crops: CropModel[];
  private GetCrops() {
    this.cropService.getCrops().subscribe(response => this.crops = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        this.cropList = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.crops, "Name", "CropId");
      })
  }
  selectedCropId: number;

  /**
   * On Crop selection change event
   * @param event 
   */
  onCropSelectionChange(event) {
    this.selectedCropId = event.value.value;
    this.filterAssaysByCropAndStage();
  }

  /**
   * Method to filter assays based on seleted Crop and Stage
   */
  filterAssaysByCropAndStage() {
    this.selectedAssay = null
    this.assaysFilteredList = this.assays.filter(x => x.CropId === this.selectedCropId && x.IsLateStage === this.isLateStageSelected && x.IsActive === 'Active');
  }

  displayResults: boolean = false;
  /**
   * Button event on Search button Click
   */
  Search() {
    this.searchResults = [];
    let fromDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
    let toDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');

    //Setting Export file name
    this.exportFileName = this.selectedCrop.label + "_" + fromDate + "_" + toDate;

    if (this.isLateStageSelected) {
      this.resultTableHeaders = this.commonHeaders.concat(this.assaySamplingReportLateStageTableHeaders);
      this.getLateStageAssaySamplingReport(fromDate, toDate, this.selectedGeneration, this.selectedCropId, this.selectedAssay.AssayId);
    }
    else {
      this.resultTableHeaders = this.commonHeaders.concat(this.assaySamplingReportEarlyStageTableHeaders);
      this.getEarlyStageAssaySamplingReport(fromDate, toDate, this.selectedGeneration, this.selectedCropId, this.selectedAssay.AssayId);
    }
  }

  maxFromDate = new Date();
  minToDate = new Date();

  /**
   * Set Min date on selection of FromDate
   */
  setMinDates() {
    this.minToDate = this.fromDate;
    if (this.fromDate && this.toDate) {
      if (this.fromDate > this.toDate) {
        this.toDate = null;
      }
    }
  }

  /**
   * Click event to clear search fields
   */
  Clear() {
    this.fromDate = null;
    this.toDate = null;
    this.maxFromDate = null;
    this.minToDate = this.maxBackDate;;
    this.selectedCrop = null;
    this.applyGenerationT0Style = false;
    this.applyGenerationTnStyle = false;
    this.applyEarlyStageStyle = false;
    this.applyLateStageStyle = false;
    this.selectedAssay = null;
    this.searchResults = [];
    this.displayResults = false;
  }

  resultTableHeaders: any[];

  /**
   * Common headers for Early & Late Stage reports
   */
  commonHeaders: any[] = [
    { field: 'EUID', header: 'EU_ID', display: 'table-cell', format: 'none', isVisible: true },
    { field: 'PHP', header: 'PHP', display: 'table-cell', format: 'none', isVisible: true },
    { field: 'ExpectedMap', header: 'ExpectedMap', display: 'table-cell', format: 'none', isVisible: false },
    { field: 'TargetSite', header: 'Target Site', display: 'table-cell', format: 'none', isVisible: false },
    { field: 'Genotype', header: 'Genotype', display: 'table-cell', format: 'none', isVisible: false },
    { field: 'RequestName', header: 'Evenger Request Name', display: 'table-cell', format: 'none', isVisible: true },
    { field: 'StudyId', header: 'Study Id', display: 'table-cell', format: 'none', isVisible: true },
    { field: 'PMOProgram', header: 'PMO Program', display: 'table-cell', format: 'none', isVisible: false },
    { field: 'PMOProject', header: 'PMO Project', display: 'table-cell', format: 'none', isVisible: false },
    { field: 'StudyName', header: 'Study Name', display: 'table-cell', format: 'none', isVisible: true },
    { field: 'FirstEfficacyDecision', header: '1st Efficacy Decision', display: 'table-cell', format: 'none', isVisible: false },
    { field: 'AssayDetails', header: 'Assay Details', display: 'table-cell', format: 'none', isVisible: true },
    { field: 'PassCriteria', header: 'Pass Criteria', display: 'table-cell', format: 'none', isVisible: false },
    { field: 'Comments', header: 'Comments', display: 'table-cell', format: 'none', isVisible: false },
    { field: 'AssayName', header: 'Assay Name', display: 'table-cell', format: 'none', isVisible: true },
  ];

  /**
   * Additional header for Early Stage report
   */
  assaySamplingReportEarlyStageTableHeaders: any[] = [
    { field: 'SamplingWeek', header: 'Sampling Week', display: 'table-cell', format: 'date' }
  ];

  /**
   * Additional header for Late Stage report
   */
  assaySamplingReportLateStageTableHeaders: any[] = [
    { field: 'GHOrder', header: 'GH Order', display: 'table-cell', format: 'none' },
    { field: 'InfestationDate', header: 'Infestation Date', display: 'table-cell', format: 'date' }
  ];

  response: any;

  /**
   * Method to call Service method for Early Stage report
   * @param fromDate 
   * @param toDate 
   * @param generation 
   * @param cropId 
   * @param assayId 
   */
  getEarlyStageAssaySamplingReport(fromDate: string, toDate: string, generation: string, cropId: number, assayId: number) {
    this.assaySamplingReportService.getEarlyStageSamplingReport(fromDate, toDate, generation, cropId, assayId).subscribe(res => this.searchResults = res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while Fetching PI info. Please contact Admin.' })
      },
      () => {
        this.displayResults = true;
      });
  }

  /**
   * Method to call Service method for Late Stage report
   * @param fromDate 
   * @param toDate 
   * @param generation 
   * @param cropId 
   * @param assayId 
   */
  getLateStageAssaySamplingReport(fromDate: string, toDate: string, generation: string, cropId: number, assayId: number) {
    this.assaySamplingReportService.getLateStageSamplingReport(fromDate, toDate, generation, cropId, assayId).subscribe(res => this.searchResults = res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while Fetching PI info. Please contact Admin.' })
      },
      () => {
        this.displayResults = true;
      });
  }
}
