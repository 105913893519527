
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../../loading/res-loading.service';

import { GeneSetModel } from './geneset.model';
import { GeneSetService } from '../../services/geneset-service/geneset.service'
import { DatePipe } from '@angular/common';
import { PermissionService } from 'src/app/services/permission-service/permission.service';
import { UserFunctionConstants } from 'src/app/Constants/user-function-constants';

@Component({
    selector: 'app-geneset',
    templateUrl: './geneset.component.html',
    styleUrls: ['./geneset.component.css'],
    providers: [GeneSetService, ConfirmationService, MessageService, DatePipe, PermissionService]
})

export class GeneSetComponent implements OnInit {

    constructor(private genesetService: GeneSetService, private confirmationService: ConfirmationService,
        private messageService: MessageService, private datePipe: DatePipe, private permissionService: PermissionService) { }
    ngOnInit() {
        this.GetGeneSets();
        this.genesetTableHeaders = [
            //{ field: 'GeneSetId', header: ' Gene Set Id', display: 'none' },
            { field: 'Name', header: ' Name', display: 'table-cell' },
            { field: 'UserFullName', header: ' Created By', display: 'table-cell' },
            { field: 'CreatedDate', header: ' Created On', display: 'table-cell' },
            { field: 'IsActive', header: 'Status', display: 'table-cell' },
        ]
        this.defaultStatus = 'Active';
    }

    genesetTableHeaders: any[]

    genesets: GeneSetModel[] = [];
    geneSetsList: GeneSetModel[] = [];
    geneset = new GeneSetModel();
    selectedGeneSet: GeneSetModel;
    serviceResponse: any;
    isEditable: boolean = false
    dialogHeaderText: any = "Add";

    //Property to display dialog
    displayDialog: boolean = false;


    sortOptions: any[];
    sortKey: string;
    sortField: string;
    sortOrder: number;

    duplicateNameValidation: string;
    createdDateRangeFilter: Date[];

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        }
        else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    defaultStatus: string;

    //------------------------------
    //Method to display dialog
    //-----------------------------
    AddnewItem() {
        this.geneset = {};
        this.displayDialog = true;
        this.isEditable = false;
        this.dialogHeaderText = "Add";
        this.duplicateNameValidation = "";
    }

    //------------------------------------------
    //Method to save GeneSet
    //------------------------------------------
    Save() {
        this.duplicateNameValidation = "";
        if (this.genesets && this.genesets.filter(x => x.Name.toLocaleLowerCase() == this.geneset.Name.toLocaleLowerCase()).length > 0) {
            this.duplicateNameValidation = "Name already exists"
        }
        else {
            console.log(this.geneset);
            this.geneset.IsActive = this.defaultStatus;
            this.InsertGeneSet(this.geneset)
            this.displayDialog = false;
            this.geneset = {};
        }
    }

    //--------------------------------------------------------
    //To open pop up dialog box to edit selected row.
    //-------------------------------------------------------
    Edit(data) {
        this.duplicateNameValidation = "";
        this.selectedGeneSet = Object.assign({}, data);
        this.selectedGeneSet = data;
        console.log(this.selectedGeneSet);
        this.geneset = this.selectedGeneSet;
        this.defaultStatus = this.selectedGeneSet.IsActive;
        this.displayDialog = true;
        this.isEditable = true;
        this.dialogHeaderText = "Edit";
    }

    //---------------------------------------
    //To delete the selected row
    //--------------------------------------
    Delete(data) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                console.log(data);
                this.geneset = data;
                this.DeleteGeneSet(this.geneset.GeneSetId);
                // this.displayDialog = false; 
                this.geneset = {}
            },
            reject: () => {

            }
        });
    }

    //------------------------ 
    //To update one row.
    //------------------------
    Update() {
        //To-do : Add validation while editing
        if (this.genesets && this.genesets.filter(x => x.Name == this.geneset.Name).length > 1) {
            this.duplicateNameValidation = "Duplicate Name !"
        }
        else {
            this.geneset = this.selectedGeneSet;
            this.geneset.IsActive = this.defaultStatus;
            this.UpdateGeneSet(this.geneset);
            this.displayDialog = false;
            this.geneset = {};
        }
    }

    //-----------------------------------
    //Method to cancel/close dialog
    //-----------------------------------
    Cancel() {
        this.displayDialog = false;
        this.geneset = {};
    }

    //-----------------------------------------------
    // Insert one row in GeneSet data table
    //----------------------------------------------
    private InsertGeneSet(geneset) {
        this.genesetService.createGeneSet(this.geneset).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while adding. Contact Administrator' })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    //-----------------------------------------------------------------------------
    // Get list of GeneSet from GeneSets data table
    //-----------------------------------------------------------------------------
    private GetGeneSets() {
        this.genesetService.getGeneSets().subscribe(response => this.genesets = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                console.log(this.genesets)
                this.geneSetsList = this.genesets;
            })
    }

    //----------------------------------------------
    // Update selected row and refresh the grid
    //----------------------------------------------
    private UpdateGeneSet(geneset) {
        this.genesetService.updateGeneSet(this.geneset).subscribe(res => this.serviceResponse = res,
            error => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while updating. Contact Administrator' })

            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    //-----------------------------------------------
    // Delete selected row and refresh the grid.    
    //-----------------------------------------------
    private DeleteGeneSet(Id) {
        this.genesetService.deleteGeneSet(this.geneset.GeneSetId).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while deleting. Contact Administrator' })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Deletion successful' })
                this.ngOnInit();
            });
    }

    /**
     * Method to filter GeneSets by Date range
     * @param dateRange 
     */
    onDateFilterChange(dateRange: Date[]) {
        console.log(dateRange);
        let dp = this.datePipe.transform(dateRange[0], 'yyyy-MM-dd');
        let fromDate = new Date(this.datePipe.transform(dateRange[0], 'yyyy-MM-dd'));
        let toDate = new Date(this.datePipe.transform(dateRange[1], 'yyyy-MM-dd'));

        this.geneSetsList = this.genesets.filter(x => new Date(x.CreatedDate) >= fromDate && new Date(x.CreatedDate) <= toDate);
    }

    /**
     * Button event to clear date filter
     */
    onDateFilterClear() {
        this.geneSetsList = this.genesets;
    }
    
    /**
     * Method to check logged-in user is access to create study
     */
    isAdmin() {
        return this.permissionService.isUserPermittedToAccess(UserFunctionConstants.MANAGE_ADMINISTRATION);
    }
}

