import { EnvironmentDefinition } from "./environment-defination.interface"

/*
    This is the class where i can define the key and its value
    and it will transform the value of the key based on environment.

    --> If you want to add a new key and its value in the below section
        then first you define that key in the EnvironmentDefinition interface.
*/
export class Environments {

    public static environments: EnvironmentDefinition[] = [
        {   // Removed api in TICApiPath, since we have removed api in the api controllers
            name: 'LOCAL',
            hosts: ['localhost'],
            TICApiPath: 'https://localhost:44390',
            TICUIPath: 'http://localhost:4200',
            appRegistrationClientId: "4949c077-481b-479c-96e9-1fd4f0a8aad1",
            evengerRequestDateCriteriaSearchFromMonths: 36,
            readScope: 'api://tic-nonprod/ReadAccess'
        },
        {    // Added api in TICApiPath, since we have hosted api application in api directory in azure service
            name: 'DV',
            hosts: ['dev', 'tic-dev'],
            TICApiPath: 'https://tic-dev.se.research.corteva.com/api',
            TICUIPath: 'https://tic-dev.se.research.corteva.com',
            appRegistrationClientId: "4949c077-481b-479c-96e9-1fd4f0a8aad1",
            evengerRequestDateCriteriaSearchFromMonths: 36,
            readScope: 'api://tic-nonprod/ReadAccess'
        },
        {
            name: 'QA',
            hosts: ['qa'],
            TICApiPath: 'https://tic-qa.se.research.corteva.com/api',
            TICUIPath: 'https://tic-qa.se.research.corteva.com',
            appRegistrationClientId: "4949c077-481b-479c-96e9-1fd4f0a8aad1",
            evengerRequestDateCriteriaSearchFromMonths: 36,
            readScope: 'api://tic-nonprod/ReadAccess'
        },
        {
            name: 'UAT',
            hosts: ['uat'],
            TICApiPath: 'https://tic-uat.se.research.corteva.com/api',
            TICUIPath: 'https://tic-uat.se.research.corteva.com',
            appRegistrationClientId: "4949c077-481b-479c-96e9-1fd4f0a8aad1",
            evengerRequestDateCriteriaSearchFromMonths: 36,
            readScope: 'api://tic-nonprod/ReadAccess'
        },
        {
            name: 'PR',
            hosts: ['prod','tic.'],
            TICApiPath: 'https://tic.se.research.corteva.com/api',
            TICUIPath: 'https://tic.se.research.corteva.com',
            appRegistrationClientId: "321a93c5-140f-4f4f-841b-07f40f28cbcf",
            evengerRequestDateCriteriaSearchFromMonths: 9,
            readScope: 'api://tic-prod/ReadAccess'
        }
    ];
}
