
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { UserRoleService } from 'src/app/services/userrole-service/userrole.service';
import { UserRoleModel, ViewUserRoleModel } from './user-role.model';
import { UserModel } from 'src/app/user/user.model';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { RoleModel } from 'src/app/role/role.model';
import { RoleService } from 'src/app/services/role-service/role.service';
import { SearchableDropdownModel } from 'src/app/services/UtilityService/searchableDropdownModel';
import { SearchableDropdownUtility } from 'src/app/services/UtilityService/searchableDropdownUtility';
import { RoleConstants } from 'src/app/Constants/role-constants';
import { PermissionService } from 'src/app/services/permission-service/permission.service';
import { UserFunctionConstants } from 'src/app/Constants/user-function-constants';


@Component({
    selector: 'app-userrole',
    templateUrl: './user-role.component.html',
    styleUrls: ['./user-role.component.scss'],
    providers: [UserRoleService, ConfirmationService, MessageService, RoleService, PermissionService]
})

export class UserRoleComponent implements OnInit {

    constructor(private userroleService: UserRoleService, private confirmationService: ConfirmationService,
        private messageService: MessageService, private roleService: RoleService, private permissionService: PermissionService) { }

    userSearchBox: UserModel[];
    UserSearchAPIURL = ConfigurationSettings.USER_SEARCH_API_URL;

    ngOnInit() {
        this.GetUserRoles();
        this.GetRoles();
        this.ResetSelection();
        this.userSearchBox = [];
        this.userroleTableHeaders = [
            { field: 'Name', header: 'Name', display: 'table-cell', showFilter:'inline-block'},
            { field: 'IsUser', header: 'User Role', display: 'table-cell', textAlign: 'center', showFilter:'none' },
            { field: 'IsPI', header: 'PI Role', display: 'table-cell', textAlign: 'center', showFilter:'none'  },        
            { field: 'IsManager', header: 'Manager Role', display: 'table-cell', textAlign: 'center', showFilter:'none'  },
            { field: 'IsAdmin', header: 'Admin Role', display: 'table-cell', textAlign: 'center', showFilter:'none'  },
            { field: 'IsActive', header: ' Status', display: 'table-cell', textAlign: 'center', showFilter:'none'  }
        ]
        this.defaultStatus = 'Active';
    }

    //to track selected Roles, default set to None
    selectedRoles: any[] = [0]
    defaultRole: RoleModel;
    defaultRoleId: number[] = [];
    optionalRoles: RoleModel[] = [];
    newUserRoles: UserRoleModel[] = [];
    roles: RoleModel[] = [];
    checked: boolean = true;
    defaultStatus: string;

    /**
     * Button event to save new Users and roles
     */
    AddUsers() {
        this.newUserRoles = [];
        let role;
        if (this.selectedRoles.length > 0 && this.selectedRoles[0] != 0) {
            role = [this.defaultRole.RoleId, this.selectedRoles];
        }
        else {
            role = [this.defaultRole.RoleId];
        }
        this.userSearchBox.forEach(element => {
            let user = new UserRoleModel();
            user.User = element;
            user.RoleIds = role;
            user.User.IsActive = this.defaultStatus == 'Active' ? true : false;
            this.newUserRoles.push(user);
        });
        console.log(this.newUserRoles);
        this.InsertUserRole(this.newUserRoles)
    }

    /**
     * Method to clear searchbox and selected role
     */
    ResetSelection() {
        this.userSearchBox = [];
        this.selectedRoles = [0];
        this.isEditable = false;
    }
    userroleTableHeaders: any[]

    userroles: UserRoleModel[];
    userrole = new UserRoleModel();
    serviceResponse: any;
    isEditable: boolean = false
    dialogHeaderText: any = "Add";
    optionalRoleNone: RoleModel = { RoleId: 0, Name: 'None' }

    //Property to display dialog
    displayDialog: boolean = false;

    /**
     * Method to display dialog
     */
    AddnewItem() {
        this.ResetSelection();
        this.userrole = new UserRoleModel();
        this.displayDialog = true;
        this.isEditable = false;
        this.dialogHeaderText = "Add";
    }

    /**
     * Method to show dialog to edit the roles.
     * @param data 
     */
    Edit(data) {
        this.userSearchBox = [];
        this.selectedRoles = [];
        this.userrole = data.UserRole;
        this.userSearchBox.push(data.UserRole.User);
        this.selectedRoles = data.UserRole.RoleIds.filter(x => !this.defaultRoleId.includes(x));
        this.defaultStatus = data.IsActive == true ? 'Active' : 'InActive';
        if (this.selectedRoles.length == 0) {
            this.selectedRoles = [0];
        }
        this.displayDialog = true;
        this.isEditable = true;
        this.dialogHeaderText = "Edit";
    }


    /**
     * Button event to update user role.
     * @param user : UserModel
     * @param updatedRoles :Selected RoleIds
     * @param isActive : Status
     */
    Update(user, updatedRoles, isActive) {
        let updatedUser = new UserRoleModel();
        let role = [];
        if (updatedRoles.length > 0 && updatedRoles[0] != 0) {
            role = role.concat(updatedRoles, this.defaultRoleId)
        }
        else {
            role = [this.defaultRole.RoleId];
        }
        updatedUser.User = user;
        updatedUser.User.IsActive = isActive == 'Active' ? true : false;
        updatedUser.RoleIds = role;
        console.log(updatedUser);

        this.UpdateUserRole(updatedUser);
        this.displayDialog = false;
        this.isEditable = false;
    }

    //Method to cancel dialog
    Cancel() {
        this.displayDialog = false;
        this.ResetSelection();
    }


    private GetRoles() {
        this.roleService.getRoles().subscribe(response => this.roles = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: error.message })
            },
            () => {
                this.defaultRole = this.roles.filter(x => x.Name == RoleConstants.USER)[0];
                this.defaultRoleId[0] = this.defaultRole.RoleId;
                var adminRole = this.roles.find(o => o.Name == RoleConstants.ADMIN);
                this.optionalRoles = this.roles.filter(x => x.Name != RoleConstants.USER && x.Name != RoleConstants.ADMIN);
                this.optionalRoles.push(adminRole);
                this.optionalRoles.unshift(this.optionalRoleNone);
                this.AdminRoleId = this.roles.find(x => x.Name == RoleConstants.ADMIN).RoleId;
                this.PIRoleId = this.roles.find(x => x.Name == RoleConstants.PI).RoleId;
                this.ManagerRoleId = this.roles.find(x => x.Name == RoleConstants.MANAGER).RoleId;
                this.convertToViewModel();
                this.rolesDropdown = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.roles, "Name", "Name")
            })
    }

    rolesDropdown: SearchableDropdownModel[]

    /**
     * Method to filter by Role
     * @param selectedRoleFilter 
     */
    FilterByRole(selectedRoleFilter: SearchableDropdownModel) {
        console.log(selectedRoleFilter);
        if (selectedRoleFilter == null) {
            this.filteredViewUserRoles = this.viewUserRoles;
        }
        else {
            if (selectedRoleFilter.value == RoleConstants.ADMIN)
                this.filteredViewUserRoles = this.viewUserRoles.filter(x => x.IsAdmin == true)
            else if (selectedRoleFilter.value == RoleConstants.PI)
                this.filteredViewUserRoles = this.viewUserRoles.filter(x => x.IsPI == true);
            else
                this.filteredViewUserRoles = this.viewUserRoles;
        }
    }

    private InsertUserRole(userrole) {
        this.userroleService.createUserRole(userrole).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' });
                this.displayDialog = false;
                this.ngOnInit();
            });
    }

    viewUserRoles: ViewUserRoleModel[] = [];
    filteredViewUserRoles: ViewUserRoleModel[] = [];
    AdminRoleId: number;
    PIRoleId: number;
    ManagerRoleId:number;
    private GetUserRoles() {
        this.userroleService.getUserRoles().subscribe(response => this.userroles = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: error.message })
            },
            () => {
                console.log(this.userroles)
                this.convertToViewModel();

                console.log(this.viewUserRoles);

            })
    }

    /**
     * Method to convert API model to Display Grid model
     */
    private convertToViewModel() {
        this.viewUserRoles = [];
        if(this.userroles != null || this.userroles != undefined){
        this.userroles.forEach(element => {
            let userRole = new ViewUserRoleModel();
            userRole.UserRole = element;
            userRole.Name = element.User.LastName + ", " + element.User.FirstName;
            userRole.IsActive = element.User.IsActive == true ? true : false;
            userRole.IsAdmin = element.RoleIds.findIndex(x => x == this.AdminRoleId) != -1 ? true : false;
            userRole.IsPI = element.RoleIds.findIndex(x => x == this.PIRoleId) != -1 ? true : false;
            userRole.IsManager = element.RoleIds.findIndex(x => x == this.ManagerRoleId) != -1 ? true : false;
            userRole.IsUser = true;
            this.viewUserRoles.push(userRole);
        });
        }
        this.filteredViewUserRoles = this.viewUserRoles;
    }

    private UpdateUserRole(updatedUser) {
        this.userroleService.updateUserRole(updatedUser).subscribe(res => this.serviceResponse = res,
            error => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: '', detail: error.message })

            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    /**
 * Method to check logged-in user is access to create study
 */
    isAdmin() {
        return this.permissionService.isUserPermittedToAccess(UserFunctionConstants.MANAGE_ADMINISTRATION);
    }
}

