import { EnvironmentService } from "./services/environment-service/environment.service";
import { WebConfigKey } from './services/web-config-service/web-config-key'
import { Injectable } from "@angular/core";
/*
    This class will contain all configuration related keys.
*/
@Injectable()
export class ConfigurationSettings {

    // environmentService is the object of the EnvironmentDefinition interface type.
    // It will provide you the the current environment related key and its value
    private static environmentService = new EnvironmentService();

    /* Getting RestApiUrl */
    public static REST_API_URL: string = ConfigurationSettings.environmentService.currentEnvironment.TICApiPath;
    public static CURRENT_ENVIRONMENT_NAME: string = ConfigurationSettings.environmentService.currentEnvironment.name;
    public static APP_UI_URL: string = ConfigurationSettings.environmentService.currentEnvironment.TICUIPath;
    public static PLEASE_WAIT_MESSAGE = "Please wait...";
    public static USER_SEARCH_API_URL = ConfigurationSettings.environmentService.currentEnvironment.TICApiPath + "/users/search/{searchTerm}"
    public static EVENGER_REQUEST_DATE_CRITERIA_SEARCH_FROM_MONTHS = ConfigurationSettings.environmentService.currentEnvironment.evengerRequestDateCriteriaSearchFromMonths;
    public static APP_REGISTRATION_CLIENTID = ConfigurationSettings.environmentService.currentEnvironment.appRegistrationClientId;
    public static READ_SCOPE: string = ConfigurationSettings.environmentService.currentEnvironment.readScope;
}
