import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserRoleModel } from 'src/app/Administrator/user-role/user-role.model';
import { UserModel } from 'src/app/Administrator/user/user.model';
import { UserService } from '../user-service/user.service';

@Injectable({
  providedIn: 'root'
})

export class TICAuthService {



  constructor(private router: Router, private userService: UserService) { }

  public isLoggedIn: boolean = false;
  userRoleModel: any;
  // array of roles provided, check with user's assigned roles
  public doesUserHasRole(roles: Array<string>): boolean {
    let user: any = this.getUserFromSessionStorage();
    if (user == null) {
      return false;
    }
    for (var role of roles) {
      if (user.Roles.find(x => x.Name.toUpperCase() == role.toUpperCase())) {
        return true;
      }
    }
    return false;
  }

  /**
   * Set User in session storage
   * @param users
   * @returns
   */
  setUserToSessionStorage(user: any) {
    sessionStorage.setItem('loggedinUserInfo', JSON.stringify(user));
    sessionStorage.setItem("loggedInUserFunctions", user.Permissions);
    sessionStorage.setItem("loggedInUserRoles", user.Roles);
  }

  isUserActive(user: UserModel) {
    let isUserActive = true;
    if (!user || !user.IsActive) {
      isUserActive = false;
    }
    return isUserActive;
  }

  /**
   * Get User from Session Storage
   * @returns
   */
  getUserFromSessionStorage(): UserModel {
    let userModel = JSON.parse(sessionStorage.getItem('loggedinUserInfo'));
    if (!userModel) {
      return null;
    }
    return userModel;
  }
}
