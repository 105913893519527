/**
 * enums for Statuses
 */
export enum StatusConstants {
    DRAFT = "Draft",
    SUBMITTED_FOR_REVIEW = "Submitted for Review",
    ADMIN = "Admin",
    REVIEWED = "Reviewed",
    CANCELLED = "Cancelled",
    NEEDMOREINFO = "Need More Info",
    ARCHIVED = "Archived"
}