import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { catchError, map, tap } from 'rxjs/operators';
import { StudyStatusModel } from 'src/app/study/manage-study/study-status.model';

@Injectable({
  providedIn: 'root'
})
export class StudyCommentlogService {

  constructor(private _http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  public createStudyComment(studyComment: StudyStatusModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/studycommentlogs"
    let body = JSON.stringify(studyComment);

    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Added new Study Comment"))
      );
  }
}
