export class UserModel {
    UserId?: number
    Username?: string
    FirstName?: string
    LastName?: string
    Email?: string
    CreatedBy?: number
    CreatedDate?: Date
    ModifiedBy?: number
    ModifiedDate?: Date
    DisplayName?: string
    IsActive?: boolean;
}