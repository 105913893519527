export class SamplingWeekModel {
    SamplingWeekId?: number
    Name?: string
    CropId?: number
    T0RoundOffDay?: string
    TnRoundOffDay?: string
    T0SendToGHPlusWeeks?: number
    TnTransplantPlusWeeks?: number
    IsActive?: string
    CreatedDate?: Date
    CreatedBy?: number
    UserFullName?: string
    CropName?:string
}
