/**
 * enums for User Functions
 */
export enum UserFunctionConstants {
    CREATE_STUDY = "Create Study",
    EDIT_STUDY = "Edit Study",
    VIEW_STUDY = "View Study",
    REVIEW = "Review",
    NEED_MORE_INFO = "Need More Info",
    CANCEL = "Cancel",
    ASSIGN_OWNER = "Assign Owner",
    UPDATE_KEY_CONTACT = "Update Key Contact",
    UPDATE_ASSAY_DETAIL = "Update Assay Detail",
    ASSOCIATE_EVENGER_REQUEST = "Associate Evenger Request",
    MANAGE_ADMINISTRATION = "Manage Administration",
    VIEW_ADMINISTRATION = "View Administration",
    DASHBOARD = "Dashboard",
    VIEW_REPORTS = "View Reports",
    DOWNLOAD_REPORTS = "Download Reports",
    ARCHIVE_STUDY = "Archive Study",
    EDIT_EVENGER_REQUEST = "Edit Evenger Request",
    ADD_STUDY_GENERATION = "Add Study Generation",
    DELETE_STUDY_GENERATION = "Delete Study Generation"

}