
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { UserModel } from '../../user/user.model'

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable({
  providedIn: 'root'
})
export class UserService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }

    public createUser(user: UserModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/users"
        let body = JSON.stringify(user);

        return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Created new User"))
            );
    }

    public getUsers(): any {
        let url = ConfigurationSettings.REST_API_URL + "/roles/users"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Users")));
    }

    public updateUser(user: UserModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/users"
        let body = JSON.stringify(user);
        return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Updated User"))
            );
    }

    //Method to call API endpoint to search users from Active directory
    public searchUser(searchTerm): any {
        let url = ConfigurationSettings.REST_API_URL + "/users/search/" + searchTerm;
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Users")));
    }

    public getUsersByRoles(roles): any {
        let url = ConfigurationSettings.REST_API_URL + "/roles/users?roles="+ roles
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Users")));
    }
}
