

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import{GeneSetModel} from '../../Administrator/geneset/geneset.model';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class GeneSetService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private _http:HttpClient) { }

  public createGeneSet(geneset:GeneSetModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/genesets"
    let body= JSON.stringify(geneset);

    return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Created new GeneSet"))
            );
    }

  public getGeneSets():any{
    let url=ConfigurationSettings.REST_API_URL + "/genesets"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched GeneSets")));
    }

  public updateGeneSet(geneset:GeneSetModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/genesets"
    let body= JSON.stringify(geneset);
    return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Updated GeneSet"))
            );
    }

  public deleteGeneSet(Id:number):any{
    let url=ConfigurationSettings.REST_API_URL + "/genesets/"+Id;
   return this._http.delete(url).pipe(
            tap(x => console.log("Deleted GeneSet")));
    }
}