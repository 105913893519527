

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { ConfigurationSettings } from "../../configuration-settings"

@Injectable()
export class AssayCategoryTypeService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private _http:HttpClient) { }

  public getAssayCategoryTypes():any{
    let url=ConfigurationSettings.REST_API_URL + "/assay-category-types"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched assaycategorytypes")));
    }
}