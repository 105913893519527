import { Injectable } from '@angular/core';

@Injectable()
export class FilterService {
    filterColumnValueList: any[] = [];

    constructor() { }

    //----------------------------------------------------------------------
    // Method to filter records based on provided column and its value
    //----------------------------------------------------------------------
    public filter(column, value, filterColumns: any[], dataList: any[], clearAll: boolean): any[] {
        var filterColumnValue: any;

        //Clear All
        if (clearAll) {
            this.filterColumnValueList = [];
            return dataList;
        }
        
        // Remove the column from FilterColumnValueList in case user has removed the text.
        if (value == '') {
            filterColumnValue = this.filterColumnValueList.find(c => c.filterColumn == column);
            if (filterColumnValue != undefined) {
                this.filterColumnValueList.splice(this.filterColumnValueList.indexOf(filterColumnValue), 1);
            }
        }

        if (value != '' || this.filterColumnValueList.length > 0) {
            let existsElements: any[] = [];
            filterColumnValue = this.filterColumnValueList.find(c => c.filterColumn == column);

            if (filterColumnValue != undefined) {
                this.filterColumnValueList.splice(this.filterColumnValueList.indexOf(filterColumnValue), 1);
            }

            this.filterColumnValueList.push({ 'filterColumn': column, 'filterValue': value });

            //Loop through each column in filter Columns
            filterColumns.forEach(row => {
                existsElements = [];

                dataList.forEach(element => {
                    filterColumnValue = this.filterColumnValueList.find(c => c.filterColumn == row.Column);
                    if (filterColumnValue != undefined) {
                        if (element[row.Column].toLowerCase().includes(filterColumnValue.filterValue.toLowerCase())) {
                            existsElements.push(element);
                        }
                    }
                });
                if (filterColumnValue != undefined)
                    dataList = existsElements;
            });
        }
        return dataList;
    }
}