import { Injectable } from '@angular/core';
import { EnvironmentDefinition } from "./environment-defination.interface"
import { Environments } from "./environment";
import { ConsoleLog } from "../../console.log.service";

@Injectable()
export class EnvironmentService { 

    public currentEnvironment:EnvironmentDefinition;

    constructor()
    {       
        this.currentEnvironment = this.getValidEnvironment();
        new ConsoleLog().message("Current environment: " + this.currentEnvironment.name +" : API :" + this.currentEnvironment.TICApiPath);
    }

    private getValidEnvironment(): EnvironmentDefinition {
         return this.determineEnvironmentFromPath();
    }

    private determineEnvironmentFromPath(): EnvironmentDefinition {
        var path = window.location.host.toUpperCase();
        return this.determineEnvironmentFromHost(path);
    }

    private determineEnvironmentFromHost(host: string): EnvironmentDefinition {
      
        for (var i = 0; i < this.environments.length; i++) {
            var env = this.environments[i];
            
            for (var j = 0; j < env.hosts.length; j++) {
                var envHost = env.hosts[j].trim().toUpperCase();
                if (host.indexOf(envHost) !== -1) {
                    return env;
                }
            }
        }
        //return 'PROD';
    }

    private environments:EnvironmentDefinition[] = Environments.environments;

}
