import { Directive, HostListener, ElementRef, Injectable } from '@angular/core';
import { WhitelistedCharacterConstants } from '../Constants/whitelisted-character-constants';

@Directive({
  selector: 'input,textarea,p-autoComplete,sein-user-search',

})
@Injectable()
export class WhitelistedCharacterDirective {

  constructor(private el: ElementRef<HTMLInputElement>) {

  }

  allowedCharacters = WhitelistedCharacterConstants.ALLOWED_CHARACTERS_PATTERN;

  // check for keypress event in input field, allow only those that matches the regex
  @HostListener('keypress', ['$event'])
  onKeyPress(event) {
    if (!new RegExp(this.allowedCharacters).test(event.key))
      alert('Special character not allowed ' + event.key);
    return new RegExp(this.allowedCharacters).test(event.key);
  }

  // check for paste event, and validate if matches the regex below
  @HostListener('paste', ['$event'])
  blockPaste(event: ClipboardEvent) {
    this.replaceCharacterinFields(event);
  }

  replaceCharacterinFields(event) {
    if (!new RegExp(this.allowedCharacters).test(event.clipboardData.getData('text/plain'))){
      let pastedData = event.clipboardData.getData('text/plain').replace(/[a-zA-Z0-9@(:  ; _/''),.?&% -]/g, '');
      alert('Following characters are not allowed in the copied text: '+  pastedData +' Please remove it');
    }
  }
}
