import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { EvengerRequestSearchModel } from './evenger-request-search.model';
import { UserSearchAutocompleteComponent } from 'sein-user-search';
import { StudyService } from 'src/app/services/study-service/study.service';
import { ViewStudyCommentLogModel } from '../../view-study/view-study-comment-log.model';
import { EvengerRequestAssociationModel } from './evenger-request-association.model';
import { EvengerRequestMappingService } from 'src/app/services/evenger-request-mapping-service/evenger-request-mapping.service';
import { MessageService } from 'primeng/api';
import { UserModel } from 'src/app/user/user.model';
import { UserService } from 'src/app/services/user-service/user.service';
import { EvengerRequestResponseModel } from './evenger-request-response.model';
import { DatePipe } from '@angular/common';
import { StudyGeneralInformation } from '../study-generalInformation.model';
import { StudyEvengerRequestModel } from './study-evenger-request.model';
import { StudyStatusModel } from '../study-status.model';
import { StudyCommentCreateModel } from './study-comment-create.model';
import { StatusConstants } from 'src/app/Constants/status-constants';
import { RoleConstants } from 'src/app/Constants/role-constants';
import { PermissionService } from 'src/app/services/permission-service/permission.service';
import { UserFunctionConstants } from 'src/app/Constants/user-function-constants';

@Component({
  selector: 'app-evenger-request-mapping',
  templateUrl: './evenger-request-mapping.component.html',
  styleUrls: ['./evenger-request-mapping.component.scss'],
  providers: [EvengerRequestMappingService, UserService, DatePipe, StudyService, PermissionService]
})
export class EvengerRequestMappingComponent implements OnInit {

  constructor(private evengerRequestMappingService: EvengerRequestMappingService, private messageService: MessageService,
    private userService: UserService, private datePipe: DatePipe, private studyService: StudyService,  private permissionService: PermissionService) { }

  ngOnInit() {
    this.minToDate.setMonth(this.minToDate.getMonth() - ConfigurationSettings.EVENGER_REQUEST_DATE_CRITERIA_SEARCH_FROM_MONTHS);
    this.minFromDate.setMonth(this.minFromDate.getMonth() - ConfigurationSettings.EVENGER_REQUEST_DATE_CRITERIA_SEARCH_FROM_MONTHS);
    if (!this.permissionService.doesUserHaveRole(RoleConstants.USER)) {
      this.getLoggedInUserName();
    }
    this.getAssociatedEvengerRequestForStudy(this.savedStudyGenralInfo.StudyId);
  }

  @Input() savedStudyGenralInfo: StudyGeneralInformation;

  //Outpot event to notify the deletion of Study Evenger request
  @Output() studyEvengerRequestDeletionSuccessEvent = new EventEmitter<null>();

  studyId: number;
  defaultUser: UserModel[] = [];
  fromDate: Date
  toDate: Date;
  minToDate = new Date();
  maxToDate = new Date();
  minFromDate = new Date();
  maxFromDate = new Date();

  selectedEvengerRequests: EvengerRequestSearchModel[] = [];
  user: UserModel;
  evengerRequestsResponseModel: EvengerRequestResponseModel[] = [];
  selectedStudyEvengerRequests: StudyEvengerRequestModel[] = [];
  response: any;
  dialogHeaderTextEvengerRequestDeletion: string = 'Evenger Request Dissociation';

  displayEvengerMappingDialog: boolean = false;
  displayCommentsDialog: boolean = false;
  selectedFilterCriteria: string = 'requestor';
  UserSearchAPIURL = ConfigurationSettings.USER_SEARCH_API_URL;
  public statusConstants: typeof StatusConstants = StatusConstants;
  public userFunctions: typeof UserFunctionConstants = UserFunctionConstants;


  /**
   * Set Min date on selection of FromDate
   */
  setMinDates() {
    this.minToDate = this.evengerRequestSearchModel.fromDate;
    if (this.evengerRequestSearchModel.fromDate && this.evengerRequestSearchModel.toDate) {
      if (this.evengerRequestSearchModel.fromDate > this.evengerRequestSearchModel.toDate) {
        this.evengerRequestSearchModel.toDate = null;
      }
    }
  }
  /**
   * Button click event to display Evenger Request association dialog
   */
  showEvengerRequestMappingDialog() {
    this.displayEvengerMappingDialog = true;
    this.selectedFilterCriteria = 'requestor';
    this.evengerRequestSearchModel.requestorName = null;
    this.evengerRequestSearchModel.requestorName = this.user[0];
    this.Clear();
  }

  /**
   * Button click event to close Evenger Request association dialog
   */
  closeDialog() {
    this.displayEvengerMappingDialog = false;
  }

  evengerRequestSearchModel = new EvengerRequestSearchModel();
  defaultUserList: any[] = [];
  searchErrorMessage: string;
  displayErrorMessage: boolean = false;
  @ViewChild(UserSearchAutocompleteComponent, { static: false }) userSearchAutoComponent: UserSearchAutocompleteComponent

  associatedEvengerRequestList: EvengerRequestAssociationModel[] = [];

  /**
   * Click event to search Evenger requests
   */
  Search() {
    this.displayErrorMessage = false;
    this.searchErrorMessage = '';

    if (this.selectedFilterCriteria == 'requestName') {
      this.evengerRequestSearchModel.isSearchByRequestName = true;
      if (this.evengerRequestSearchModel.requestName == undefined) {
        this.searchErrorMessage = 'Request Name is required';
      }
      else {
        this.getEvengerRequestsByRequestName(this.evengerRequestSearchModel.requestName);
      }
    }
    else {
      if (this.validateSearchByRequestorNameFields()) {
        let username = this.evengerRequestSearchModel.requestorName.Username;
        let fromDate = this.datePipe.transform(this.evengerRequestSearchModel.fromDate, 'yyyy-MM-dd');
        let toDate = this.datePipe.transform(this.evengerRequestSearchModel.toDate, 'yyyy-MM-dd');
        this.getEvengerRequestsByRequestorName(username, fromDate, toDate);
      }
    }
  }

  /**
   * Method to validate search criteria 
   */
  validateSearchByRequestorNameFields() {
    let isValid: boolean = false;

    //Checking if Requestor Name is valid
    if (this.evengerRequestSearchModel.requestorName == undefined || this.evengerRequestSearchModel.requestorName == '') {
      //checking if Dates are enetred
      if (this.evengerRequestSearchModel.toDate == undefined || this.evengerRequestSearchModel.fromDate == undefined) {
        //isInvalid if only From Or To Or both are undefined
        isValid = false;
        this.searchErrorMessage = 'Atleast Requestor Name (OR) From & To date is required.'
      }
      else {
        isValid = true;
      }
    }
    else {
      //If requestorName is provided checkin if dates are valid
      if ((this.evengerRequestSearchModel.fromDate && this.evengerRequestSearchModel.toDate) ||
        (!this.evengerRequestSearchModel.toDate && !this.evengerRequestSearchModel.fromDate)) {
        //Both From & To dates are provided/not provided
        isValid = true;
      }
      else {
        isValid = false;
        this.searchErrorMessage = ' Only one date provided.Select both From & To dates.'
      }
    }
    return isValid;
  }

  /**
   * Click event to clear the search criteria
   */
  Clear() {
    this.defaultUserList = [];
    //this.userSearchAutoComponent.clearInputText();
    this.evengerRequestSearchModel = new EvengerRequestSearchModel();
    this.evengerRequestSearchModel.requestorName = null;
    this.evengerRequestSearchModel.requestorName = this.user[0];
    this.searchErrorMessage = '';
    this.evengerRequestsResponseModel = [];
    this.selectedEvengerRequests = [];
  }

  /**Search results header array */
  resultTableHeaders: any[] = [
    // { field: '', header: 'check', display: 'table-cell' },
    { field: 'RequestName', header: 'Request Name', display: 'table-cell' },
    { field: 'RequestedDate', header: 'Created Date', display: 'table-cell' },
    { field: 'RequestedBy', header: 'Requestor', display: 'table-cell' },
    { field: 'ExpectedMap', header: 'Map Details', display: 'table-cell' },
    { field: 'StudyCode', header: 'Study Id', display: 'table-cell' },
    { field: 'ExternalId', header: 'External Id', display: 'table-cell' },

  ];

  studyEvengerRequestHeaders: any[] = [
    { field: 'RequestName', header: 'Request Name', display: 'table-cell' },
    { field: 'RequestedDate', header: 'Date Created', display: 'table-cell' },
    { field: 'RequestedBy', header: 'Requestor', display: 'table-cell' },
    { field: 'ExpectedMap', header: 'Map Details', display: 'table-cell' },
    { field: 'ExternalId', header: 'External Id', display: 'table-cell' },
    { field: 'CreatedDate', header: 'Associated on', display: 'table-cell' },
    // { field: 'Actions', header: 'Actionsak', display: 'table-cell' },
  ]

  dateFormat: string = 'date';
  /**
   * Clcik event on change in search option
   */
  onFilterOptionSelect() {
    this.searchErrorMessage = '';
    this.evengerRequestSearchModel = new EvengerRequestSearchModel();
    if (this.selectedFilterCriteria == 'requestName') {
      this.evengerRequestSearchModel.isSearchByRequestName = true;
    }
    else {
      this.evengerRequestSearchModel.isSearchByRequestName = false;
      this.evengerRequestSearchModel.requestorName = null;
      this.evengerRequestSearchModel.requestorName = this.user[0];
    }
  }

  /**
   * Method to get PI user model
   */
  getLoggedInUserName() {
    let loggedInUser = sessionStorage['loggedInUsername'];
    this.userService.searchUser(loggedInUser).subscribe(res => this.user = res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while Fetching user info. Please contact Admin.' })
      },
      () => {
        this.evengerRequestSearchModel.requestorName = null;
        this.evengerRequestSearchModel.requestorName = this.user[0];

      });
  }

  /**
   * Method to call API to get Evenger Requests by Request Name
   * @param requestName 
   * @param studyId 
   */
  getEvengerRequestsByRequestName(requestName: string) {
    this.evengerRequestMappingService.getEvengerRequestsByRequestName(requestName).subscribe(res => this.evengerRequestsResponseModel = res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while fetching Eveneger Requests by Request Name. Please contact Admin.' })
      },
      () => {
      })
  }

  /**
   * Method to call service method to get Eveneger requests by Requestor Name & Date Range
   * @param requestorUserName 
   * @param fromDate 
   * @param toDate 
   */
  getEvengerRequestsByRequestorName(requestorUserName: string, fromDate: string, toDate: string) {
    this.evengerRequestMappingService.getEvengerRequestsByRequestorName(requestorUserName, fromDate, toDate).subscribe(res => this.evengerRequestsResponseModel = res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while fetching Evenger Requests by Request Name/Date range. Please contact Admin.' })
      },
      () => {
      })
  }

  /**
   * Button event to close Evenger mapping Dialog
   */
  closeEvnegerRequestAssociationDialog() {
    this.Clear();
    this.displayEvengerMappingDialog = false;
  }

  /**
  * click event from Dialog to save selected Evenger requests
  * @param selectedEvengerRequests 
  */
  associateSelectedEvengerRequests(selectedEvengerRequests: EvengerRequestResponseModel[]) {
    let studyId = this.savedStudyGenralInfo.StudyId;

    //converting EvenegerRequestResoponse Model to StudyEvenegerRequest Model
    this.selectedStudyEvengerRequests = selectedEvengerRequests.map(function (x) {
      let studyEvengerRequest = new StudyEvengerRequestModel();
      studyEvengerRequest.RequestKey = x.RequestKey;
      studyEvengerRequest.RequestName = x.RequestName;
      studyEvengerRequest.RequestedBy = x.RequestedBy;
      studyEvengerRequest.RequestedDate = x.RequestedDate;
      studyEvengerRequest.ExpectedMap = x.ExpectedMap;
      studyEvengerRequest.StudyId = studyId;
      studyEvengerRequest.ExternalId = x.ExternalId;
      return studyEvengerRequest;
    });
    this.saveSelectedEvengerRequests(this.selectedStudyEvengerRequests);
  }

  /**
   * Method to call service method to Associate Eveneger requests
   * @param selectedStudyEvengerRequests 
   */
  saveSelectedEvengerRequests(selectedStudyEvengerRequests: StudyEvengerRequestModel[]) {
    this.evengerRequestMappingService.createStudyEvengerRequestAssociation(selectedStudyEvengerRequests).subscribe(res => this.response = res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while asociating Eveneger Requests to study. Please contact Admin.' });
      },
      () => {
        this.Search();
        this.getAssociatedEvengerRequestForStudy(this.savedStudyGenralInfo.StudyId);
        this.selectedEvengerRequests = [];
        this.messageService.add({ severity: 'success', summary: '', detail: 'Evenger Requests association is succeeded.' });
      })
  }

  /**
   * Method to fetch associated Evenger requests for a study
   * @param studyId 
   */
  getAssociatedEvengerRequestForStudy(studyId: number) {
    this.evengerRequestMappingService.getAssociatedStudyEvengerRequests(studyId).subscribe(res => this.associatedEvengerRequestList = res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while fetching Asociating Eveneger Requests. Please contact Admin.' });
      });
  }


  selectedStudyEvengerRequestToDelete: StudyEvengerRequestModel;
  studyEvengerRequestDeletionComments: string;
  commentsValidation: string;

  /**
   *  Method to display comments disalog for deleting associated evenger request
   * @param comment
   */
  deleteStudyEvengerRequest(studyEvengerRequest: StudyEvengerRequestModel) {
    this.studyEvengerRequestDeletionComments = '';
    this.selectedStudyEvengerRequestToDelete = studyEvengerRequest;
    this.displayCommentsDialog = true;
    this.commentsValidation = '';
  }

  /**
   * Click event from Evenger request deletion comments.
   * Method to close dialog
   */
  cancelStudyEvengerRequestDeletion() {
    this.displayCommentsDialog = false;
    this.selectedStudyEvengerRequestToDelete = null;
    this.studyEvengerRequestDeletionComments = '';
  }

  /**
   * Method to add deletion comment and delete StudyEvenger Request
   * @param comment 
   */
  confirmAndDeleteStudyEvengerRequest(comment: string) {
    this.commentsValidation = '';
    if (!comment) {
      this.commentsValidation = 'Comments Required';
      return;
    }
    if (comment) {
      if (comment.length > 250) {
        this.commentsValidation = 'Comments cannot be more than 250 characters';
        return;
      }
    }

    let studyEvengerRequestDeleteModel = new StudyCommentCreateModel();
    studyEvengerRequestDeleteModel.Comments = comment;
    studyEvengerRequestDeleteModel.StudyId = this.savedStudyGenralInfo.StudyId;

    this.deleteStudyEvengerRequestById(this.selectedStudyEvengerRequestToDelete.StudyEvengerRequestId, studyEvengerRequestDeleteModel);
  }


  /**
   * Method to call service method to Delete Study Evenger request
   * @param studyEvengerRequestId 
   */
  deleteStudyEvengerRequestById(studyEvengerRequestId: number, studyEvengerRequest: StudyCommentCreateModel) {
    this.evengerRequestMappingService.deleteAssociatedEvengerRequest(studyEvengerRequestId, studyEvengerRequest).subscribe(res => this.response = res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while deleting Asociating Eveneger Requests. Please contact Admin.' });
      },
      () => {
        this.messageService.add({ severity: 'success', summary: '', detail: 'Study EvengerRequest association is deleted.' });
        this.getAssociatedEvengerRequestForStudy(this.savedStudyGenralInfo.StudyId);
        this.displayCommentsDialog = false;
        this.studyEvengerRequestDeletionSuccessEvent.emit();
      });
  }

  EditExternalIdDialogHeader = 'Add/Edit External Id';
  displayEditExternalIdDialog = false;
  selectedStudyEvengerRequestToEdit = new StudyEvengerRequestModel();
  externalIdFieldValidationMessage = 'ExternalId cannot be more than 20 characters';
  isExternalIdInValid = false;

  /**
   * Display Add/Edit External ID dialog
   * @param studyEvengerRequest 
   */
  showExternalIdEditDialog(studyEvengerRequest: StudyEvengerRequestModel) {
    this.selectedStudyEvengerRequestToEdit = studyEvengerRequest;
    this.displayEditExternalIdDialog = true;
  }

  /**
   * Save button Event on Add/Edit External ID dialog
   * @param studyEvengerRequest 
   */
  SaveExternalId(studyEvengerRequest: StudyEvengerRequestModel) {
    if (studyEvengerRequest.ExternalId.length > 20) {
      this.isExternalIdInValid = true;
      return;
    }
    this.evengerRequestMappingService.EditStudyEvengerRequestAssociation(studyEvengerRequest).subscribe(res => this.response = res,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while Updating ExternalId. Please contact Admin.' });
      },
      () => {
        this.messageService.add({ severity: 'success', summary: '', detail: 'Updated ExternalId.' });
        this.closeExternalIdEditDialog();
      });
  }
  /**
   * Close Add/Edit External ID dialog
   */
  closeExternalIdEditDialog() {
    this.displayEditExternalIdDialog = false;
    this.selectedStudyEvengerRequestToEdit = new StudyEvengerRequestModel();
    this.getAssociatedEvengerRequestForStudy(this.savedStudyGenralInfo.StudyId);
  }

showAssociateEvengerRequest() {
  if ((this.permissionService.doesUserHaveRole(RoleConstants.PI) && this.savedStudyGenralInfo.IsStudyOwner) ||
       (this.permissionService.doesUserHaveRole(RoleConstants.ADMIN) || this.permissionService.doesUserHaveRole(RoleConstants.MANAGER))
       && this.savedStudyGenralInfo.Status == StatusConstants.REVIEWED) {
    return true;
  }
}

  showEditEvengerRequest(functionName: string) {
    if (this.savedStudyGenralInfo.Status == StatusConstants.REVIEWED && (this.savedStudyGenralInfo.IsStudyOwner || this.permissionService.isUserPermittedToAccess(functionName))){
         return true;
      }
  }
}
