
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../../loading/res-loading.service';
import { Router } from '@angular/router';
import { AssayCategoryTypeDetailModel } from './assaycategorytypedetail.model';
import { AssayDetailService } from '../../services/assay-detail-service/assay-detail.service'
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserFunctionConstants } from 'src/app/Constants/user-function-constants';
import { PermissionService } from 'src/app/services/permission-service/permission.service';
import { AssayCategoryTypeService } from 'src/app/services/assay-category-type-service/assay-category-type.service';
import { GeneSetService } from 'src/app/services/geneset-service/geneset.service';
import { GeneSetModel } from '../geneset/geneset.model';
import { SearchableDropdownUtility } from 'src/app/services/UtilityService/searchableDropdownUtility';
import { SearchableDropdownModel } from 'src/app/services/UtilityService/searchableDropdownModel';

@Component({
    selector: 'app-assay-detail',
    templateUrl: './assay-detail.component.html',
    styleUrls: ['./assay-detail.component.css'],
    providers: [AssayDetailService, ConfirmationService, MessageService, DatePipe, PermissionService, AssayCategoryTypeService, GeneSetService]
})

export class AssayDetailComponent implements OnInit {

    constructor(private assaycategorytypedetailService: AssayDetailService,
        private confirmationService: ConfirmationService, private messageService: MessageService,
        private activatedRoute: ActivatedRoute, private datePipe: DatePipe, private permissionService: PermissionService,
        private assayCategoryService: AssayCategoryTypeService, private router: Router, private genesetService: GeneSetService) {
    }

    assaycategorytypedetailTableHeaders: any[]
    assaycategorytypedetails: AssayCategoryTypeDetailModel[] = [];
    assaycategorytypedetailsList: AssayCategoryTypeDetailModel[] = [];
    assaycategorytypedetail = new AssayCategoryTypeDetailModel();
    selectedAssayCategoryTypeDetail: AssayCategoryTypeDetailModel;
    assaycategorytypes: any[];
    serviceResponse: any;
    isEditable: boolean = false
    dialogHeaderText: any = "Add";
    displayDialog: boolean = false;
    categoryName: string;
    sortOptions: any[];
    sortKey: string;
    sortField: string;
    sortOrder: number;
    nameErrorMessage: string;
    defaultStatus: string;
    genesets: GeneSetModel[] = [];
    geneSetList: SearchableDropdownModel[];
    selectedAssayCategoryType: any;
    selectedGeneSetId: number;
    geneSetErrorMessage: string;

    ngOnInit() {
        this.GetAssayCategoryTypes();
        this.assaycategorytypedetailTableHeaders = [
            { field: 'Name', header: ' Name', display: 'table-cell' },
            { field: 'GeneSetName', header: ' Gene Set', display: 'none' },
            { field: 'UserFullName', header: ' Created By', display: 'table-cell' },
            { field: 'CreatedDate', header: ' Created On', display: 'table-cell' },
            { field: 'IsActive', header: 'Status', display: 'table-cell' },
        ]

        this.activatedRoute.queryParams.subscribe(params => {
            this.categoryName = params['CategoryName'];
            this.GetAssayCategoryTypeDetails(this.categoryName);
        });

        this.defaultStatus = 'Active';
    }


    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        }
        else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    //------------------------------
    //Method to display dialog
    //-----------------------------
    AddnewItem() {
        this.assaycategorytypedetail = {};
        this.nameErrorMessage = "";
        this.geneSetErrorMessage = "";
        this.displayDialog = true;
        this.isEditable = false;
        this.dialogHeaderText = "Add";
        this.selectedGeneSetId = null;
        this.geneSetList = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.genesets.filter(x => x.IsActive == "Active"), "Name", "GeneSetId");
    }


    getAssayDetails(category){
        this.selectedAssayCategoryType = category
        this.showGeneSetColumn();
        this.router.navigateByUrl('/admin/assaydetail?CategoryName='+ this.selectedAssayCategoryType.Name);
    }

    isActiveCategory(name){
        if(this.categoryName ===  name){
            return true;
        }
    }

    showGeneSetColumn(){
        if(this.selectedAssayCategoryType?.IsGeneSetRequired){
            this.assaycategorytypedetailTableHeaders.find(a => a.field == "GeneSetName").display = 'table-cell';
        }
        else{
           this.assaycategorytypedetailTableHeaders.find(a => a.field == "GeneSetName").display = 'none';
        }
    }

    //------------------------------------------
    //Method to save AssayCategoryTypeDetail
    //------------------------------------------
    Save() {
        this.nameErrorMessage = "";
        this.geneSetErrorMessage = ""
        if (!this.isFormValid()) {
            return;
        }

        if (this.assaycategorytypedetails.filter(x => x.Name.toLocaleLowerCase() == this.assaycategorytypedetail.Name.toLocaleLowerCase()).length > 0) {
            this.nameErrorMessage = "Name already exists";
        }
        else {
            this.assaycategorytypedetail.IsActive = this.defaultStatus;
            this.assaycategorytypedetail.AssayCategoryTypeName = this.categoryName;
            this.assaycategorytypedetail.GeneSetId = this.selectedGeneSetId;
            this.InsertAssayCategoryTypeDetail(this.assaycategorytypedetail)
            this.displayDialog = false;
            this.assaycategorytypedetail = {};
            this.selectedGeneSetId = undefined;
        }
    }

    //--------------------------------------------------------
    //To open pop up dialog box to edit selected row.
    //-------------------------------------------------------
    Edit(data) {
        this.nameErrorMessage = "";
        this.selectedAssayCategoryTypeDetail = Object.assign({}, data);
        this.selectedAssayCategoryTypeDetail = data;
        this.assaycategorytypedetail = this.selectedAssayCategoryTypeDetail;
        this.defaultStatus = this.selectedAssayCategoryTypeDetail.IsActive;
        this.selectedGeneSetId = this.assaycategorytypedetail.GeneSetId;
        this.displayDialog = true;
        this.isEditable = true;
        this.dialogHeaderText = "Edit";
        this.geneSetList = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.genesets, "Name", "GeneSetId");
    }

    //------------------------
    //To update one row.
    //------------------------
    Update() {
        if (this.assaycategorytypedetails && this.assaycategorytypedetails.filter(x => x.Name == this.assaycategorytypedetail.Name).length > 1) {
            this.nameErrorMessage = "Duplicate " + this.categoryName;
        }
        else {
            this.assaycategorytypedetail = this.selectedAssayCategoryTypeDetail;
            this.assaycategorytypedetail.IsActive = this.defaultStatus;
            this.UpdateAssayCategoryTypeDetail(this.assaycategorytypedetail);
            this.displayDialog = false;
            this.assaycategorytypedetail = {};
            this.selectedGeneSetId = undefined;
        }
    }

    isFormValid() {
        let isValid = true;
        if (!this.assaycategorytypedetail.Name) {
            this.nameErrorMessage = "Name is required"
            isValid = false;
        }

        if (!this.selectedGeneSetId && this.selectedAssayCategoryType?.IsGeneSetRequired) {
            this.geneSetErrorMessage = "GeneSet is required"
            isValid = false;
        }

        return isValid;
    }

    //-----------------------------------
    //Method to cancel/close dialog
    //-----------------------------------
    Cancel() {
        this.displayDialog = false;
        this.assaycategorytypedetail = {};
    }

    //-----------------------------------------------
    // Insert one row in AssayCategoryTypeDetail data table
    //----------------------------------------------
    private InsertAssayCategoryTypeDetail(assaycategorytypedetail) {
        this.assaycategorytypedetailService.createAssayCategoryTypeDetail(this.assaycategorytypedetail).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while adding. Contact Administrator' })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                 this.GetAssayCategoryTypeDetails(this.categoryName);
            });
    }

    //-----------------------------------------------------------------------------
    // Get list of AssayCategoryTypeDetail from AssayCategoryTypeDetails data table
    //-----------------------------------------------------------------------------
    private GetAssayCategoryTypeDetails(CategoryName) {
        this.assaycategorytypedetailService.getAssayCategoryTypeDetails(CategoryName).subscribe(response => this.assaycategorytypedetails = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.assaycategorytypedetailsList = this.assaycategorytypedetails
            })
    }

    //-----------------------------------------------------------------------------
    // Get list of AssayCategoryType from AssayCategoryTypes data table
    //-----------------------------------------------------------------------------
    private GetAssayCategoryTypes() {
        this.assayCategoryService.getAssayCategoryTypes().subscribe(response => this.assaycategorytypes = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.assaycategorytypes = this.assaycategorytypes.filter(function( obj ) {
                    return obj.Name.toLowerCase() !== 'other';
                });
                this.selectedAssayCategoryType = this.assaycategorytypes?.find(c => c.Name == this.categoryName)
                this.showGeneSetColumn();
                this.GetGeneSets();
            });


        }

    //----------------------------------------------
    // Update selected row and refresh the grid
    //----------------------------------------------
    private UpdateAssayCategoryTypeDetail(assaycategorytypedetail) {
        this.assaycategorytypedetailService.updateAssayCategoryTypeDetail(this.assaycategorytypedetail).subscribe(res => this.serviceResponse = res,
            error => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while updating. Contact Administrator' })

            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.GetAssayCategoryTypeDetails(this.categoryName);
            });
    }

        //-----------------------------------------------------------------------------
    // Get list of GeneSet from GeneSets data table
    //-----------------------------------------------------------------------------
    private GetGeneSets() {
        this.genesetService.getGeneSets().subscribe(response => this.genesets = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.geneSetList = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.genesets.filter(x => x.IsActive == "Active"), "Name", "GeneSetId");
            })
    }

    createdDateRangeFilter: Date[];
    /**
     * Method to filter GeneSets by Date range
     * @param dateRange
     */
    onDateFilterChange(dateRange: Date[]) {
        let fromDate = new Date(this.datePipe.transform(dateRange[0], 'yyyy-MM-dd'));
        let toDate = new Date(this.datePipe.transform(dateRange[1], 'yyyy-MM-dd'));

        this.assaycategorytypedetailsList = this.assaycategorytypedetails.filter(x => new Date(x.CreatedDate) >= fromDate && new Date(x.CreatedDate) <= toDate);
    }

    /**
     * Button event to clear date filter
     */
    onDateFilterClear() {
        this.assaycategorytypedetailsList = this.assaycategorytypedetails;
    }

    /**
 * Method to check logged-in user is access to create study
 */
    isAdmin() {
        return this.permissionService.isUserPermittedToAccess(UserFunctionConstants.MANAGE_ADMINISTRATION);
    }
}

