

import { Component, OnInit } from '@angular/core';
import { UserModel } from './user.model';
import { UserService } from '../services/user-service/user.service'
//import { GrowlModule } from 'primeng/primeng';
import { ConfirmationService } from "primeng/api";
import { MessageService } from 'primeng/api';
import { LoadingService } from '../loading/res-loading.service';
import { ToastModule } from 'primeng/toast';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css'],
    providers: [UserService, ConfirmationService, MessageService]
})

export class UserComponent implements OnInit {

    constructor(private userService: UserService, private confirmationService: ConfirmationService,
        private messageService: MessageService) { }
    ngOnInit() {
        this.GetUsers();

        this.userTableHeaders = [
            { field: 'UserId', header: 'User Id', display: 'none' },
            { field: 'Username', header: 'User Name', display: 'table-cell' },
            { field: 'FirstName', header: 'First Name', display: 'table-cell' },
            { field: 'LastName', header: 'Last Name', display: 'table-cell' },
            { field: 'Email', header: 'Email', display: 'table-cell' }]
    }

    growlMsgs: ToastModule[] = [];
    users: UserModel[];
    user = new UserModel();
    selectedUser: UserModel;
    serviceResponse: any;
    isEditable: boolean = false
    dialogHeaderText: any = "Add new";

    //Property to display dialog
    displayDialog: boolean = false;

    userTableHeaders: any[];
    selectedUsers: UserModel[];

    //------------------------------
    //Method to display dialog
    //-----------------------------
    AddnewItem() {
        this.user = {};
        this.displayDialog = true;
        this.isEditable = false;
        this.dialogHeaderText = "Add new";
    }

    //------------------------------------------
    //Method to save user
    //------------------------------------------
    Save() {
        console.log(this.user);
        this.InsertUser(this.user)
        this.displayDialog = false;
        this.user = {};
    }

    //--------------------------------------------------------
    //To open pop up dialog box to edit selected row.
    //-------------------------------------------------------
    Edit(data) {
        console.log(this.isEditable)
        this.selectedUser = data;
        console.log(this.selectedUser);
        this.user = this.selectedUser;
        this.user.CreatedDate = new Date(this.selectedUser.CreatedDate.toString())
        this.user.ModifiedDate = new Date(this.selectedUser.ModifiedDate.toString())

        this.displayDialog = true;
        this.isEditable = true;
        this.dialogHeaderText = "Edit";
        console.log(this.isEditable)

    }

    //------------------------ 
    //To update one row.
    //------------------------
    Update() {
        this.user = this.selectedUser;
        this.UpdateUser(this.user);
        this.displayDialog = false;
        this.user = {};
    }

    //-----------------------------------
    //Method to cancel/close dialog
    //-----------------------------------
    Cancel() {
        this.displayDialog = false;
        this.user = {};
    }

    //-----------------------------------------------
    // Insert one row in User data table
    //----------------------------------------------
    private InsertUser(user) {
        this.userService.createUser(this.user).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    //-----------------------------------------------------------------------------
    // Get list of user from User data table
    //-----------------------------------------------------------------------------
    private GetUsers() {
        this.userService.getUsers().subscribe(response => this.users = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: error.message })
            },
            () => { console.log(this.users) })
    }

    //----------------------------------------------
    // Update selected row and refresh the grid
    //----------------------------------------------
    private UpdateUser(user) {
        this.userService.updateUser(this.user).subscribe(res => this.serviceResponse = res,
            error => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: '', detail: error.message })

            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    //--------------------------------------
    // Download the tempalte    
    //--------------------------------------
    DownloadTemplate() {
        var columnsName = [];
        columnsName.push('UserId');
        columnsName.push('Username');
        columnsName.push('FirstName');
        columnsName.push('LastName');
        columnsName.push('Email');
        columnsName.push('CreatedBy');
        columnsName.push('CreatedDate');
        columnsName.push('ModifiedBy');
        columnsName.push('ModifiedDate');


        let blob = new Blob(['\ufeff' + columnsName], { type: 'text/csv;charset=utf-8;' });
        // if (window.navigator.msSaveOrOpenBlob) //IE & Edge
        // {
        //     //msSaveBlob only available for IE & Edge
        //     window.navigator.msSaveBlob(blob, "UserImportTemplate.csv");
        // }
        // else //Chrome & FF
        // {

            let dwldLink = document.createElement("a");
            let url = URL.createObjectURL(blob);
            //let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
            //if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            //    dwldLink.setAttribute("target", "_blank");
            //}
            dwldLink.setAttribute("href", url);
            dwldLink.setAttribute("download", "UserImportTemplate.csv");
            dwldLink.style.visibility = "hidden";
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        //}
    }
}
