
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../../loading/res-loading.service';
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { SamplingWeekModel } from './samplingweek.model';
import { CropModel } from '../crop/crop.model';
import { SamplingWeekService } from '../../services/samplingweek-service/samplingweek.service'
import { SearchableDropdownUtility } from 'src/app/services/UtilityService/searchableDropdownUtility';
import { SearchableDropdownModel } from 'src/app/services/UtilityService/searchableDropdownModel';
import { CropService } from '../../services/crop-service/crop.service'
import { UserFunctionConstants } from 'src/app/Constants/user-function-constants';
import { PermissionService } from 'src/app/services/permission-service/permission.service';


@Component({
    selector: 'app-samplingweek',
    templateUrl: './samplingweek.component.html',
    styleUrls: ['./samplingweek.component.css'],
    providers: [SamplingWeekService, ConfirmationService, MessageService, CropService, PermissionService]
})

export class SamplingWeekComponent implements OnInit {

    constructor(private fb: UntypedFormBuilder, private samplingweekService: SamplingWeekService, private confirmationService: ConfirmationService, private messageService: MessageService,
        private cropService: CropService, private permissionService: PermissionService) {
        this.roundOffDays = [
            { label: 'Monday', value: 'Monday' },
            { label: 'Tuesday', value: 'Tuesday' },
            { label: 'Wednesday', value: 'Wednesday' },
            { label: 'Thursday', value: 'Thursday' },
            { label: 'Friday', value: 'Friday' },
            { label: 'Saturday', value: 'Saturday' },
            { label: 'Sunday', value: 'Sunday' }
        ]
    }
    ngOnInit() {
        this.samplingWeekInformation = this.fb.group({
            SamplingWeekId: ['0'],
            Name: ['', [Validators.required, Validators.maxLength(30)]],
            CropId: ['', Validators.required],
            T0RoundOffDay: ['', Validators.required],
            TnRoundOffDay: ['', Validators.required],
            T0SendToGHPlusWeeks: ['', Validators.required],
            TnTransplantPlusWeeks: ['', Validators.required],
            IsActive: ['Active', Validators.required],
        }, {});

        this.GetSamplingWeeks();
        this.samplingweekTableHeaders = [
            { field: 'SamplingWeekId', header: ' Sampling Week Id', display: 'none' },
            { field: 'Name', header: ' Name', display: 'table-cell' },
            { field: 'CropName', header: ' Crop', display: 'table-cell' },
            { field: 'UserFullName', header: ' Created By', display: 'table-cell' },
            { field: 'CreatedDate', header: ' Created On', display: 'table-cell' },
            { field: 'IsActive', header: 'Status', display: 'table-cell' },

        ]
        this.GetCrops();
    }

    samplingweekTableHeaders: any[]
    samplingweeks: SamplingWeekModel[] = [];
    samplingweek = new SamplingWeekModel();
    selectedSamplingWeek: SamplingWeekModel;
    serviceResponse: any;
    isEditable: boolean = false
    dialogHeaderText: any = "Add";
    roundOffDays: SelectItem[];
    crops: CropModel[];

    //Property to display dialog
    displayDialog: boolean = false;
    samplingWeekInformation: UntypedFormGroup;
    submitted = false;
    get samplingWeekInformationControlsObj() { return this.samplingWeekInformation.controls; }

    sortOptions: any[];
    sortKey: string;
    sortField: string;
    sortOrder: number;
    duplicateNameValidation: string;

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        }
        else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    //defaultStatus: string;
    //------------------------------
    //Method to display dialog
    //-----------------------------
    AddnewItem() {
        this.samplingweek = {};
        this.isEditable = false;
        this.ResetForm();
        this.displayDialog = true;
        this.dialogHeaderText = "Add";
        this.duplicateNameValidation = "";
    }

    //Method to Rest Form
    ResetForm() {
        this.submitted = false;
        this.samplingWeekInformation.reset();
        this.samplingWeekInformation.patchValue({ IsActive: 'Active', SamplingWeekId: '0' });
        this.samplingWeekInformation.get('Name')['enable']();
        this.samplingWeekInformation.get('CropId')['enable']();
        this.samplingWeekInformation.clearAsyncValidators();
        this.duplicateNameValidation = "";
    }

    //------------------------------------------
    //Method to save SamplingWeek
    //------------------------------------------
    Save() {
        this.duplicateNameValidation = "";
        console.log(this.samplingWeekInformation.value);
        this.samplingweek = this.samplingWeekInformation.value;
        this.submitted = true;
        if (this.samplingWeekInformation.invalid) {
            return;
        }

        if (this.samplingweeks && this.samplingweeks.filter(x => x.Name.toLocaleLowerCase() == this.samplingWeekInformation.value.Name.toLocaleLowerCase()
            && x.CropId == this.samplingWeekInformation.value.CropId).length > 0) {
            this.duplicateNameValidation = "Name already exists";
            return;
        }

        this.InsertSamplingWeek(this.samplingweek)
        this.displayDialog = false;
        this.samplingweek = {};
        this.ResetForm();
    }

    //--------------------------------------------------------
    //To open pop up dialog box to edit selected row.
    //-------------------------------------------------------
    Edit(data) {
        this.selectedSamplingWeek = data;
        console.log(this.selectedSamplingWeek);
        this.samplingweek = this.selectedSamplingWeek;
        this.BindSamplingWeekInformation()
        //this.samplingWeekInformation.value =  this.selectedSamplingWeek;
        this.displayDialog = true;
        this.isEditable = true;
        this.dialogHeaderText = "Edit";
        this.samplingWeekInformation.get('Name')[!this.isEditable ? 'enable' : 'disable']();
        this.samplingWeekInformation.get('CropId')[!this.isEditable ? 'enable' : 'disable']();
    }

    private BindSamplingWeekInformation() {
        this.samplingWeekInformation.get("SamplingWeekId").setValue(this.samplingweek.SamplingWeekId);
        this.samplingWeekInformation.get("Name").setValue(this.samplingweek.Name);
        this.samplingWeekInformation.get("T0RoundOffDay").setValue(this.samplingweek.T0RoundOffDay);
        this.samplingWeekInformation.get("TnRoundOffDay").setValue(this.samplingweek.TnRoundOffDay);
        this.samplingWeekInformation.get("T0SendToGHPlusWeeks").setValue(this.samplingweek.T0SendToGHPlusWeeks);
        this.samplingWeekInformation.get("TnTransplantPlusWeeks").setValue(this.samplingweek.TnTransplantPlusWeeks);
        this.samplingWeekInformation.get("IsActive").setValue(this.samplingweek.IsActive);
        this.samplingWeekInformation.get("CropId").setValue(this.samplingweek.CropId);
        console.log(this.samplingWeekInformation);
    }

    //---------------------------------------
    //To delete the selected row
    //--------------------------------------
    Delete(data) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                console.log(data);
                this.samplingweek = data;
                this.DeleteSamplingWeek(this.samplingweek.SamplingWeekId);
                this.samplingweek = {}
            },
            reject: () => {

            }
        });
    }
    cropList: SearchableDropdownModel[];
    private GetCrops() {
        this.cropService.getCrops().subscribe(response => this.crops = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.cropList = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.crops, "Name", "CropId");
            })
    }

    //------------------------ 
    //To update one row.
    //------------------------
    Update() {
        this.submitted = true;
        if (this.samplingWeekInformation.invalid) {
            return;
        }
        this.samplingweek = this.samplingWeekInformation.value;
        this.samplingweek.Name = this.samplingWeekInformation.getRawValue().Name,
            this.samplingweek.CropId = this.samplingWeekInformation.getRawValue().CropId,
            this.UpdateSamplingWeek(this.samplingweek);
        this.displayDialog = false;
        this.samplingweek = {};
    }

    //-----------------------------------
    //Method to cancel/close dialog
    //-----------------------------------
    Cancel() {
        this.ResetForm();
        this.displayDialog = false;
        this.samplingweek = {};
    }

    //-----------------------------------------------
    // Insert one row in SamplingWeek data table
    //----------------------------------------------
    private InsertSamplingWeek(samplingweek) {
        this.samplingweekService.createSamplingWeek(this.samplingweek).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while adding. Contact Administrator' })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    //-----------------------------------------------------------------------------
    // Get list of SamplingWeek from SamplingWeeks data table
    //-----------------------------------------------------------------------------
    private GetSamplingWeeks() {
        this.samplingweekService.getSamplingWeeks().subscribe(response => this.samplingweeks = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                console.log(this.samplingweeks)
            })

    }

    //----------------------------------------------
    // Update selected row and refresh the grid
    //----------------------------------------------
    private UpdateSamplingWeek(samplingweek) {
        this.samplingweekService.updateSamplingWeek(this.samplingweek).subscribe(res => this.serviceResponse = res,
            error => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while updating. Contact Administrator' })

            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    //-----------------------------------------------
    // Delete selected row and refresh the grid.    
    //-----------------------------------------------
    private DeleteSamplingWeek(Id) {
        this.samplingweekService.deleteSamplingWeek(this.samplingweek.SamplingWeekId).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while deleting. Contact Administrator' })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Deletion successful' })
                this.ngOnInit();
            });
    }

    /**
     * Method to check logged-in user is access to create study
     */
    isAdmin() {
        return this.permissionService.isUserPermittedToAccess(UserFunctionConstants.MANAGE_ADMINISTRATION);
    }

}