import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/toPromise';
import { ConfigurationSettings } from "../.././configuration-settings"
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class SecurityService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private options = { headers: this.headers };
    constructor(private _http: HttpClient) { }

    /* Validating User - Authorize if the current user is in database list*/
    public authorizeUser(): any {
        let url = ConfigurationSettings.REST_API_URL + "/authorize/me";

        return this._http.get(url)
            .pipe(
                tap(x => console.log("User is authorized")));
    }
}
