import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ConfirmationService, SelectItem, MenuItem, Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../../loading/res-loading.service';
import { FormControl, FormGroup, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { StudyGeneralInformation } from './study-generalInformation.model';
import { ProjectTacticTeamService } from 'src/app/services/projecttacticteam-service/projecttacticteam.service';
import { ProjectTacticTeamModel } from 'src/app/Administrator/projecttacticteam/projecttacticteam.model';
import { SearchableDropdownUtility } from 'src/app/services/UtilityService/searchableDropdownUtility';
import { SearchableDropdownModel } from 'src/app/services/UtilityService/searchableDropdownModel';
import { GeneSetModel } from 'src/app/Administrator/geneset/geneset.model';
import { GeneVariantModel } from 'src/app/Administrator/genevariant/genevariant.model';
import { GeneSetService } from 'src/app/services/geneset-service/geneset.service';
import { GeneVariantService } from 'src/app/services/genevariant-service/genevariant.service';
import { UserService } from 'src/app/services/user-service/user.service';
import { PMOPortalService } from 'src/app/services/PMOPortal-service/PMOPortal.service';
import { UserModel } from 'src/app/Administrator/user/user.model';
import { CropModel } from 'src/app/Administrator/crop/crop.model';
import { CropService } from 'src/app/services/crop-service/crop.service';
import { PMOProjectModel } from 'src/app/PMOPortal/PMOProject.model';
import { StudyService } from 'src/app/services/study-service/study.service';
import { StudyKeyContactModel } from './study-keycontact.model';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
//import { ChangeDetectionStrategy } from '@angular/compiler/src/core';
import { switchMap } from 'rxjs/operators';
//import { map } from 'rxjs-compat/operator/map';
import { pmoProgramModel } from './pmoProgram.model';
import { GenerationConstants } from 'src/app/Constants/generation-constants';
import { StudyConstants } from 'src/app/Constants/study-constants';
import { AssaySelectionComponent } from './assay-selection/assay-selection.component';

@Component({
    selector: 'app-geneset',
    templateUrl: './study.component.html',
    styleUrls: ['./study.component.css'],
    //changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ConfirmationService, ProjectTacticTeamService, MessageService, GeneSetService, GeneVariantService, UserService, PMOPortalService, CropService, StudyService, ConfigurationSettings]
})

export class StudyComponent implements OnInit {
    constructor(private fb: UntypedFormBuilder, private messageService: MessageService, private projectTacticTeamService: ProjectTacticTeamService, private geneSetService: GeneSetService,
        private geneVariantService: GeneVariantService, private userService: UserService, private pmoPortalService: PMOPortalService, private cropService: CropService, private studyService: StudyService, private router: Router,
        private confirmationService: ConfirmationService, private route: ActivatedRoute, private loader: LoadingService) {
    }
    paramStudyId: any

    ngOnInit() {
        this.paramStudyId = this.route.snapshot.paramMap.get("studyId");
        if (this.paramStudyId != null) {
            this.savedStudyId = this.paramStudyId;
            this.activeIndex = 2;
            this.readOnlyStepperNavigation = false;
            this.pageFrom ="Preview";
        }
        else {
            this.activeIndex = 0;
            this.savedStudyId = 0;
        }
        this.initializeStepper();

    }

    items: MenuItem[];
    activeIndex: number = 0;
    showGeneralInfoStep: boolean = true;
    isStudyInValid:boolean = false;

    readOnlyStepperNavigation: boolean = true;

    private initializeStepper() {
        this.items = [{
            label: 'General Info',
            command: (event: any) => {
                if (!this.isStudyInValid) {
                    this.activeIndex = 0;
                    this.showGeneralInfoStep = true;
                } else {
                    this.activeIndex = 1;
                }
            }
        },
        {
          label: 'Assay Selection',
          command: (event: any) => {
              this.activeIndex = 1;
          }
        },

        {
            label: 'Preview',
            command: (event: any) => {
                if (!this.isStudyInValid) {
                this.activeIndex = 2;
                this.pageFrom ="Preview";
                }
                else {
                    this.activeIndex = 1;
                }
            },
        }];
    }

    savedStudyId: number;
    generationName: string;
    pageFrom:string

    Previous(params) {
        if (params.stepName === StudyConstants.ASSAY_SELECTION_CONSTANT) {
            this.activeIndex = 0;
            this.savedStudyId = params.studyId
        }
        else {
            this.activeIndex = 1;
        }
    }

    Next(params) {
        if (params.stepName === StudyConstants.STUDY_GENERALINFO_CONSTANT) {
            this.savedStudyId = params.studyId;
            this.activeIndex = 1;

        }
        if (params.stepName === StudyConstants.ASSAY_SELECTION_CONSTANT) {
            this.activeIndex = 2;
            this.savedStudyId = params.studyId;
            this.pageFrom = "Preview";
        }
    }

    Close() {
        this.router.navigateByUrl('/study');
    }


    @ViewChild(AssaySelectionComponent) assaySelectionComponent : AssaySelectionComponent;

//Call the child component to validate the generation information and obtain the status.
    isStudyValid(){
        this.isStudyInValid = false;
        if(this.assaySelectionComponent != undefined &&  !this.assaySelectionComponent?.validateAssayDataForGeneration())
        {
            this.isStudyInValid = true;
        }

    }
}
