
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';



import { ConfigurationSettings } from "../.././configuration-settings"
import { StudyAssayModel } from 'src/app/study/manage-study/study-assay-selection/study-assay.model';

@Injectable()
export class StudyAssayService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }

    public createStudyAssays(studyId, studyAssayList: StudyAssayModel[]): any {
        let url = ConfigurationSettings.REST_API_URL + "/study/"+studyId+"/assays";
        let body = JSON.stringify(studyAssayList);

        return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Study Saved"))
            );

    }

    public getStudyAssaysForGeneration(studyId: number, generation: string): any {
        let url = ConfigurationSettings.REST_API_URL + "/study/" + studyId + "/assays/generation/" + generation;
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched StudyAssays")));
    }


    public updateStudyAssayDetails(studyId:number, studyAssay: StudyAssayModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/study/"+studyId+"/assays"
        let body = JSON.stringify(studyAssay);
        return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Updated StudyAssay"))
            );
    }

    public deleteStudyAssay(StudyGenerationAssayId: number): any {
        let url = ConfigurationSettings.REST_API_URL + "/study/assays/" + StudyGenerationAssayId;
        return this._http.delete(url).pipe(
            tap(x => console.log("Deleted Study Assay")));
    }  
}