import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ConfirmationService, SelectItem, MenuItem, Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ProjectTacticTeamService } from 'src/app/services/projecttacticteam-service/projecttacticteam.service';
import { ProjectTacticTeamModel } from 'src/app/Administrator/projecttacticteam/projecttacticteam.model';
import { SearchableDropdownUtility } from 'src/app/services/UtilityService/searchableDropdownUtility';
import { SearchableDropdownModel } from 'src/app/services/UtilityService/searchableDropdownModel';
import { GeneSetModel } from 'src/app/Administrator/geneset/geneset.model';
import { GeneVariantModel } from 'src/app/Administrator/genevariant/genevariant.model';
import { GeneSetService } from 'src/app/services/geneset-service/geneset.service';
import { GeneVariantService } from 'src/app/services/genevariant-service/genevariant.service';
import { UserService } from 'src/app/services/user-service/user.service';
import { PMOPortalService } from 'src/app/services/PMOPortal-service/PMOPortal.service';
import { UserModel } from 'src/app/Administrator/user/user.model';
import { CropModel } from 'src/app/Administrator/crop/crop.model';
import { CropService } from 'src/app/services/crop-service/crop.service';
import { PMOProjectModel } from 'src/app/PMOPortal/PMOProject.model';
import { StudyService } from 'src/app/services/study-service/study.service';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
//import { ChangeDetectionStrategy } from '@angular/compiler/src/core';
import { switchMap } from 'rxjs/operators';
//import { map } from 'rxjs-compat/operator/map';
import { StudyKeyContactModel } from '../study-keycontact.model';
import { pmoProgramModel } from '../pmoProgram.model';
import { LoadingService } from 'src/app/loading/res-loading.service';
import { StudyGeneralInformation } from '../study-generalInformation.model';
import { GenerationConstants } from 'src/app/Constants/generation-constants';
import { CropNameConstants } from 'src/app/Constants/crop-name-constants';
import { StudyConstants } from 'src/app/Constants/study-constants';
import { StatusConstants } from 'src/app/Constants/status-constants';
import { RoleConstants } from 'src/app/Constants/role-constants';


@Component({
    selector: 'study-generalInformation',
    templateUrl: './study-generalInformation.component.html',
    styleUrls: ['./study-generalInformation.component.css'],
    //changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ConfirmationService, ProjectTacticTeamService, MessageService, GeneSetService, GeneVariantService, UserService, PMOPortalService, CropService, StudyService, ConfigurationSettings]
})

export class StudyGeneralInformationComponent implements OnInit {
    constructor(private fb: FormBuilder, private messageService: MessageService, private projectTacticTeamService: ProjectTacticTeamService, private geneSetService: GeneSetService,
        private geneVariantService: GeneVariantService, private userService: UserService, private pmoPortalService: PMOPortalService, private cropService: CropService, private studyService: StudyService, private router: Router,
        private confirmationService: ConfirmationService, private route: ActivatedRoute, private loader: LoadingService) {
        this.IsActiveSelectItems = [
            { label: 'Active', value: 'true' },
            { label: 'InActive', value: 'false' }
        ]

    }
    paramStudyId: any
    stackedConstructs: any[];

    @Input() draftStudyId: number;
    ngOnInit() {
        this.stackedConstructs = [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }];
        this.paramStudyId = this.route.snapshot.paramMap.get("studyId");
        this.GetActiveGeneSets();
        this.GetActivePTTs();
        this.GetCrops();
      if(this.draftStudyId == undefined){
        this.draftStudyId = this.paramStudyId;
      }
        if (this.draftStudyId != 0) {
            this.GetStudyByStudyId(this.draftStudyId);
            this.getStudyProject(this.draftStudyId)
            this.savedStudyId = this.draftStudyId;
        }
        else {
            this.GetActiveGeneVariants();
            this.initializeStudyGeneralInformationForm();
        }
    }

    private initializeStudyGeneralInformationForm() {
        this.generalInformation = this.fb.group({
            StudyId: [0],
            Name: ['', [Validators.required, Validators.maxLength(100)]],
            PMOProgram: ['', Validators.required],
            PMOProject: ['', Validators.required],
            ProjectTacticTeamId: ['', Validators.required],
            CropId: ['', Validators.required],
            TestQuestion: ['', [Validators.maxLength(250)]],
            KeyContacts: [''],
            IsStackedConstruct: [this.stackedConstructs.find(x => x.label == "No"), Validators.required],
            GeneSet: ['', Validators.required],
            GeneVariant: ['', Validators.required],
            EstimatedNumberOfConstructs: ['', [Validators.max(999), Validators.min(0)]],
            T0QEEventsPerPHX: ['', [Validators.max(999), Validators.min(0)]],
            T0SelectionKeepCriteria: ['', [Validators.maxLength(250)]],
            IsActive: [true]
        }, {});
    }

    studyStatus: string;
    public statusConstants: typeof StatusConstants = StatusConstants;
    isStudyReviewed: boolean = false;

    GetStudyByStudyId(studyId: number) {
        this.loader.setMessage("Loading Study...");
        this.studyService.getStudyByStudyId(studyId).subscribe(response => this.savedStudyGeneralInfo = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.displayProgressIcon = true;
                this.studyStatus = this.savedStudyGeneralInfo.Status;
                this.getStudyOwnerInformation();
                this.setGenertalInformationFormData(this.savedStudyGeneralInfo);
                this.GetActiveGeneVariants();
                this.isCropEditable = this.savedStudyGeneralInfo.Status == StatusConstants.DRAFT || this.savedStudyGeneralInfo.Status == StatusConstants.NEEDMOREINFO ? true : false;
            })
    }

    getStudyOwnerInformation(){
        if (this.studyStatus == StatusConstants.REVIEWED) {
            this.getUsersForStudyOwnerAssignment();
            this.isStudyReviewed = true;
          }
          else{
            this.studyOwners = [{ label: this.savedStudyGeneralInfo.StudyOwner, value: this.savedStudyGeneralInfo.CreatedBy }];
          }

    }
    
    userList: UserModel[];
    private getUsersForStudyOwnerAssignment() {
        // Prepare comma separted roles string and send it to API to get users for it.
        let roles =  RoleConstants.PI.concat(',').concat(RoleConstants.ADMIN).concat(',').concat(RoleConstants.MANAGER);
        this.userService.getUsersByRoles(roles).subscribe(response => this.userList = response,
          error => {
            this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
          },
          () => { 
            this.studyOwners = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.userList.filter(u => u.IsActive), "DisplayName", "UserId"); 
        })
      }

    userSearchBox: StudyKeyContactModel[];
    setGenertalInformationFormData(generlaInfo: StudyGeneralInformation) {
        //Adding User full name property to display in textbox.
        generlaInfo.KeyContacts.forEach(element => {
            element.DisplayName = element.LastName + ", " + element.FirstName;
        });
        let stackedConstructValue = this.stackedConstructs.find(x => x.value === String(generlaInfo.IsStackedConstruct));
        if (stackedConstructValue == undefined || stackedConstructValue == null) {
          stackedConstructValue = this.stackedConstructs.find(x => x.value === "No");
        }
        this.userSearchBox = generlaInfo.KeyContacts;
         this.generalInformation = this.fb.group({
            studyId: [generlaInfo.StudyId],
            CreatedBy: [generlaInfo.CreatedBy],
            StudyCode: [generlaInfo.StudyCode],
            Name: [generlaInfo.Name, [Validators.required, Validators.maxLength(100)]],
            PMOProgram: [this.pmoProgram, Validators.required],
            PMOProject: [generlaInfo.PMOProject, Validators.required],
            ProjectTacticTeamId: [generlaInfo.ProjectTacticTeamId, Validators.required],
            CropId: [generlaInfo.CropId, Validators.required],
            TestQuestion: [generlaInfo.TestQuestion, [Validators.maxLength(250)]],
            KeyContacts: [this.userSearchBox],
            IsStackedConstruct: [stackedConstructValue,Validators.required],
            GeneSet: [generlaInfo.GeneSet, Validators.required],
            GeneVariant: [generlaInfo.GeneVariant, Validators.required],
            EstimatedNumberOfConstructs: [generlaInfo.EstimatedNumberOfConstructs, [Validators.max(999), Validators.min(0)]],
            T0QEEventsPerPHX: [generlaInfo.T0QEEventsPerPHX, [Validators.max(999), Validators.min(0)]],
            T0SelectionKeepCriteria: [generlaInfo.T0SelectionKeepCriteria, [Validators.maxLength(250)]],
            IsActive: [true]
        }, {});

        this.loader.clearMessage();
    }
    generalInformation: FormGroup;
    submitted = false;
    studyGeneralInfo: StudyGeneralInformation;
    filteredKeyContactsMultiple: any[];
    filteredPrograms: any[];
    users: UserModel[];
    crops: CropModel[];
    projects: PMOProjectModel[];
    projectList: SearchableDropdownModel[];
    displayProgressIcon:boolean = false;
    isCropEditable:boolean = false;
    studyOwners: SearchableDropdownModel[];

    //User search API Constant, fetching from Environment Settings
    UserSearchAPIURL = ConfigurationSettings.USER_SEARCH_API_URL;

    cropList: SearchableDropdownModel[];

    pmoProject:any;
    pmoProgram = new pmoProgramModel();

    displayGeneVariantErrorMessage:boolean = false;

    displayTimeOutProgram:boolean = false;
    getStudyProject(projectKey){
        this.studyService.GetStudyProject(projectKey).subscribe(response => this.pmoProject = response,
          error => {
            this.displayTimeOutProgram = true;
            this.displayProgressIcon = false;
          },
          () => {
                    this.pmoProgram.pmoProgramName = this.pmoProject.pmoProgramName;
                    //Adding entry to dropdown as default value
                    this.projects = [];
                    this.projects.push(this.pmoProject);
                    this.projectList = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.projects, "pmoProjectName", "pmoProjectName");
                    this.generalInformation.patchValue({ PMOProgram : this.pmoProgram, PMOProject: this.pmoProject.pmoProjectName });
                    this.displayProgressIcon = false;
                })
            }

    private GetCrops() {
        this.cropService.getCrops().subscribe(response => this.crops = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.cropList = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.crops, "Name", "CropId");
            })
    }

    displayNoResultsProgram: boolean = false;
    displayNoResltsKeyContacts: boolean = false;
    filterProgram(event) {
        let query = event.query;
        this.pmoPortalService.getPrograms(query).subscribe(response => this.filteredPrograms = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                if (this.filteredPrograms.length == 0) {
                    this.displayNoResultsProgram = true;
                }
                else {
                    this.displayNoResultsProgram = false;
                }
            })
    }

    loadProjects(event) {
        this.projects = [];
        this.generalInformation.patchValue({ PMOProject: '' });
        let programKey = event.ProgramKey
        this.pmoPortalService.getProjects(programKey).subscribe(response => this.projects = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.projectList = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.projects, "pmoProjectName", "pmoProjectName");
            })
    }

    setProjectToFormControl(event) {
        this.generalInformation.patchValue({ PMOProject: event.value });
    }

    filterKeyContactMultiple(event) {
        let query = event.query;
        this.userService.searchUser(query).subscribe(response => this.users = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.filteredKeyContactsMultiple = this.users;
                if (this.users.length == 0) {
                    this.displayNoResltsKeyContacts = true;
                }
                else {
                    this.displayNoResltsKeyContacts = false;
                }
            })
    }

    /**
     * Method to clear hasDuplicateStudyName flag to false when value is changes
     */
    removeDuplicateNameValidation() {
        this.hasDuplicateStudyName = false;
    }

    prepareStudyKeyContactList() {
        var keyContactList: StudyKeyContactModel[] = [];
        var contacts: any[];
        contacts = this.generalInformation.value.KeyContacts;

        for (let i = 0; i < contacts?.length; i++) {
            let x = new StudyKeyContactModel();

            x.Email = contacts[i]["Email"];
            x.FirstName = contacts[i]["FirstName"];
            x.LastName = contacts[i]["LastName"];

            keyContactList.push(x);
        }
        return keyContactList;
    }


    IsActiveSelectItems: SelectItem[];
    get generalInformationControlsObj() {
        return this.generalInformation?.controls;
    }

    items: MenuItem[];
    activeIndex: number = 0;
    showGeneralInfoStep: boolean = true;


    //PTT Dropdown
    activePTTsDropdowns: SearchableDropdownModel[];
    activePTTs: ProjectTacticTeamModel[];
    GetActivePTTs() {
        this.projectTacticTeamService.getProjectTacticTeams().subscribe(response => this.activePTTs = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.activePTTsDropdowns = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.activePTTs.filter(x => x.IsActive == "Active"), "Name", "ProjectTacticTeamId");
            })
    }

    //GeneSet Dropdown
    activeGeneSetDropdowns: SearchableDropdownModel[];
    activeGeneSet: GeneSetModel[];
    GetActiveGeneSets() {
        this.geneSetService.getGeneSets().subscribe(response => this.activeGeneSet = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.activeGeneSetDropdowns = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.activeGeneSet.filter(x => x.IsActive == "Active"), "Name", "GeneSetId");
            })
    }

    //GeneVariant Dropdown
    activeGeneVariantDropdowns: SearchableDropdownModel[];
    activeGeneVariant: GeneVariantModel[];
    GetActiveGeneVariants() {
        this.geneVariantService.getGeneVariants().subscribe(response => this.activeGeneVariant = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                if (this.draftStudyId != 0) {
                    this.getGeneVariantsByGeneSets(this.savedStudyGeneralInfo.GeneSet);
                }
            })
    }

    savedStudyGeneralInfo: StudyGeneralInformation;
    generationName: string;
    savedStudyId: number;
    //Flag to check for Duplicate Study Name
    hasDuplicateStudyName: boolean = false;
    duplicateStudyNameValidationMessage: string;
    /**
     * Method to call API to Save Study General information
     * @param studyGeneralInfo
     */
    SaveStudyGeneralInfo(studyGeneralInfo: StudyGeneralInformation, action: string) {
        this.studyService.saveStudyGeneralInfo(studyGeneralInfo).subscribe(response => this.savedStudyId = response,
            error => {
                if (StudyConstants.STUDY_NAME_CONSTANT == error.error.ParamName) {
                    this.hasDuplicateStudyName = true;
                    this.duplicateStudyNameValidationMessage = error.error.Message
                    this.messageService.add({ severity: 'error', summary: '', detail: this.duplicateStudyNameValidationMessage });
                }
                else {
                    this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while saving data. Contact Administrator' })
                }
            },
            () => {
                this.ResetGeneralInformationForm();
                this.submitted = false;
                this.stepNavigation(action);
            })
    }

    /**
     * Method to call API to Update Study General information
     * @param studyGeneralInfo
     */
    updateStudyGeneralInfo(studyGeneralInfo: StudyGeneralInformation, action: string) {
        this.studyService.updatetudyGeneralInfo(studyGeneralInfo).subscribe(response => response,
            error => {
                if (StudyConstants.STUDY_NAME_CONSTANT == error.error.ParamName) {
                    this.hasDuplicateStudyName = true;
                    this.duplicateStudyNameValidationMessage = error.error.Message
                    this.messageService.add({ severity: 'error', summary: '', detail: this.duplicateStudyNameValidationMessage });
                }
                else {
                    this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while saving data. Contact Administrator' })
                }
            },
            () => {
              if(this.studyStatus == this.statusConstants.REVIEWED){
                this.router.navigate(['/viewstudy/' + this.savedStudyId]);
              } else { 
                this.ResetGeneralInformationForm();
                this.submitted = false;
                this.stepNavigation(action);
              }
            })
    }
    /**
     * Method to navegate stepper based on action
     * @param action
     */
    private stepNavigation(action: string) {
        if (action == 'NEXT') {
            this.SaveNext.emit({ stepName: StudyConstants.STUDY_GENERALINFO_CONSTANT.toString(), studyId: this.savedStudyId });
        }
        else {
            this.messageService.add({ severity: 'success', summary: '', detail: 'Study saved successfully' });
            setTimeout(() => {
                this.router.navigate(['/study']);
            }, 1000);
        }
    }

    //Method to Rest Form
    ResetGeneralInformationForm() {
        this.submitted = false;
        this.generalInformation.reset();
        this.generalInformation.patchValue({ studyId: 0, IsActive: true });
    }


    //----------------------------------
    //Method to cancel the study
    //----------------------------------
    Cancel() {
        this.confirmationService.confirm({
            message: 'All unsaved data may be lost. Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.router.navigateByUrl('/study');
            },
            reject: () => {
            }
        });
    }

   /**
    * Additional check for GenSet and GeneVariant mismatch before saving the study
    */
    validateGeneVariantsWithAssociatedGeneSets(): boolean {
        let isMismatchFound  = false;
        let allGeneVariantsForSelectedGeneSets = []
        let currentGeneVariants = [];
        allGeneVariantsForSelectedGeneSets = this.activeGeneVariantDropdowns?.map(a  => a.value);
        currentGeneVariants = this.generalInformation.value.GeneVariant;
        currentGeneVariants.forEach(function (obj) {
             if(allGeneVariantsForSelectedGeneSets.indexOf(obj) === -1){
                isMismatchFound = true;
                return;
             }
        })
        return isMismatchFound;
    }
    /**
     * Event to save Study General information
     * @param action
     */
    saveGeneralInformation(action: string) {
        this.submitted = true;

        if(this.validateGeneVariantsWithAssociatedGeneSets()){
            this.displayGeneVariantErrorMessage = true;
            return;
        }

        if (this.generalInformation.invalid) {
            return;
        }
        else {
            this.studyGeneralInfo = this.generalInformation.value;
            this.studyGeneralInfo.IsStackedConstruct = this.generalInformation.value.IsStackedConstruct.label == "Yes" ? true : false;
            this.studyGeneralInfo.PMOProgram = this.studyGeneralInfo.PMOProgram["pmoProgramName"];
            this.studyGeneralInfo.ProjectKey = this.filteredPrograms ? String(this.projects.filter(p => p.pmoProjectName == this.studyGeneralInfo.PMOProject)[0].ProjectKey) : this.savedStudyGeneralInfo.ProjectKey;
            this.studyGeneralInfo.KeyContacts = this.prepareStudyKeyContactList();
            if (this.generalInformation.dirty && this.studyGeneralInfo.StudyId != 0) {
                debugger;
                this.updateStudyGeneralInfo(this.studyGeneralInfo, action);

            }
            else if (!this.generalInformation.dirty && this.studyGeneralInfo.StudyId != 0) {
                this.stepNavigation(action);
            }
            else {
                this.SaveStudyGeneralInfo(this.studyGeneralInfo, action);
            }
        }
    }

    /**
     * OnChange event for geneset multiselect
     * @param event
     */
    onGeneSetChange(event) {
        this.getGeneVariantsByGeneSets(event.value);
        this.generalInformation.patchValue({ GeneVariant : this.resetGeneVariantsByGeneSetSelection() });
    }

    /**
     * Remove GeneVariant selection when GeneSet is deselected and provide valid GeneVariants based on current GeneSet Selection
     */
    resetGeneVariantsByGeneSetSelection() {
        let validGeneVariants = []
        let currentGeneVariants = this.generalInformation.value.GeneVariant;
        let allGeneVariantsForCurrentGeneSetSelection = this.activeGeneVariantDropdowns.map(g => g.value);
        if (currentGeneVariants) {
            validGeneVariants = currentGeneVariants.filter(function (obj) {
                return allGeneVariantsForCurrentGeneSetSelection.indexOf(obj) !== -1;
            })
        }
        return validGeneVariants;
    }


    /**
     * Method to get genevariants based on selected genesets
     * @param geneSets
     */
    getGeneVariantsByGeneSets(geneSets) {
        let geneVariantsByGensets: any[] = []
        geneSets?.forEach(geneSet => {
            var activeGenevariants = this.activeGeneVariant?.filter(x => x.GeneSetId == geneSet && x.IsActive == "Active");
            activeGenevariants?.forEach(geneVariant => {
                geneVariantsByGensets.push(geneVariant);
            })
        });
        //Sorting by GeneVariant Name
        geneVariantsByGensets = geneVariantsByGensets.sort((a, b) => a.Name.toUpperCase() !== b.Name.toUpperCase() ? a.Name.toUpperCase() < b.Name.toUpperCase() ? -1 : 1 : 0);
        this.activeGeneVariantDropdowns = new SearchableDropdownUtility().ConvertToFilterDropdownModel(geneVariantsByGensets, "Name", "GeneVariantId");
      }

      onStackedConstructChange(event: any) {
        this.generalInformation.patchValue({ GeneSet: [] });
        this.generalInformation.patchValue({ GeneVariant: [] });
        this.generalInformation.controls['GeneSet'].setValidators([Validators.required]);
        this.generalInformation.controls['GeneSet'].updateValueAndValidity();
        this.generalInformation.controls['GeneVariant'].setValidators([Validators.required]);
        this.generalInformation.controls['GeneVariant'].updateValueAndValidity();
      }

      singleSelectOption(event){
        if(this.generalInformation.value.IsStackedConstruct.label == "No"){
          this.generalInformation.patchValue({ GeneSet: [event.itemValue] });
        }
      }

    @Output() SaveNext = new EventEmitter<{ stepName: string, studyId: number }>();

    DiscardChanges() {
        this.confirmationService.confirm({
          message: 'Do you wish to continue, changes will be lost?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.router.navigate(['/viewstudy/' + this.savedStudyId]);
          },
          reject: () => {
          }
        });
    }
}
