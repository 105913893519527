import { Component, OnInit, Input,ViewChildren, QueryList, EventEmitter, Output  } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { AssayService } from 'src/app/services/assay-service/assay.service';
import * as _ from "lodash";
import { StudyAssayService } from 'src/app/services/study-service/studyAssay.service';
import { StudyService } from 'src/app/services/study-service/study.service';
import { StudyGeneralInformation } from '../study-generalInformation.model';
import { ActivatedRoute, Router } from '@angular/router';
import { StudyCommentlogService } from 'src/app/services/study-commentlog-service/study-commentlog.service';
import { stringify } from 'querystring';
import { LoadingService } from 'src/app/loading/res-loading.service';
import { StudyAssaySelectionComponent } from '../study-assay-selection/study-assay-selection.component';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { CropNameConstants } from 'src/app/Constants/crop-name-constants';
import { GenerationConstants } from 'src/app/Constants/generation-constants';
import { PermissionService } from 'src/app/services/permission-service/permission.service';
import { RoleConstants } from 'src/app/Constants/role-constants';
import { StudyConstants } from 'src/app/Constants/study-constants';
import { UserFunctionConstants } from 'src/app/Constants/user-function-constants';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { StatusConstants } from 'src/app/Constants/status-constants';
import { DialogHeaderConstants } from 'src/app/Constants/dialog-header-constants';
import { CommentPrefixConstants } from 'src/app/Constants/comment-prefix-constants';

@Component({
  selector: 'app-assay-selection',
  templateUrl: './assay-selection.component.html',
  styleUrls: ['./assay-selection.component.scss'],
  providers: [AssayService, MessageService, StudyAssayService, StudyService, StudyCommentlogService, ConfirmationService, PermissionService]
})
export class AssaySelectionComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,private loader: LoadingService,
    private studyService: StudyService, private messageService: MessageService,
    private router: Router,
    private permissionService: PermissionService,
    private confirmationService: ConfirmationService,
   ) {}

   commentsValidation: string;
   dialogHeaderText: string;
   displayCommentsDialog: boolean;
   Comments: string;

  ngOnInit() {
    this.activeIndex = 0;
    if(this.savedStudyId == undefined){
      this.savedStudyId =+ this.route.snapshot.paramMap.get("studyId");
    }
    this.getStudyAndItsGenerations(this.savedStudyId);
  }

  addGenerationValidation() {
    let studyAssaySelectionComponentReference = this.studyAssaySelectionComponent.toArray()[this.activeIndex];
    if (studyAssaySelectionComponentReference.verifyAssayDataForCurrentGeneration()) {
      if (this.studyStatus == StatusConstants.REVIEWED) {
        this.commentsValidation = "";
        this.dialogHeaderText = DialogHeaderConstants.ADD_GENERATION_CHANGE;
        this.displayCommentsDialog = true;
        this.Comments = "";
      } else {
        this.addGeneration();
      }
    }
  }

  addGeneration() {

      this.studyGenerations.sort();
      let currentGeneration = this.studyGenerations[this.studyGenerations.length - 1];
      let nextGeneration = "T";

      if (this.studyGenerations.length < this.maxGenerations) {
        nextGeneration = nextGeneration + (parseInt(currentGeneration.slice(-1)) + 1);
        this.studyGenerations.push(nextGeneration);
        this.activeIndex = this.studyGenerations.length - 1;
        this.showHideGenerationButton();
      }


      this.checkStudyHasOnlyDefaltGeneration();

  }

  showAddGeneration(functionName){
    if(this.permissionService.isUserPermittedToAccess(functionName)){
      return true;
    }
  }

  checkStudyHasOnlyDefaltGeneration(){
    this.isDefaultGeneration = this.studyGenerations.length == 1 ? true : false;
  }


  studyGenerations: any = [];
  activeIndex:number;

  savedStudyGeneralInfo: StudyGeneralInformation;
  studyGenerationsData:any;
  generationName: string;
  @Input() savedStudyId: number;
  isSubmittedForReview: boolean;
  cropId:number;
  studyStatus:string;
  IsStudyIncludesAllGenerations:boolean = false;
  maxGenerations:number;
  public userFunctions: typeof UserFunctionConstants = UserFunctionConstants;
  isDefaultGeneration:boolean = false;

  @ViewChildren(StudyAssaySelectionComponent) studyAssaySelectionComponent:QueryList<StudyAssaySelectionComponent>

   private getStudyAndItsGenerations(studyId){
        //'forkJoin' waits for each HTTP request to complete and group's all the observables returned by each HTTP call into a single observable array and finally return that observable array
        // Making here two HTTP calls, but in a similar way, you can request as many HTTP calls as required.
        forkJoin([this.studyService.getStudyByStudyId(studyId), this.studyService.GetStudyGenerations(studyId)]).subscribe((response: Array<any>) => {

            this.savedStudyGeneralInfo = response[0];
            this.studyGenerationsData = response[1];

            this.cropId = this.savedStudyGeneralInfo.CropId;
            this.studyStatus = this.savedStudyGeneralInfo.Status;
            if (this.studyStatus == StatusConstants.REVIEWED) {
              this.isSubmittedForReview = true
            }
            this.generationName = this.savedStudyGeneralInfo.CropName == CropNameConstants.ARABIDOPSIS ? GenerationConstants.T1 : GenerationConstants.T0;
            this.maxGenerations = this.savedStudyGeneralInfo.CropName == CropNameConstants.ARABIDOPSIS ? StudyConstants.STUDY_MAX_GENRATIONS_FOR_ARABIDOPSIS : StudyConstants.STUDY_MAX_GENRATIONS_FOR_OTHERS

            this.studyGenerationsData.forEach(element => {
              this.studyGenerations.push(element.GenerationName);
            });

            this.studyGenerations = this.studyGenerations.length == 0 ? [this.generationName] : this.studyGenerations;
            this.studyGenerations.length == this.maxGenerations ? this.IsStudyIncludesAllGenerations = true : this.IsStudyIncludesAllGenerations = false
            this.checkStudyHasOnlyDefaltGeneration();
          },
          (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.split('(')[0] : error })
          }
         );
    }

  switchTabs(event, tabview){
     let studyAssaySelectionComponentReference = this.studyAssaySelectionComponent.toArray()[this.activeIndex];
     if(studyAssaySelectionComponentReference.verifyAssayDataForCurrentGeneration()){
        this.activeIndex = event.index;
     }
     {
      tabview.tabs[event.index]._selected = false;
      tabview.tabs[this.activeIndex]._selected = true;
     }
   }

   validateAssayDataForGeneration(){
    let studyAssaySelectionComponentReference = this.studyAssaySelectionComponent.toArray()[this.activeIndex];
    if(studyAssaySelectionComponentReference.verifyAssayDataForCurrentGeneration()){
      return true;
    }
  }


  isClosable(generation){
    if (this.permissionService.isUserPermittedToAccess(this.userFunctions.DELETE_STUDY_GENERATION)) {
      if (this.generationName != generation) {
        if (this.studyGenerations[this.studyGenerations.length - 1] == generation) {
          return true;
        }
      }
    }
  }

  isReviewedStudyDeleteGenerationClicked: boolean = false;
  deleteStudyEvent: any;

  deleteGenerationValidate(event){
    if (this.studyStatus == StatusConstants.REVIEWED) {
      this.commentsValidation = "";
      this.dialogHeaderText = DialogHeaderConstants.DELETE_GENERATION_CHANGE;
      this.displayCommentsDialog = true;
      this.Comments = "";
      this.isReviewedStudyDeleteGenerationClicked = true;
      this.deleteStudyEvent = event;
    } else {
      this.deleteGeneration(event);
    }
  }

  deleteGeneration(event) {
    if (this.isReviewedStudyDeleteGenerationClicked) {
      let studyAssaySelectionComponentReference = this.studyAssaySelectionComponent.toArray()[event.index];
      let comment = studyAssaySelectionComponentReference.generationName + ' ' + CommentPrefixConstants.DELETE_GENERATION_COMMENT + CommentPrefixConstants.HYPHEN_COMMENT + this.Comments;
      studyAssaySelectionComponentReference.AddAssayEditComments(studyAssaySelectionComponentReference.prepareStudyStatus(this.savedStudyId, this.studyStatus, comment));
      this.deleteGenerationSave(event);
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure, do you want to delete the generation. Please Confirm',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.deleteGenerationSave(event);
        },
        reject: () => {
        }
      });
    }
  }

  showHideGenerationButton(){
    if(this.studyGenerations.length == this.maxGenerations){
       this.IsStudyIncludesAllGenerations = true;
    }
    else{
      this.IsStudyIncludesAllGenerations = false;
    }
 }

  deleteGenerationSave(event) {
    let generationToDelete = this.studyGenerations[event.index];
    this.loader.setMessage("Deleting Study Generation...")
    this.studyService.DeleteStudyGeneration(this.savedStudyId, generationToDelete).subscribe(response => this.studyGenerationsData = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        this.studyGenerations.splice(-1);
        this.checkStudyHasOnlyDefaltGeneration();
        this.showHideGenerationButton();
        this.activeIndex = event.index - 1;
        this.isReviewedStudyDeleteGenerationClicked = false;
        this.messageService.add({ severity: 'success', summary: '', detail: generationToDelete + ' Generation Deleted Successfully' });
      })
  }

  @Output() PreviousButtonEvent = new EventEmitter<{ stepName: string, studyId: number }>();
  @Output() NextButtonEvent = new EventEmitter<{ stepName: string, studyId: number }>();
  @Output() CloseButtonEvent = new EventEmitter<string>();

  Previous() {
    this.PreviousButtonEvent.emit({ stepName: StudyConstants.ASSAY_SELECTION_CONSTANT.toString(), studyId: this.savedStudyId });
  }

  Next() {
    this.NextButtonEvent.emit({ stepName: StudyConstants.ASSAY_SELECTION_CONSTANT.toString(), studyId: this.savedStudyId });
  }

  Close() {
    this.router.navigateByUrl('/study');
  }

  commentCanceled() {
    this.displayCommentsDialog = false;
    this.commentsValidation = "";
  }

  commentConfirmed() {
    this.commentsValidation = "";
    if (!this.Comments) {
      this.commentsValidation = "Comments Required";
      return
    }
    if (this.Comments) {
      if (this.Comments.length > 220) {
        this.commentsValidation = "Comments Length is exceeded";
        return
      }
    }
    this.displayCommentsDialog = false;
    if (this.isReviewedStudyDeleteGenerationClicked) {
      this.deleteGeneration(this.deleteStudyEvent);
    } else {
      this.addGeneration();
    }
  }
}
