import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';
import { RoleComponent } from './role/role.component';
import { ProjectTacticTeamComponent } from './Administrator/projecttacticteam/projecttacticteam.component';
import { GeneSetComponent } from './Administrator/geneset/geneset.component';
import { GeneVariantComponent } from './Administrator/genevariant/genevariant.component';
import { AssayDetailComponent } from './Administrator/assay-detail/assay-detail.component';
import { AssayComponent } from './Administrator/assay/assay.component';
import { SamplingWeekComponent } from './Administrator/samplingweek/samplingweek.component';
import { AuthenticateComponent } from './login/authenticate.component';
import { UnauthorizedComponent } from './login/unauthorized.component';
import { StudyListComponent } from './study/study-list/study-list.component';
import { StudyComponent } from './study/manage-study/study.component';
import { ViewStudyComponent } from './study/view-study/view-study.component';
import { UserRoleComponent } from './Administrator/user-role/user-role.component';
import { StudyAssaySelectionComponent } from './study/manage-study/study-assay-selection/study-assay-selection.component';
import { RoleGuardService } from './services/security-service/role-gaurd-service';
import { RoleConstants } from './Constants/role-constants';
import { AssaySamplingReportComponent } from './reports/assay-sampling-report/assay-sampling-report.component';
import { AssaySelectionComponent } from './study/manage-study/assay-selection/assay-selection.component';
import { StudyGeneralInformation } from './study/manage-study/study-generalInformation.model';
import { StudyGeneralInformationComponent } from './study/manage-study/study-generalInformation/study-generalInformation.component';


const routes: Routes = [
  { path: 'user', component: UserComponent },
  { path: 'role', component: RoleComponent },

  //Admin Routes
  { path: 'admin/dat', pathMatch: 'full', component: ProjectTacticTeamComponent, canActivate: [MsalGuard] },
  { path: 'admin/geneset', pathMatch: 'full', component: GeneSetComponent, canActivate: [MsalGuard] },
  { path: 'admin/genevariant', pathMatch: 'full', component: GeneVariantComponent, canActivate: [MsalGuard] },
  { path: 'admin/samplingweek', pathMatch: 'full', component: SamplingWeekComponent, canActivate: [MsalGuard] },
  { path: 'study', pathMatch: 'full', component: StudyListComponent, canActivate: [MsalGuard] },
  { path: 'createstudy/:studyId', pathMatch: 'full', component: StudyComponent, canActivate: [MsalGuard] },
  { path: 'createstudy', pathMatch: 'full', component: StudyComponent, canActivate: [MsalGuard] },
  { path: 'viewstudy/:studyId', pathMatch: 'full', component: ViewStudyComponent, canActivate: [MsalGuard] },
  { path: 'admin/assay', pathMatch: 'full', component: AssayComponent, canActivate: [MsalGuard] },
  { path: 'admin/assaydetail', pathMatch: 'full', component: AssayDetailComponent, canActivate: [MsalGuard] },
  { path: 'admin/user', pathMatch: 'full', component: UserRoleComponent, canActivate: [RoleGuardService], data: { expectedRole: RoleConstants.ADMIN } },
  { path: 'study/:studyId/editassay', pathMatch: 'full', component: AssaySelectionComponent, canActivate: [MsalGuard], data: { expectedRole: [RoleConstants.ADMIN , RoleConstants.MANAGER ]}  },
  { path: 'study/:studyId/editgeneralinfo', pathMatch: 'full', component: StudyGeneralInformationComponent, canActivate: [MsalGuard], data: { expectedRole: [RoleConstants.ADMIN , RoleConstants.MANAGER ]}  },
  { path: 'reports/assaysampling', pathMatch: 'full', component: AssaySamplingReportComponent, canActivate: [MsalGuard] },
  { path: 'authenticate', component: AuthenticateComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  //Below route is used to redirect the user on login page when given route in URL is empty.(http://localhost:4200/)
  { path: '', redirectTo: '/study', pathMatch: 'full' },
  //Below route is used to redirect the user on login page when given route in URL is not defined in application routes.(http://localhost:4200/**)
  { path: '**', redirectTo: '/study', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
