

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { ConfigurationSettings } from "../.././configuration-settings"
import { AssayModel } from 'src/app/Administrator/assay/assay.model';

@Injectable()
export class AssayService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private _http:HttpClient) { }

  public createAssay(assay:AssayModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/assays"
    let body= JSON.stringify(assay);
    return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Created new Assay"))
            );
    }

  public getAssays():any{
    let url=ConfigurationSettings.REST_API_URL + "/assays"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Assays")));
    }

  public updateAssay(assay:AssayModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/assays"
    let body= JSON.stringify(assay);
    return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Updated Assay"))
            );
    }

  public deleteAssay(Id:number):any{
    let url=ConfigurationSettings.REST_API_URL + "/assays/"+Id;
   return this._http.delete(url).pipe(
            tap(x => console.log("Deleted Assay")));
    }


    public getAssaysForStudy():any{
      let url=ConfigurationSettings.REST_API_URL + "/assays/selection"
          return this._http.get(url).pipe(
              tap(x => console.log("Fetched Assays")));
      }
}