

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { ConfigurationSettings } from "../.././configuration-settings"
import { StudyKeyContactModel } from 'src/app/study/manage-study/study-keycontact.model';

@Injectable()
export class StudyKeyContactService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private _http:HttpClient) { }


  public updateStudyKeyContact(studyId:number, studykeycontactList:StudyKeyContactModel[]):any{
    let url=ConfigurationSettings.REST_API_URL + "/study/"+studyId+"/key-contacts"
    let body= JSON.stringify(studykeycontactList);
    return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Updated StudyKeyContactList"))
            );
    }
}