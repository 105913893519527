import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SelectItem, MessageService, ConfirmationService } from 'primeng/api';
import { AssayModel } from 'src/app/Administrator/assay/assay.model';
import { AssayService } from 'src/app/services/assay-service/assay.service';
//import { and } from '@angular/router/src/utils/collection';
import { FormArray, AbstractControl, Validators, FormControl, FormBuilder, FormGroup, NgModel } from '@angular/forms';
import { SearchableDropdownModel } from 'src/app/services/UtilityService/searchableDropdownModel';
import { SearchableDropdownUtility } from 'src/app/services/UtilityService/searchableDropdownUtility';
import * as _ from "lodash";
import { StudyAssaySelectionModel } from './study-assay-selection.model';
import { AssayCategoryTypeDetailModel } from 'src/app/Administrator/assay-detail/assaycategorytypedetail.model';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { StudyAssayService } from 'src/app/services/study-service/studyAssay.service';
import { StudyAssayModel } from './study-assay.model';
import { LoadingService } from 'src/app/loading/res-loading.service';
import { StudyService } from 'src/app/services/study-service/study.service';
import { StudyGeneralInformation } from '../study-generalInformation.model';
import { AssayTypeConstants } from 'src/app/Constants/assay-type-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusConstants } from 'src/app/Constants/status-constants';
import { StudyStatusModel } from '../study-status.model';
import { AssaysGroupByAssayCategoryModel } from './assay-groupby-assayCategory.model';
import { StudyCommentlogService } from 'src/app/services/study-commentlog-service/study-commentlog.service';
import { GenerationConstants } from 'src/app/Constants/generation-constants';
import { StudyConstants } from 'src/app/Constants/study-constants';
import { StudyGenerationCommentModel } from '../generation-comment.model';
import { StudyGenerationCommentService } from 'src/app/services/generation-comment-service/generation-comment.service';
import { CommentPrefixConstants } from 'src/app/Constants/comment-prefix-constants';

@Component({
  selector: 'app-study-assay-selection',
  templateUrl: './study-assay-selection.component.html',
  styleUrls: ['./study-assay-selection.component.scss'],
  providers: [AssayService, MessageService, StudyAssayService,StudyGenerationCommentService, StudyService, ConfirmationService, StudyCommentlogService]
})
export class StudyAssaySelectionComponent implements OnInit {

  constructor(private fb: FormBuilder, private assayService: AssayService, private messageService: MessageService, private studyAssayService: StudyAssayService, private loader: LoadingService,
    private studyService: StudyService, private route: ActivatedRoute, private router: Router, private confirmationService: ConfirmationService, private studyCommentLogService: StudyCommentlogService,
    private studyGenerationCommentService: StudyGenerationCommentService) {
    this.expressionAssayTypeConstant = AssayTypeConstants.EXPRESSION;
    this.efficacyAssayTypeConstant = AssayTypeConstants.EFFICACY;

  }

  ngOnInit() {
    //StudyId from route is present only for Admin Edit after Study is reviewed
    let studyId = this.route.snapshot.paramMap.get("studyId");

    if (studyId) {
      this.savedStudyId = parseInt(studyId);
    }

    this.initializeEditAssayDetailsForm(null);

    this.GetAssays();

    if (this.status == StatusConstants.REVIEWED) {
      this.isSubmittedForReview = true
    }

    this.GetSelectedStudyAssaysForGeneration(this.savedStudyId, this.generationName);
    this.getStudyGenerationComment();
  }

  @Input() generationName: string;
  @Input() savedStudyId: number;
  @Input() activeExpressionAssayGroupedListss: AssaysGroupByAssayCategoryModel[];
  @Input() cropId: number;
  @Input() status: string;
  @Input() defaultGeneration:boolean;
  assays: AssayModel[];
  assaysForSelectionList: AssayModel[];
  activeExpressionAssayGroupedList: AssaysGroupByAssayCategoryModel[];
  activeEfficacyAssayGroupedList: AssaysGroupByAssayCategoryModel[];
  activePhenotypeAssayGroupedList: AssaysGroupByAssayCategoryModel[];
  activeSelectionAssayGroupedList: AssaysGroupByAssayCategoryModel[];
  studyGenerationComments: StudyGenerationCommentModel;
  selectionCriteriaModel: string = '';
  pollinationInstructionsModel: string = '';
  generalCommentsModel: string = '';

  isSubmittedForReview: boolean = false;
  //-----------------------------------------------------------------------------
  // Get list of Assay from Assays data table
  //-----------------------------------------------------------------------------
  private GetAssays() {
    this.assayService.getAssaysForStudy().subscribe(response => this.assaysForSelectionList = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
          this.seperateAssaysByAssayType(this.assaysForSelectionList, this.cropId);
      })
  }

  /**
   * Method to seggregate assays to Expression & Efficacy
   */
  seperateAssaysByAssayType(assaysForSelectionList: AssayModel[], cropId: number) {
    let activeExpressionAssayList = assaysForSelectionList?.filter(x => x.AssayType == AssayTypeConstants.EXPRESSION && x.CropId == cropId).sort((a, b) => (a.AssayCategoryType < b.AssayCategoryType ? -1 : 1));
    let activeEfficacyAssayList = assaysForSelectionList?.filter(x => x.AssayType == AssayTypeConstants.EFFICACY && x.CropId == cropId).sort((a, b) => (a.AssayCategoryType < b.AssayCategoryType ? -1 : 1));
    let activePhenotypeAssayList = assaysForSelectionList?.filter(x => x.AssayType == AssayTypeConstants.PHENOTYPE && x.CropId == cropId).sort((a, b) => (a.AssayCategoryType < b.AssayCategoryType ? -1 : 1));
    let activeSelectionAssayList = assaysForSelectionList?.filter(x => x.AssayType == AssayTypeConstants.SELECTION && x.CropId == cropId).sort((a, b) => (a.AssayCategoryType < b.AssayCategoryType ? -1 : 1));

    this.activeExpressionAssayGroupedList = this.GroupAssaysByAssayType(activeExpressionAssayList);
    this.activeEfficacyAssayGroupedList = this.GroupAssaysByAssayType(activeEfficacyAssayList);
    this.activePhenotypeAssayGroupedList = this.GroupAssaysByAssayType(activePhenotypeAssayList);
    this.activeSelectionAssayGroupedList = this.GroupAssaysByAssayType(activeSelectionAssayList);
  }

  /**
   * Method to Group assays by AssayType
   * @param assays
   */
  GroupAssaysByAssayType(assays: AssayModel[]) {
    let assaysGrouped: AssaysGroupByAssayCategoryModel[] = []
    let assaysGroupedObject: AssaysGroupByAssayCategoryModel[] = assays?.reduce(function (r, a) {
      r[a.AssayCategoryType] = r[a.AssayCategoryType] || [];
      r[a.AssayCategoryType].push(a);
      return r;
    }, Object.create(null))

    assaysGrouped = Object.keys(assaysGroupedObject).map(function (y) {
      let assayGroupModel = new AssaysGroupByAssayCategoryModel();
      assayGroupModel.AssayCategoryName = y;
      assayGroupModel.Assays = assaysGroupedObject[y].sort(function (a, b) {
        return a.Name.localeCompare(b.Name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return assayGroupModel;
    })
    return assaysGrouped;
  }

  selectedExpressionAssays: StudyAssaySelectionModel[] = [];
  selectedEfficacyAssays: StudyAssaySelectionModel[] = [];
  selectedPhenotypeAssays: StudyAssaySelectionModel[] = [];
  selectedSelectionAssays: StudyAssaySelectionModel[] = [];

  /* #region  Region for code related to displaying selected assays */
  assayToEdit: StudyAssaySelectionModel;
  dialogHeader: string;
  editAssayDetailsFormGroup: FormGroup;
  displayDialog: boolean;
  selectedAssayDetails: AssayCategoryTypeDetailModel[];

  selectedAssayIds: any[] = [];

  stuydAssayList: StudyAssayModel[] = [];
  expressionAssayTypeConstant: string;
  efficacyAssayTypeConstant: string;

  isBulkAssaySelectionEdit: boolean = false;
  isSingleAssayDelete: boolean = false;
  assayToDelete: StudyAssaySelectionModel;

  /**
   * event after adding assays to selection
   * @param selectedAssayIds : array of AssayIds
   * @param assayType : Expression or Efficacy
   */
  AddAssaysToSelection(selectedAssayIds) {
    this.isBulkAssaySelectionEdit = true;
    this.isSubmitted = false;
    this.stuydAssayList = [];
    selectedAssayIds.forEach(element => {
      let studyAssay = new StudyAssayModel();
      studyAssay.AssayId = element;
      studyAssay.Generation = this.generationName;
      studyAssay.StudyId = this.savedStudyId;

      this.stuydAssayList.push(studyAssay);
    });

    //Action based on Study Status
    if (this.status == StatusConstants.REVIEWED) {
      this.commentsValidation = "";
      this.dialogHeaderText = "Assay Selection Change"
      this.displayCommentsDialog = true;
      this.Comments = "";
    }
    else {
      this.createStudyAssays(this.savedStudyId, this.stuydAssayList);
    }

  }

  createStudyAssaysResponse: any;

  /**
   * Method to Create new StudyAssays
   * @param studyAssays
   */
  createStudyAssays(studyId: number, studyAssays: StudyAssayModel[]) {
    this.studyAssayService.createStudyAssays(studyId, studyAssays).subscribe(response => this.createStudyAssaysResponse = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        this.isBulkAssaySelectionEdit = false;
        if (this.status != StatusConstants.DRAFT) {
          this.messageService.add({ severity: 'success', summary: '', detail: 'Assay Selection updated.' })
        }
        //Replace StudyId with this.savedStudyGeneralInfo.StudyId
        this.GetSelectedStudyAssaysForGeneration(this.savedStudyId, this.generationName);
      })
  }

  savedStudyAssays: StudyAssaySelectionModel[];

  /**
   * Method to fetch StudyAssays
   * @param studyId
   * @param generationId
   */
  GetSelectedStudyAssaysForGeneration(studyId, generation) {
    this.studyAssayService.getStudyAssaysForGeneration(studyId, generation).subscribe(response => this.savedStudyAssays = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        this.selectedExpressionAssays = this.savedStudyAssays.filter(x => x.AssayModel.AssayType === AssayTypeConstants.EXPRESSION);
        this.selectedEfficacyAssays = this.savedStudyAssays.filter(x => x.AssayModel.AssayType === AssayTypeConstants.EFFICACY);
        this.selectedPhenotypeAssays = this.savedStudyAssays.filter(x => x.AssayModel.AssayType === AssayTypeConstants.PHENOTYPE);
        this.selectedSelectionAssays = this.savedStudyAssays.filter(x => x.AssayModel.AssayType === AssayTypeConstants.SELECTION);
        this.selectedAssayIds = [];
        this.selectedAssayIds = this.savedStudyAssays.map(function (assay) { return String(assay.AssayModel.AssayId) });
        this.loader.clearMessage();
      });
  }
  /* #endregion */

  /* #region for adding Assaydetails */

  /**
   * Method to initialize Form group to edit assay details
   * @param assay : StudyAssaySelectionModel
   */
  initializeEditAssayDetailsForm(assay: StudyAssaySelectionModel) {

    let inActiveStudyAssayDetails: AssayCategoryTypeDetailModel[] = []
    console.log(assay);
    if (assay != null) {

      this.editAssayDetailsFormGroup = this.fb.group({
        StudyGenerationAssayId: [assay.StudyGenerationAssayId],
        AssayModel: [assay.AssayModel],
        //SelectedAssayCategoryTypeDetails: [assay.SelectedAssayCategoryTypeDetails, [Validators.required]],
        Comments: [assay.Comments, [Validators.maxLength(250)]]
      })

      if (assay.AssayModel.IsCriteriaForPass) {
        this.editAssayDetailsFormGroup.addControl('CriteriaToPass', new FormControl(assay.CriteriaToPass, [Validators.required, Validators.maxLength(250)]))
      }
      if (assay.AssayCategoryTypeDetails != null && assay.AssayCategoryTypeDetails[0] != null) {
        // pulling only active details list for source options
        assay.AssayCategoryTypeDetails = assay.AssayCategoryTypeDetails.filter(a => a.IsActive == "Active");

        if (assay.SelectedAssayCategoryTypeDetails) { //  for edit scenarios: prepare list of  inactive selected detials
          for (var i in assay.SelectedAssayCategoryTypeDetails) {
            if (assay.AssayCategoryTypeDetails.filter(a => a.AssayCategoryTypeDetailId === assay.SelectedAssayCategoryTypeDetails[i].AssayCategoryTypeDetailId).length == 0) {
              inActiveStudyAssayDetails.push(assay.SelectedAssayCategoryTypeDetails[i]);
            }
          }
          assay.AssayCategoryTypeDetails.push.apply(assay.AssayCategoryTypeDetails, inActiveStudyAssayDetails); // append to master list
          assay.AssayCategoryTypeDetails = assay.AssayCategoryTypeDetails.sort((a, b) => (a.Name < b.Name ? -1 : 1)); // sort list based on name
        }

        this.editAssayDetailsFormGroup.addControl('SelectedAssayCategoryTypeDetails', new FormControl(assay.SelectedAssayCategoryTypeDetails, [Validators.required]))
      }
    }
  }

  //Creating Form group object
  get editAssayDetailsControlsObj() { return this.editAssayDetailsFormGroup.controls; }

  selectedAssayDetailsForEditedAssay: AssayCategoryTypeDetailModel[] = [];

  //Is false if AsssayDetails selection is null.
  isNewAssaySelection = false;
  /**
   * Click event for editing Assay Category Type details
   * @param assay
   */
  editAssayDetails(assay: StudyAssaySelectionModel) {
    this.assayDetailsChangeComments = "";
    this.isNewAssaySelection = false;
    this.assayDetailsChanged = false;

    //Check if the selected Assay is newly added or editing an existing
    if (assay.SelectedAssayCategoryTypeDetails == null) {
      this.isNewAssaySelection = true;
    }
    this.initializeEditAssayDetailsForm(assay);
    this.assayToEdit = null;
    this.selectedAssayDetails = [];
    this.assayToEdit = assay;
    this.dialogHeader = 'Add details for ' + this.assayToEdit.AssayModel.Name;
    this.displayDialog = true;
    this.submitted = false;
  }
  submitted: boolean = false;

  editedAssayDetails: StudyAssaySelectionModel;

  /**
   * Button click event for Saving Assay Details dialog
   */
  SaveAssayCategoryTypeDetails() {
    this.submitted = true;

    if (this.editAssayDetailsFormGroup.invalid) {
      return;
    }
    else {
      this.displayDialog = false;
      let editedAssayDetails: StudyAssaySelectionModel;
      editedAssayDetails = this.editAssayDetailsFormGroup.value;
      editedAssayDetails.SelectedAssayCategoryTypeDetails = editedAssayDetails.SelectedAssayCategoryTypeDetails;

      //Saving Assay Details Change Comments only if assayDetailsChanged===TRUE.
      if (this.assayDetailsChanged) {
        let comment = this.generationName + ' ' + CommentPrefixConstants.ASSAY_DETAILS_UPDATED_COMMENT + CommentPrefixConstants.HYPHEN_COMMENT + this.assayDetailsChangeComments;
        this.AddAssayEditComments(this.prepareStudyStatus(this.savedStudyId, this.status, comment));
        this.assayDetailsChangeComments = "";
      }

      this.UpdateSelectedAssays(editedAssayDetails);
    }
  }

  /**
   * Method to update the AssayCategoryTypeDetails of Selected assay
   * @param assayToEdit
   */
  private UpdateSelectedAssays(assayToEdit: StudyAssaySelectionModel) {

    let studyAssay = new StudyAssayModel();

    studyAssay.StudyGenerationAssayId = assayToEdit.StudyGenerationAssayId;
    studyAssay.PassCriteria = assayToEdit.CriteriaToPass;
    studyAssay.Comments = assayToEdit.Comments;
    studyAssay.StudyId = this.savedStudyId;
    studyAssay.AssayId = assayToEdit.AssayModel.AssayId;
    studyAssay.Generation = this.generationName;

    if (assayToEdit.SelectedAssayCategoryTypeDetails != null) {
      studyAssay.AssayCategoryTypeDetailIds = assayToEdit.SelectedAssayCategoryTypeDetails.map(function (x) { return x.AssayCategoryTypeDetailId });
    }
    this.UpdateStudyAssays(this.savedStudyId, studyAssay);
  }

  updateStudyAssayResponse: any;

  /**
   * Method to Updated StudyAssay Details with Comments, Assay Category
   * @param studyAssay
   */
  UpdateStudyAssays(studyId, studyAssay: StudyAssayModel) {
    this.studyAssayService.updateStudyAssayDetails(studyId, studyAssay).subscribe(response => this.updateStudyAssayResponse = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        this.GetSelectedStudyAssaysForGeneration(this.savedStudyId, this.generationName);
      })
  }

  //Click method to close Edit Assay Category Type Details Dialog
  CancelAssayCategoryTypeDetails() {
    this.displayDialog = false;
  }


  /**
   * Button click event method for Deleting an Assay
   * @param assay
   */
  deleteStudyAssay(assay: StudyAssaySelectionModel) {
    if (this.status == StatusConstants.REVIEWED) {
      this.isSingleAssayDelete = true;
      this.assayToDelete = assay;
      this.commentsValidation = "";
      this.dialogHeaderText = "Assay Deletion"

      this.displayCommentsDialog = true;
      this.Comments = "";
    }
    else {
      this.DeleteSelectedStudyAssay(assay.StudyGenerationAssayId);
    }
  }


  DeleteSelectedStudyAssay(StudyGenerationAssayId: number) {
    this.studyAssayService.deleteStudyAssay(StudyGenerationAssayId).subscribe(response => this.deleteStudyAssayResponse = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        this.isSingleAssayDelete = false;
        this.GetSelectedStudyAssaysForGeneration(this.savedStudyId, this.generationName);
        if (this.status !== StatusConstants.DRAFT) {
          this.messageService.add({ severity: 'success', summary: '', detail: 'Assay removed from study.' })
        }
      })
  }
  /* #End region */


  @Output() PreviousButtonEvent = new EventEmitter<{ stepName: string, studyId: number }>();
  @Output() NextButtonEvent = new EventEmitter<{ stepName: string, studyId: number }>();
  @Output() CloseButtonEvent = new EventEmitter<string>();

  Previous() {
    if (this.checkStudyIsValid()) {
    this.PreviousButtonEvent.emit({ stepName: StudyConstants.ASSAY_SELECTION_CONSTANT.toString(), studyId: this.savedStudyId });
    }
  }

  Next() {
    if(this.verifyAssayDataForCurrentGeneration()){
     this.NextButtonEvent.emit({ stepName: StudyConstants.ASSAY_SELECTION_CONSTANT.toString(), studyId: this.savedStudyId });
    }
  }

  Close() {
    if (this.checkStudyIsValid()) {
      this.router.navigateByUrl('/study');
      this.CloseButtonEvent.emit(this.generationName);
    }
  }

  checkStudyIsValid():boolean{
    this.isValidStudy = true;
    if (!this.isStudyValid(true)) {
      this.isValidStudy = false;
      this.confirmationService.confirm({
        message: this.validationMessage,
        header: 'Validation Failed',
        icon: null,
        acceptLabel: 'Ok',
        rejectVisible: false
      });
    }
    return this.isValidStudy;
  }


  isSubmitted: boolean = false;

  /**
   * Button event to return to View page
   */
  ReturnToView() {
    this.isSubmitted = true;

    if(this.verifyAssayDataForCurrentGeneration()){
      this.router.navigate(['/viewstudy/' + this.savedStudyId]);
    }
  }

  /**
   * Method to validate the assays when Admin edits in Reviews status
   */
  ValidateAssays() {
    if (this.savedStudyAssays.length == 0) {
      this.confirmationService.confirm({
        message: 'Please select at least one assay to proceed further.',
        header: 'Validation Failed',
        icon: null,
        acceptLabel: 'Ok',
        rejectVisible: false
      });
      return false;
    }
    let hasAssayRequirementsFullfilled: boolean = true;
    let assayRequirementMessage: string = "";

    //Checking if atleast one T0 assay is available
    if (this.assaysForSelectionList.length == 0) {
      hasAssayRequirementsFullfilled = false;
    }

    //Validating the Assay Details selection
    let isExpressionAssaysValid = this.selectedExpressionAssays.filter(x => x.AssayCategoryTypeDetails != null && x.SelectedAssayCategoryTypeDetails == null).length == 0 ? true : false
    let isEfficacyAssaysValid = this.selectedEfficacyAssays.filter(x => x.AssayCategoryTypeDetails != null && x.SelectedAssayCategoryTypeDetails == null).length == 0 ? true : false
    let isPhenotypeAssaysValid = this.selectedPhenotypeAssays.filter(x => x.AssayCategoryTypeDetails != null && x.SelectedAssayCategoryTypeDetails == null).length == 0 ? true : false
    let isSelectionAssaysValid = this.selectedSelectionAssays.filter(x => x.AssayCategoryTypeDetails != null && x.SelectedAssayCategoryTypeDetails == null).length == 0 ? true : false

    //Check if Critera comments are provided for assays.
    let isExpressionAssayCriteriaCommentsEntered = this.selectedExpressionAssays.filter(x => x.AssayModel.IsCriteriaForPass == true && x.CriteriaToPass == null).length == 0 ? true : false;
    let isEfficacyAssayCriteriaCommentsEntered = this.selectedEfficacyAssays.filter(x => x.AssayModel.IsCriteriaForPass == true && x.CriteriaToPass == null).length == 0 ? true : false;
    let isPhenotypeAssayCriteriaCommentsEntered = this.selectedPhenotypeAssays.filter(x => x.AssayModel.IsCriteriaForPass == true && x.CriteriaToPass == null).length == 0 ? true : false;
    let isSelectionAssayCriteriaCommentsEntered = this.selectedSelectionAssays.filter(x => x.AssayModel.IsCriteriaForPass == true && x.CriteriaToPass == null).length == 0 ? true : false;

    if (hasAssayRequirementsFullfilled && isExpressionAssaysValid && isPhenotypeAssaysValid && isEfficacyAssaysValid && isExpressionAssayCriteriaCommentsEntered && isEfficacyAssayCriteriaCommentsEntered
      && isSelectionAssaysValid && isPhenotypeAssayCriteriaCommentsEntered && isSelectionAssayCriteriaCommentsEntered) {
      return true;
    }
    else {
      this.confirmationService.confirm({
        message: 'Mandatory details not provided.',
        header: 'Validation Failed',
        icon: null,
        acceptLabel: 'Ok',
        rejectVisible: false
      });
      return false;
    }
  }

  deleteStudyAssayResponse: any;
  displayCommentsDialog: boolean = false;
  dialogHeaderText: any;
  Comments: string;
  commentsValidation: string;
  @Input() addGenerationComment: string;
  /**
 * Method to update study status
 */
  DeleteConfirmed() {
    this.commentsValidation = "";
    if (!this.Comments) {
      this.commentsValidation = "Comments Required";
      return
    }
    if (this.Comments) {
      if (this.Comments.length > 220) {
        this.commentsValidation = "Comments Length is exceeded";
        return
      }
    }
    if (this.addGenerationComment) {
      let comment = this.generationName + ' ' + CommentPrefixConstants.ADD_GENERATION_COMMENT + CommentPrefixConstants.HYPHEN_COMMENT + this.addGenerationComment;
      this.AddAssayEditComments(this.prepareStudyStatus(this.savedStudyId, this.status, comment));
      this.addGenerationComment = "";
    } 

    let status: string = this.status;
    let assayComment: string = this.Comments;
    if (this.isBulkAssaySelectionEdit) {
      assayComment = this.generationName + ' ' + CommentPrefixConstants.ASSAY_UPDATED_COMMENT + CommentPrefixConstants.HYPHEN_COMMENT + this.Comments;
    } else {
      assayComment = this.generationName + ' ' + CommentPrefixConstants.ASSAY_DELETED_COMMENT + CommentPrefixConstants.HYPHEN_COMMENT + this.Comments;
    }

    this.AddAssayEditComments(this.prepareStudyStatus(this.savedStudyId, status, assayComment));

   
    if (this.isBulkAssaySelectionEdit) {
      this.createStudyAssays(this.savedStudyId, this.stuydAssayList);
    }
    else {
      this.DeleteSelectedStudyAssay(this.assayToDelete.StudyGenerationAssayId);
    }
  }

  /**
   * close the dialog
   */
  DeleteCanceled() {
    this.isBulkAssaySelectionEdit = false;
    this.isSingleAssayDelete = false;
    this.displayCommentsDialog = false;
    this.commentsValidation = "";
  }

  /**
     * create study status
     * @param studyStatusModel
     */
    public AddAssayEditComments(studyStatusModel: StudyStatusModel) {
      this.studyCommentLogService.createStudyComment(studyStatusModel).subscribe(res => res,
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while adding. Contact Administrator' })
        },
        () => {
          this.displayCommentsDialog = false;
        });
    }

  /**
 * prepare study status model
 * @param studyId
 * @param status
 */
  prepareStudyStatus(studyId, status, comments) {
    let studyStatus = new StudyStatusModel();
    studyStatus.StudyId = studyId;
    studyStatus.Status = status;
    studyStatus.Comments = comments;

    return studyStatus;
  }

  assayDetailsChanged = false;
  assayDetailsChangeComments = "";

  /**
   * Assay Details Selection Dropdown change event method
   */
  AssayDetailsChangeEvent() {
    this.assayDetailsChanged = false;
    const isAssayDetailsChanged = this.editAssayDetailsFormGroup.controls['SelectedAssayCategoryTypeDetails'].dirty;

    // Prompt Assay Details change comments
    // IF Study in REVIEWED status
    // AND IF AssayDetailsSelection Changed
    // AND IF Its not a new Assay Added
    if (this.status === StatusConstants.REVIEWED && isAssayDetailsChanged && !this.isNewAssaySelection) {
      this.assayDetailsChanged = true;
    }
  }

  saveUpdateGenerationComments() {
    this.studyGenerationComments.GeneralComments = this.generalCommentsModel;
    this.studyGenerationComments.PollinationInstructions = this.pollinationInstructionsModel;
    this.studyGenerationComments.SelectionCriteria = this.selectionCriteriaModel;
    this.studyGenerationComments.StudyId = this.savedStudyId;
    this.studyGenerationComments.GenerationName = this.generationName;
    if (this.studyGenerationComments.StudyGenerationCommentId == 0) {
      this.saveGenerationComment(this.studyGenerationComments);
    }
    else {
      this.updateGenerationComment(this.studyGenerationComments);
    }
    if (this.addGenerationComment) {
      let comment = this.generationName + ' ' + CommentPrefixConstants.ADD_GENERATION_COMMENT + CommentPrefixConstants.HYPHEN_COMMENT + this.addGenerationComment;
      this.AddAssayEditComments(this.prepareStudyStatus(this.savedStudyId, this.status, comment));
      this.addGenerationComment = "";
    }
  }

    private saveGenerationComment(generationCommentModel: StudyGenerationCommentModel) {
      this.studyGenerationCommentService.createGenerationComment(generationCommentModel).subscribe(res => res,
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while adding. Contact Administrator' })
        }, () => {
          this.getStudyGenerationComment();
        })
    }

    private updateGenerationComment(generationCommentModel: StudyGenerationCommentModel ){
      this.studyGenerationCommentService.updateGenerationComment(generationCommentModel).subscribe(res => res,
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while adding. Contact Administrator' })
        })
    }

  getStudyGenerationComment() {
    this.studyGenerationCommentService.getGenerationComment(this.savedStudyId,this.generationName).subscribe(response => this.studyGenerationComments = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        this.selectionCriteriaModel = this.studyGenerationComments.SelectionCriteria;
        this.pollinationInstructionsModel = this.studyGenerationComments.PollinationInstructions;
        this.generalCommentsModel = this.studyGenerationComments.GeneralComments;
      })
  }

  isStudyValid(verifyDetails?: boolean):boolean {
    let hasAssayRequirementsFullfilled: boolean = true;
    let assayRequirementMessage: string = "";
    this.isSubmitted = true;

    if (!verifyDetails) {
      //Checking if atleast one T0 assay is available
      if (this.selectedExpressionAssays.length == 0 && this.selectedEfficacyAssays.length == 0 && this.selectedPhenotypeAssays.length == 0 && this.selectedSelectionAssays.length == 0) {
        hasAssayRequirementsFullfilled = false;
        assayRequirementMessage += `<i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;<span> Assay Details not provided.</span> <br>`
      }
    }

    //Validateing the Assay Details selection
    let isExpressionValid = this.selectedExpressionAssays.filter(x => x.AssayCategoryTypeDetails != null && x.SelectedAssayCategoryTypeDetails == null).length == 0 ? true : false
    let isEfficacyValid = this.selectedEfficacyAssays.filter(x => x.AssayCategoryTypeDetails != null && x.SelectedAssayCategoryTypeDetails == null).length == 0 ? true : false

    let isPhenotypeValid = this.selectedPhenotypeAssays.filter(x => x.AssayCategoryTypeDetails != null && x.SelectedAssayCategoryTypeDetails == null).length == 0 ? true : false
    let isSelectionValid = this.selectedSelectionAssays.filter(x => x.AssayCategoryTypeDetails != null && x.SelectedAssayCategoryTypeDetails == null).length == 0 ? true : false

    //Check if Critera comments are provided for assays.
    let isExpressionAssayCriteriaCommentsEntered = this.selectedExpressionAssays.filter(x => x.AssayModel.IsCriteriaForPass == true && x.CriteriaToPass == null).length == 0 ? true : false;
    let isEfficacyAssayCriteriaCommentsEntered = this.selectedEfficacyAssays.filter(x => x.AssayModel.IsCriteriaForPass == true && x.CriteriaToPass == null).length == 0 ? true : false;

    let isPhenotypeAssayCriteriaCommentsEntered = this.selectedPhenotypeAssays.filter(x => x.AssayModel.IsCriteriaForPass == true && x.CriteriaToPass == null).length == 0 ? true : false;
    let isSelectionAssayCriteriaCommentsEntered = this.selectedSelectionAssays.filter(x => x.AssayModel.IsCriteriaForPass == true && x.CriteriaToPass == null).length == 0 ? true : false;

    //Check all mandatory conditions
    if (isEfficacyValid && isExpressionValid && isEfficacyValid && isExpressionValid
      && isExpressionAssayCriteriaCommentsEntered && isEfficacyAssayCriteriaCommentsEntered
      && isEfficacyAssayCriteriaCommentsEntered && hasAssayRequirementsFullfilled
      && isPhenotypeValid && isSelectionValid && isPhenotypeAssayCriteriaCommentsEntered && isSelectionAssayCriteriaCommentsEntered) {

        return true;
    }
    else {
      assayRequirementMessage += `<i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;<span>Mandatory details not provided.</span><br>`;
      this.validationMessage = assayRequirementMessage;
      return false;
    }
  }

  isValidStudy: boolean;

  validationMessage:string;

  verifyAssayDataForCurrentGeneration(){
    let isValid = false;

      if(this.defaultGeneration){
        if(this.isStudyValid()){
          isValid = true;
        }
      }
      else{
        if(this.studyGenerationComments.SelectionCriteria?.length > 0 || this.studyGenerationComments.PollinationInstructions?.length > 0 || this.studyGenerationComments.GeneralComments?.length > 0){
          // check mandatory assay details as well
          if(this.isStudyValid(true)){
            isValid = true;
          }
        }
        else if(this.isStudyValid()){
          isValid = true;
        }
        else{
          this.validationMessage = "Assay Details/Generation Comments not provided";
          isValid = false;
        }
      }

      if(!isValid){
        this.confirmationService.confirm({
          message: this.validationMessage,
          header: 'Validation Failed',
          icon: null,
          acceptLabel: 'Ok',
          rejectVisible: false
        });
      }

      return isValid;
  }
}
