

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class AssayTypeService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private _http:HttpClient) { }

  public getAssayTypes():any{
    let url=ConfigurationSettings.REST_API_URL + "/assaytypes"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Assaytypes")));
    }
}