import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { ConfigurationSettings } from "../.././configuration-settings"
import { EvengerRequestSearchModel } from 'src/app/study/manage-study/evenger-request-mapping/evenger-request-search.model';
//import { queryRefresh } from '@angular/core/src/render3';
import { logging } from 'protractor';

@Injectable()
export class AssaySamplingReportService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }

    getEarlyStageSamplingReport(fromDate: string, toDate: string, generation: string, cropId: number, assayId: number): any {
        let queryString = "fromDate=" + fromDate + "&toDate=" + toDate + "&generation=" + generation + "&cropId=" + cropId + "&assayId=" + assayId;
        let url = ConfigurationSettings.REST_API_URL + "/sampling-reports/early-stage?" + queryString

        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Early Stage Assay Sampling Report")));
    }

    getLateStageSamplingReport(fromDate: string, toDate: string, generation: string, cropId: number, assayId: number): any {
        let queryString = "fromDate=" + fromDate + "&toDate=" + toDate + "&generation=" + generation + "&cropId=" + cropId + "&assayId=" + assayId;
        let url = ConfigurationSettings.REST_API_URL + "/sampling-reports/late-stage?" + queryString;

        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Late Stage Assay Sampling Report")));
    }
}