import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { LoadingService } from 'src/app/loading/res-loading.service';
import { catchError, finalize } from 'rxjs/operators';
import { RouteConstants } from 'src/app/Constants/route-constants';
import { Observable } from 'rxjs';



@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

    activeRequests: number = 0;

    constructor(
        private loadingScreenService: LoadingService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.activeRequests === 0) {
            this.loadingScreenService.setMessage("Loading... Please wait");
        }
        
        if(!request.url.includes(RouteConstants.GET_EVENGER_REQUEST_OF_TRANFORMATION_SENDTOGH)){      
        this.activeRequests++;
       }
        
        return next.handle(request).pipe(
            finalize(() => {
                if(!request.url.includes(RouteConstants.GET_EVENGER_REQUEST_OF_TRANFORMATION_SENDTOGH)){      
                this.activeRequests--;
                }
                if (this.activeRequests === 0) {
                    this.loadingScreenService.clearMessage();
                }
            })
        )
    };
}