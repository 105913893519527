import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AssayTypeConstants } from 'src/app/Constants/assay-type-constants';
import { StatusConstants } from 'src/app/Constants/status-constants';
import { UserFunctionConstants } from 'src/app/Constants/user-function-constants';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { LoadingService } from 'src/app/loading/res-loading.service';
import { AssayService } from 'src/app/services/assay-service/assay.service';
import { StudyGenerationCommentService } from 'src/app/services/generation-comment-service/generation-comment.service';
import { PermissionService } from 'src/app/services/permission-service/permission.service';
import { StudyAssayService } from 'src/app/services/study-service/studyAssay.service';
import { UserModel } from 'src/app/user/user.model';
import { StudyGenerationCommentModel } from '../generation-comment.model';
import { StudyAssaySelectionModel } from '../study-assay-selection/study-assay-selection.model';
import { StudyGeneralInformation } from '../study-generalInformation.model';
import { StudyKeyContactModel } from '../study-keycontact.model';
import { StudyStatusModel } from '../study-status.model';
import { GenerationConstants } from 'src/app/Constants/generation-constants';

@Component({
  selector: 'app-study-preview-tab',
  templateUrl: './study-preview-tab.component.html',
  styleUrls: ['./study-preview-tab.component.scss'],
  providers: [AssayService, MessageService, StudyAssayService, StudyGenerationCommentService, ConfirmationService, PermissionService],
})
export class StudyPreviewTabComponent implements OnInit, OnChanges {

  constructor(private messageService: MessageService,
    private studyAssayService: StudyAssayService,
     private loader: LoadingService, private router: Router,
    private permissionService: PermissionService,
    private studyGenerationCommentService: StudyGenerationCommentService) { }

  ngOnInit() {
    console.log(this.savedStudyId);
    this.loader.setMessage("Please wait...");

  }

  ngOnChanges(){
    if(this.generationName !== undefined){
    this.GetSelectedTxStudyAssaysForGeneration(this.savedStudyId, this.generationName);
      this.getStudyGenerationComment();
    }
  }

  @Input() savedStudyId: number;
  @Input() generationName: string;
  @Input() savedStudyGeneralInfo: StudyGeneralInformation;

  getStudyGenerationComment() {
    this.studyGenerationCommentService.getGenerationComment(this.savedStudyId, this.generationName).subscribe(response => this.studyGenerationCommentResponse = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        this.selectionCriteriaModel = this.studyGenerationCommentResponse.SelectionCriteria;
        this.pollinationInstructionsModel = this.studyGenerationCommentResponse.PollinationInstructions;
        this.generalCommentsModel = this.studyGenerationCommentResponse.GeneralComments;
      })
  }
  displayCommentsLog: boolean = false;

  selectedTxExpressionAssays: StudyAssaySelectionModel[] = [];
  selectedTxEfficacyAssays: StudyAssaySelectionModel[] = [];
  selectedTxPhenotypeAssays: StudyAssaySelectionModel[] = [];
  selectedTxSelectionAssays: StudyAssaySelectionModel[] = [];

  savedStudyAssays: StudyAssaySelectionModel[];
  studyGenerationsData: any;
  UserSearchAPIURL = ConfigurationSettings.USER_SEARCH_API_URL;
  studyGenerationCommentResponse: StudyGenerationCommentModel;
  selectionCriteriaModel: string = '';
  pollinationInstructionsModel: string = '';
  generalCommentsModel: string = '';
  isSubmitted: boolean = false;
  isValidStudy: boolean = true;
  showActionPanel: boolean = true;
  displayDialog: boolean = false;
  dialogHeaderText: string;
  errorMessage: string;


  /**
   * Method to fetch StudyAssays
   * @param studyId
   * @param generationId
   */
  GetSelectedTxStudyAssaysForGeneration(studyId, generation) {
    this.studyAssayService.getStudyAssaysForGeneration(studyId, generation).subscribe(response => this.savedStudyAssays = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
      },
      () => {
        this.selectedTxExpressionAssays = this.savedStudyAssays.filter(x => x.AssayModel.AssayType === AssayTypeConstants.EXPRESSION);
        this.selectedTxEfficacyAssays = this.savedStudyAssays.filter(x => x.AssayModel.AssayType === AssayTypeConstants.EFFICACY);
        this.selectedTxPhenotypeAssays = this.savedStudyAssays.filter(x => x.AssayModel.AssayType === AssayTypeConstants.PHENOTYPE);
        this.selectedTxSelectionAssays = this.savedStudyAssays.filter(x => x.AssayModel.AssayType === AssayTypeConstants.SELECTION);
      })
  }
  validationMessage: string

  /**
* Method to check logged-in user is access to take appropriate action i.e. Review, Need More Info & Cancel
* these button will be displayed only when stusy is submitted for review
*/
showUserActions(functionName: string) {
  if (this.savedStudyGeneralInfo) {
    if (this.permissionService.isUserPermittedToAccess(functionName) && this.savedStudyGeneralInfo.Status == StatusConstants.REVIEWED) {
      return true;
    }
    else {
      return false;
    }
  }
}

public userFunctions: typeof UserFunctionConstants = UserFunctionConstants;
}
