import { Component, Inject, Optional, OnDestroy, OnInit } from '@angular/core';
import { LayoutService, AuthStatus } from '@corteva-research/ngx-components-core';
import { MsalBroadcastService } from "@azure/msal-angular";
import { MsalService } from "@azure/msal-angular";
import { Router } from '@angular/router';
import { LoadingService } from './loading/res-loading.service';
import { ConfigurationSettings } from './configuration-settings';
import { PermissionService } from './services/permission-service/permission.service';
import { RoleConstants } from './Constants/role-constants';
import { SecurityService } from './services/security-service/security.service';
import { Subject, Subscription } from 'rxjs';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { TICAuthService } from './services/auth-service/tic-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ConfigurationSettings, PermissionService, SecurityService]
})
export class AppComponent implements OnInit, OnDestroy {

  public loggedIn: boolean;
  private subscription: Subscription;

  public username: string;
  public showMenu: boolean = false;
  public userModel: any;
  public showMenuOnAuthentication: object;
  showAppName:boolean = false;
  //public MyEnum = RoleConstants;

  constructor(private broadcastService: MsalBroadcastService, private ticAuthService: TICAuthService
    , private router: Router, private loadingService: LoadingService, private permissionService: PermissionService, private securityService: SecurityService) {
  }

  currentEnvironment: string;
  private readonly _destroying$ = new Subject<void>();

  ngOnInit() {
    this.currentEnvironment = ConfigurationSettings.CURRENT_ENVIRONMENT_NAME;
    this.showAppName = this.currentEnvironment == 'PR' ? false : true;
    this.loadingService.setMessage('Loading...');


        this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
      )
      .subscribe((payload) => {
        // console.log("login failure " + JSON.stringify(payload));
        this.loggedIn = false; // To hide the menu bar making this variable to false.

      });

    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((payload) => {
        var payloadAccount = JSON.parse(JSON.stringify(payload.payload));
        sessionStorage["msal.idtoken"] = payloadAccount.accessToken;
        console.log(payloadAccount.accessToken);
        this.ticAuthService.isLoggedIn = true;
        this.loggedIn = true;
        this.username = payloadAccount.account.username;
        if (!(this.username == undefined || this.username == "guest" || this.username == "")) {
          localStorage.setItem("loggedInUsername", this.username);
          console.log(this.username + " from payload");
          this.authorizeUser();
        }
        else {
          console.log("username is either undefined or guest");
          sessionStorage["msal.user.validated"] = "false";
        }
      });

    if (!this.ticAuthService.getUserFromSessionStorage()) {
      this.authorizeUser();
    }

    // This if case is handling page refresh after login success.
    if (sessionStorage.getItem("loggedinUserInfo")) {
      this.loggedIn = true;

      let user = this.ticAuthService.getUserFromSessionStorage();
      if (!this.ticAuthService.isUserActive(user)) {
       this.showMenuOnAuthentication = { 'visibility': 'hidden' };
      }
      else {
       this.showMenuOnAuthentication = { 'visibility': 'visible' };
      }

    }
    this.loadingService.clearMessage();
   }

   ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

   /**
   * Method to validate User through API
   */
  public authorizeUser() {
    if (!(this.username == undefined || this.username == "guest" || this.username == "")) {
    sessionStorage.setItem("loggedInUsername", this.username);

    this.securityService.authorizeUser()
      .subscribe(data => this.userModel = data,
        error => {
          this.showMenu = false;
          this.router.navigate(['./unauthorized']);
        },
        () => {
          if (this.ticAuthService.isUserActive(this.userModel)) {
            this.ticAuthService.setUserToSessionStorage(this.userModel);
            this.showMenu = true;
          }
          else {
            this.showMenu = false;
            this.router.navigate(['/unauthroized']);
          }
        });
          if (this.showMenu == true) {
            this.loggedIn = true;   // To show the menu item  making this variable to true.
            this.showMenuOnAuthentication = { 'visibility': 'visible' }
          }
       }
      }

  public isRouteActive(routeName: string): boolean {
    return this.router.isActive(routeName, false);
  }

  doesUserhaveAccess() {
    return this.permissionService.doesUserHaveRole(RoleConstants.ADMIN);
  }
}

