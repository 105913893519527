

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import{SamplingWeekModel} from '../../Administrator/samplingweek/samplingweek.model';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class SamplingWeekService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private _http:HttpClient) { }

  public createSamplingWeek(samplingweek:SamplingWeekModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/samplingweeks"
    let body= JSON.stringify(samplingweek);

    return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Created new SamplingWeek"))
            );
    }

  public getSamplingWeeks():any{
    let url=ConfigurationSettings.REST_API_URL + "/samplingweeks"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched SamplingWeeks")));
    }

  public updateSamplingWeek(samplingweek:SamplingWeekModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/samplingweeks"
    let body= JSON.stringify(samplingweek);
    return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Updated SamplingWeek"))
            );
    }

  public deleteSamplingWeek(Id:number):any{
    let url=ConfigurationSettings.REST_API_URL + "/samplingweeks/"+Id;
   return this._http.delete(url).pipe(
            tap(x => console.log("Deleted SamplingWeek")));
    }
}