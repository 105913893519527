
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';



import { ConfigurationSettings } from "../.././configuration-settings"
import { StudyGeneralInformation } from 'src/app/study/manage-study/study-generalInformation.model';
import { StudyStatusModel } from 'src/app/study/manage-study/study-status.model';
import { StudyOwnerUpdateModel } from 'src/app/study/manage-study/study-owner-update.model';

@Injectable()
export class StudyService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }

    public saveStudyGeneralInfo(studyGeneralInfo: StudyGeneralInformation): any {
        let url = ConfigurationSettings.REST_API_URL + "/study"
        let body = JSON.stringify(studyGeneralInfo);

        return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Study Saved"))
            );

    }

    public updatetudyGeneralInfo(studyGeneralInfo: StudyGeneralInformation): any {
        let url = ConfigurationSettings.REST_API_URL + "/study"
        let body = JSON.stringify(studyGeneralInfo);

        return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Study Updated"))
            );
    }

    public getStudyList(fromDate: string, toDate: string, isOnlyFavorites: boolean): any {
        let queryString = "fromDate=" + fromDate + "&toDate=" + toDate + "&isOnlyFavourites=" + isOnlyFavorites;
        let url = ConfigurationSettings.REST_API_URL + "/study?" + queryString;
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched StudyList")));
    }


    public getStudyByStudyId(studyId: number): any {
        let url = ConfigurationSettings.REST_API_URL + "/study/" + studyId;
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Study")));
    }


    public createStudyStatus(studyId: number, studyStatusModel: StudyStatusModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/study/" + studyId + "/study-statuses"
        let body = JSON.stringify(studyStatusModel);

        return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Study Status Saved"))
            );
    }

    public getStudyCommentLogs(studyId: number): any {
        let url = ConfigurationSettings.REST_API_URL + "/studycommentlogs/" + studyId;
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Study comments")));
    }

    public updateStudyOwner(studyId: number, studyOwnerUpdateModel: StudyOwnerUpdateModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/study/" + studyId + "/owner";
        let body = JSON.stringify(studyOwnerUpdateModel);

        return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Study Owner Updated"))
            );
    }

    public markStudyAsFavorite(studyId) {
        let url = ConfigurationSettings.REST_API_URL + "/study/" + studyId + "/favorite";

        return this._http.post(url, {}).pipe(
            tap(x => console.log("Marked as Favorite"))
        )
    }

    public unmarkStudyAsFavorite(studyId) {
        let url = ConfigurationSettings.REST_API_URL + "/study/" + studyId + "/unfavorite";

        return this._http.delete(url).pipe(
            tap(x => console.log("UnMarked as Favorite"))
        )
    }

    public getStudyTransformationAndSendToGHStatuses(): any {
        let url = ConfigurationSettings.REST_API_URL + "/evenger-requests/tranformationinitiated-sendtogh";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched StudyStatuses")));
    }

    public GetStudyProject(studyId): any {
        let url = ConfigurationSettings.REST_API_URL + "/study/" + studyId + "/pmoproject";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Study Project from PMO")));
    }

    public GetStudyGenerations(studyId): any {
        let url = ConfigurationSettings.REST_API_URL + "/study/"+ studyId +"/generations";
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Study Generations")));
    }

    public DeleteStudyGeneration(studyId, generation): any {
        let url = ConfigurationSettings.REST_API_URL + "/study/"+ studyId +"/generations/"+ generation;
        return this._http.delete(url).pipe(
            tap(x => console.log("Deleted Study Generation")));
    }

}
