import { OnInit, Component, Input, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { LoadingService } from 'src/app/loading/res-loading.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { StudyService } from 'src/app/services/study-service/study.service';
import { StudyAssayService } from 'src/app/services/study-service/studyAssay.service';
import { StudyGeneralInformation } from '../manage-study/study-generalInformation.model';
import { StudyAssaySelectionModel } from '../manage-study/study-assay-selection/study-assay-selection.model';
import { PermissionService } from 'src/app/services/permission-service/permission.service';
import { UserFunctionConstants } from 'src/app/Constants/user-function-constants';
import { StatusConstants } from 'src/app/Constants/status-constants';
//import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer2';
import { ViewStudyCommentLogModel } from './view-study-comment-log.model';
import { StudyStatusModel } from '../manage-study/study-status.model';
import { StudyPreviewComponent } from '../manage-study/study-preview/study-preview.component';
import { CommentPrefixConstants } from 'src/app/Constants/comment-prefix-constants';

@Component({
    selector: 'app-study',
    templateUrl: './view-study.component.html',
    styleUrls: ['./view-study.component.css'],
    providers: [MessageService, StudyAssayService, StudyService, PermissionService, ConfirmationService]
})

export class ViewStudyComponent implements OnInit {
    constructor(private route: ActivatedRoute, private loader: LoadingService, private messageService: MessageService,
        private studyService: StudyService, private studyAssayService: StudyAssayService, private permissionService: PermissionService,
        private router: Router, private confirmationService: ConfirmationService) { }

    @ViewChild(StudyPreviewComponent,{static:false}) studyPreviewComponent: StudyPreviewComponent;
    @Input() savedStudyId: number

    pageFrom: string
    public userFunctions: typeof UserFunctionConstants = UserFunctionConstants;
    studyStatus: string
    studyCommentLogs: ViewStudyCommentLogModel[];
    displayCommentsDialog: boolean = false;
    dialogHeaderText: any;
    Comments: string;
    commentsValidation: string = "";
    studyGeneralInfo: StudyGeneralInformation;

    ngOnInit() {
        this.pageFrom = "View";
        this.savedStudyId = Number(this.route.snapshot.paramMap.get("studyId"));
        console.log(this.savedStudyId);
        this.GetStudyCommentLogs(this.savedStudyId);
    }

    /**
     * Method to make study as reviewed
     * @param studyId 
     */
    review(studyId) {
        this.createStudyStatus(this.prepareStudyStatus(studyId, StatusConstants.REVIEWED, CommentPrefixConstants.STUDY_REVIEW_COMMENT))
    }

    /**
     * Method to get study general information from study preview page(child)
     * @param params 
     */
    getStudyGeneralInfo(params) {
        this.studyGeneralInfo = params.studyGeneralInfo;
    }

    /**
     * Method to cancel the study
     * @param studyId 
     */
    cancel(studyId) {
        this.commentsValidation = "";
        this.dialogHeaderText = "Cancellation"
        this.displayCommentsDialog = true;
        this.Comments = "";
        //this.createStudyStatus(this.prepareStudyStatus(studyId, StatusConstants.CANCELLED, ""))
    }

    /**
    * Method to make study as need more info and reverted to PI
    * @param studyId 
    */
    needMoreInfo(studyId) {
        //console.log("child - " + this.parent);
        this.commentsValidation = "";
        this.dialogHeaderText = "Need More Info"
        this.displayCommentsDialog = true;
        this.Comments = "";
    }
    /**
     * prepare study status model
     * @param studyId 
     * @param status 
     */
    prepareStudyStatus(studyId, status, comments) {
        let studyStatus = new StudyStatusModel();
        studyStatus.StudyId = studyId,
            studyStatus.Status = status,
            studyStatus.Comments = comments;

        return studyStatus;
    }

    /**
     * create study status 
     * @param studyStatusModel 
     */
    private createStudyStatus(studyStatusModel: StudyStatusModel) {
        this.studyService.createStudyStatus(this.savedStudyId, studyStatusModel).subscribe(res => res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error occured while adding. Contact Administrator' })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Study has been ' + studyStatusModel.Status })
                setTimeout(() => {
                    this.router.navigate(['/study']);
                }, 1000);
            });
    }

    /**
     * Method to check logged-in user is access to take appropriate action i.e. Review, Need More Info & Cancel
     * these button will be displayed only when study is submitted for review
     */
    showUserActionsForSubmitted(functionName: string) {
        //if(this.studyGeneralInfo){
        if (this.permissionService.isUserPermittedToAccess(functionName) && this.studyGeneralInfo.Status == StatusConstants.SUBMITTED_FOR_REVIEW) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Method to check logged-in user has access to take Archive Study
     * these button will be displayed only when study is submitted for review & User has Admin role or Study Owner
     */
    showArchiveForReviewed(functionName: string) {
        //if(this.studyGeneralInfo){
        if ((this.permissionService.isUserPermittedToAccess(functionName) || this.studyGeneralInfo.IsStudyOwner) && this.studyGeneralInfo.Status == StatusConstants.REVIEWED) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Method to fetch commentlogs fpr study
     */
    private GetStudyCommentLogs(studyId) {
        this.studyService.getStudyCommentLogs(studyId).subscribe(response => this.studyCommentLogs = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => { this.studyCommentLogs })
    }

    /**
     * Method to update study status 
     */
    Save() {
        this.commentsValidation = "";
        if (!this.Comments) {
            this.commentsValidation = "Comments Required";
            return
        }
        if (this.Comments) {
            if (this.Comments.length > 220) {
                this.commentsValidation = "Comments Length is exceeded";
                return
            }
        }

        let studyCancelComment = CommentPrefixConstants.STUDY_CANCEL_COMMENT.concat(" - ").concat(this.Comments);
        let studyNeedMoreInfoComment = CommentPrefixConstants.STUDY_NEEDMOREINFO_COMMENT.concat(" - ").concat(this.Comments);

        let status: string = this.dialogHeaderText == "Cancellation" ? StatusConstants.CANCELLED : StatusConstants.NEEDMOREINFO;

        let studyComments = status == StatusConstants.CANCELLED ? studyCancelComment : studyNeedMoreInfoComment;

        this.createStudyStatus(this.prepareStudyStatus(this.savedStudyId, status, studyComments))

    }

    /**
     * close the dialog
     */
    Cancel() {
        this.displayCommentsDialog = false;
        this.commentsValidation = "";
    }

    /**
   * Method to reload study comments upon sucessful deletion of Eveger request
   */
    reloadStudyCommentsOnEvengerRequestDeletion() {
        this.GetStudyCommentLogs(this.savedStudyId);
    }


    /**
 * Method to check logged-in user is access to take appropriate action i.e. Review, Need More Info & Cancel
 * these button will be displayed only when study is reviewed
 */
    showUserActionsForReviewed() {
        if (this.permissionService.isUserPermittedToAccess(this.userFunctions.ASSOCIATE_EVENGER_REQUEST) && this.studyGeneralInfo.Status == StatusConstants.REVIEWED && this.studyGeneralInfo.IsStudyOwner) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Button event after clicking 'Archive Study' to prompt confimation
     */
    ConfirmArchiveStudy() {
        this.confirmationService.confirm({
            message: `Do you wish to archive the study, Once the study is archived it would be in read only mode. Evenger experiments cannot be associated and this study wouldn't be considered for Report generation.<br><br> Click Confirm to Archive the study`,
            header: 'Please Confirm',
            acceptLabel: 'Confirm',
            rejectLabel: 'Cancel',
            accept: () => {
                this.createStudyStatus(this.prepareStudyStatus(this.savedStudyId, StatusConstants.ARCHIVED, ""));
            },
            reject: () => {
            }
        });
    }
}