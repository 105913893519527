import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../services/security-service/security.service';
import { ConfigurationSettings } from '../configuration-settings';
import { LoadingService } from '../loading/res-loading.service';
@Component({
    selector: 'authenticate',
    templateUrl: './authenticate.component.html',
    providers: [SecurityService]
})
export class AuthenticateComponent implements OnInit {
    public username: string;
    constructor(private app: AppComponent, private router: Router, private securityService: SecurityService, private loadingService: LoadingService) { }
    ngOnInit() {
        this.username = this.app.username;
        if (!(this.username == undefined || this.username == "guest" || this.username == "")) {
            this.app.showMenuOnAuthentication = { 'visibility': 'hidden' }
            this.authorizeUser();
        }
        else {
            this.app.showMenuOnAuthentication = { 'visibility': 'hidden' }
            console.log("username is either undefined or guest");
            this.app.showMenu = false;
        }
    }
    public authorizeUser() {
        sessionStorage.setItem("loggedInUsername", this.username);

        this.securityService.authorizeUser()
            .subscribe(data => this.app.userModel = data,
                error => {
                    this.app.showMenu = false;
                    this.app.showMenuOnAuthentication = { 'visibility': 'hidden' }
                    this.router.navigate(['./unauthorized']);
                    //this.loadingService.clearMessage();
                },
                () => {
                    console.log(this.app.userModel);
                    sessionStorage.setItem("loggedInUsername", this.username);
                    sessionStorage.setItem("loggedInUserFunctions", this.app.userModel.Permissions);
                    sessionStorage.setItem("loggedInUserRoles", this.app.userModel.Roles);
                    console.log(sessionStorage["loggedInUserFunctions"]);
                    console.log(sessionStorage["loggedInUserRoles"]);
                    this.app.showMenu = (this.username == undefined || this.username == "guest") ? false : true;
                    if (this.app.showMenu == true) {
                        this.app.loggedIn = true;   // To show the menu item  making this variable to true.
                        this.app.showMenuOnAuthentication = { 'visibility': 'visible' }
                        this.router.navigate(["study"]);
                    }
                });
    }
}