

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { ConfigurationSettings } from "../.././configuration-settings"
import { ProjectTacticTeamModel } from 'src/app/Administrator/projecttacticteam/projecttacticteam.model';

@Injectable()
export class ProjectTacticTeamService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' })
  };

  constructor(private _http: HttpClient) { }

  public createProjectTacticTeam(projecttacticteam: ProjectTacticTeamModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/projecttacticteams"
    let body = JSON.stringify(projecttacticteam);

    return this._http.post(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Created new ProjectTacticTeam"))
      );
  }

  public getProjectTacticTeams(): any {
    let url = ConfigurationSettings.REST_API_URL + "/projecttacticteams"
    return this._http.get(url).pipe(
      tap(x => console.log("Fetched ProjectTacticTeams")));
  }

  public updateProjectTacticTeam(projecttacticteam: ProjectTacticTeamModel): any {
    let url = ConfigurationSettings.REST_API_URL + "/projecttacticteams"
    let body = JSON.stringify(projecttacticteam);
    return this._http.put(
      url,
      body, this.httpOptions).pipe(
        tap(x => console.log("Updated ProjectTacticTeam"))
      );
  }

  public deleteProjectTacticTeam(Id: number): any {
    let url = ConfigurationSettings.REST_API_URL + "/projecttacticteams/" + Id;
    return this._http.delete(url).pipe(
      tap(x => console.log("Deleted ProjectTacticTeam")));
  }
}