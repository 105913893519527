import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(public router: Router) { }
    canActivate(route: ActivatedRouteSnapshot): boolean {

        // this will be passed from the route config
        // on the data property
        const expectedRole = route.data.expectedRole;

        if (!this.doesUserHaveRole(expectedRole)) {
            this.router.navigate(['unauthorized']);
            return false;
        }
        return true;
    }

    /**
       * Mehod to check given role name is available in logged-in user roles
       * @param roleName 
       */
    public doesUserHaveRole(roleName: string): boolean {
        let isUserHaveRole = false;
        if (sessionStorage["loggedInUserRoles"]) {
            if (sessionStorage["loggedInUserRoles"].indexOf(roleName) != -1) {
                isUserHaveRole = true;
            }
            return isUserHaveRole;
        }
    }
}