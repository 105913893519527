import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResearchComponentsMsalIntegrationModule } from '@corteva-research/ngx-components-msal-integration';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html'
})
export class LoginComponent implements OnInit {

  private data: Object;
  private bearerToken: string;
  private headers: HttpHeaders;

  constructor(private router: Router, private auth: MsalService, private http: HttpClient) {
  }

  public login(): void {
    this.auth.loginPopup();
  }

  /**
  *  Reads hashed values in the url passed from the IDP.
  *  If successfully reads token, redirects to afterLoginRoute defined in ResearchAuthService
  */
  ngOnInit() {

  }
}
