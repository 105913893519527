import { Injectable } from "@angular/core";
/**
 * Service to check logged-in user permissions/Roles
 */
@Injectable()
export class PermissionService {

    /**
     * Method to check logged-in user is permitted to access given function(permission) name
     * @param functionName 
     */
    public isUserPermittedToAccess(functionName: string): boolean {
        let isUserPerimitted = false;
        if (sessionStorage["loggedInUserFunctions"]) {
            if (sessionStorage["loggedInUserFunctions"].indexOf(functionName) != -1) {
                isUserPerimitted = true;
            }
            return isUserPerimitted;
        }
    }

    /**
     * Mehod to check given role name is available in logged-in user roles
     * @param roleName 
     */
    public doesUserHaveRole(roleName: string): boolean {
        let isUserHaveRole = false;
        if (sessionStorage["loggedInUserRoles"]) {
            if (sessionStorage["loggedInUserRoles"].indexOf(roleName) != -1) {
                isUserHaveRole = true;
            }
            return isUserHaveRole;
        }
    }
}