

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import{GeneVariantModel} from '../../Administrator/genevariant/genevariant.model';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class GeneVariantService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private _http:HttpClient) { }

  public createGeneVariant(genevariant:GeneVariantModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/genevariants"
    let body= JSON.stringify(genevariant);

    return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Created new GeneVariant"))
            );
    }

  public getGeneVariants():any{
    let url=ConfigurationSettings.REST_API_URL + "/genevariants"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched GeneVariants")));
    }

  public updateGeneVariant(genevariant:GeneVariantModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/genevariants"
    let body= JSON.stringify(genevariant);
    return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Updated GeneVariant"))
            );
    }

  public deleteGeneVariant(Id:number):any{
    let url=ConfigurationSettings.REST_API_URL + "/genevariants/"+Id;
   return this._http.delete(url).pipe(
            tap(x => console.log("Deleted GeneVariant")));
    }
}