

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { ConfigurationSettings } from "../.././configuration-settings"
import { EvengerRequestSearchModel } from 'src/app/study/manage-study/evenger-request-mapping/evenger-request-search.model';
//import { queryRefresh } from '@angular/core/src/render3';
import { StudyEvengerRequestModel } from 'src/app/study/manage-study/evenger-request-mapping/study-evenger-request.model';
import { StudyCommentCreateModel } from 'src/app/study/manage-study/evenger-request-mapping/study-comment-create.model';

@Injectable()
export class EvengerRequestMappingService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }

    public createStudyEvengerRequestAssociation(selectedEvenegerRequests: StudyEvengerRequestModel[]): any {
        let url = ConfigurationSettings.REST_API_URL + "/studyevengerrequests"
        let body = JSON.stringify(selectedEvenegerRequests);

        return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Associated Evenger Requests"))
            );
    }

    public EditStudyEvengerRequestAssociation(selectedEvenegerRequest: StudyEvengerRequestModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/studyevengerrequests"
        let body = JSON.stringify(selectedEvenegerRequest);

        return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Saved StudyEvenger request"))
            );
    }



    public getAssociatedStudyEvengerRequests(studyId: number): any {
        let url = ConfigurationSettings.REST_API_URL + "/studyevengerrequests/" + studyId;
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Evenger Requests By Request Name")));
    }

    getEvengerRequestsByRequestName(requestName: string): any {
        let url = ConfigurationSettings.REST_API_URL + "/evenger-requests/" + requestName;
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Evenger Requests By Request Name")));

    }

    getEvengerRequestsByRequestorName(requestorUserName: string, fromDate: string, toDate: string): any {
        let querySting: string = "?";
        if (requestorUserName != null) {
            querySting += "requestor=" + requestorUserName;
        }
        if (fromDate != null && toDate != null) {
            querySting += "&fromDate=" + fromDate + "&toDate=" + toDate;
        }
        let url = ConfigurationSettings.REST_API_URL + "/evenger-requests" + querySting;
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Evenger Requests By Requestor Name")));

    }


    public deleteAssociatedEvengerRequest(studyEvengerRequestId: number, studyEvengerRequest: StudyCommentCreateModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/studyevengerrequests/" + studyEvengerRequestId + "/delete"
        let body = JSON.stringify(studyEvengerRequest);

        return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Deleted Associated Evenger Requests"))
            );
    }
}