export class RoleModel{
    RoleId? :number
    Name? :string
    Description? :string
    IsActive? :boolean
    CreatedDate? :Date
    CreatedBy? :number
    ModifiedDate? :Date
    ModifiedBy? :number
}
