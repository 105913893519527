import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { ResearchComponentsMsalIntegrationModule } from '@corteva-research/ngx-components-msal-integration';
import { MsalModule, MsalInterceptor, MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent } from '@azure/msal-angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { LoadingComponent } from './loading/loading.component';
import { UnauthorizedComponent } from './login/unauthorized.component';
import { LoadingService } from './loading/res-loading.service';
import { httpSetHeaders } from './services/interceptor-service/httpSetHeaders.interceptor';

import { UserComponent } from './user/user.component';

import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RoleComponent } from './role/role.component';
import { ConfigurationSettings } from './configuration-settings';
import { ProjectTacticTeamComponent } from './Administrator/projecttacticteam/projecttacticteam.component';
import { DataViewModule } from 'primeng/dataview';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { GeneSetComponent } from './Administrator/geneset/geneset.component';
import { GeneVariantComponent } from './Administrator/genevariant/genevariant.component';
import { AssayDetailComponent } from './Administrator/assay-detail/assay-detail.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { StepsModule } from 'primeng/steps';
import { CheckboxModule } from 'primeng/checkbox';
import { AccordionModule } from 'primeng/accordion';
import { AssayComponent } from './Administrator/assay/assay.component';
import { FieldsetModule } from 'primeng/fieldset';
import { SamplingWeekComponent } from './Administrator/samplingweek/samplingweek.component';
import { AuthenticateComponent } from './login/authenticate.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { StudyComponent } from './study/manage-study/study.component';
import { StudyAssaySelectionComponent } from './study/manage-study/study-assay-selection/study-assay-selection.component';
import { StudyListComponent } from './study/study-list/study-list.component';
import { StudyPreviewComponent } from './study/manage-study/study-preview/study-preview.component';
import { StudyGeneralInformationComponent } from './study/manage-study/study-generalInformation/study-generalInformation.component';
import { UserSearchAutocompleteComponent } from 'sein-user-search'
import { ViewStudyComponent } from './study/view-study/view-study.component';
import { UserRoleComponent } from './Administrator/user-role/user-role.component';
import { LoadingInterceptor } from './services/interceptor-service/loader.interceptor';
import { RoleGuardService } from './services/security-service/role-gaurd-service';
import { AssaySamplingReportComponent } from './reports/assay-sampling-report/assay-sampling-report.component';
import { EvengerRequestMappingComponent } from './study/manage-study/evenger-request-mapping/evenger-request-mapping.component';
import { TooltipModule } from 'primeng/tooltip';
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { TabViewModule } from 'primeng/tabview';
import { AssaySelectionComponent } from './study/manage-study/assay-selection/assay-selection.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { StudyPreviewTabComponent } from './study/manage-study/study-preview-tabs/study-preview-tab.component';
import { WhitelistedCharacterDirective } from './directive/character-directive.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    LoadingComponent,
    UnauthorizedComponent,
    AuthenticateComponent,
    UserComponent,
    RoleComponent,
    UserRoleComponent,
    ProjectTacticTeamComponent,
    GeneSetComponent,
    GeneVariantComponent,
    AssayDetailComponent,
    StudyComponent,
    AssayComponent,
    SamplingWeekComponent,
    StudyAssaySelectionComponent,
    StudyListComponent,
    StudyPreviewComponent,
    StudyGeneralInformationComponent,
    UserSearchAutocompleteComponent,
    ViewStudyComponent,
    StudyAssaySelectionComponent,
    AssaySamplingReportComponent,
    EvengerRequestMappingComponent,
    AssayDetailComponent,
    AssaySelectionComponent,
    StudyPreviewTabComponent,
    WhitelistedCharacterDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatStepperModule, MatInputModule, MatCardModule,
    FormsModule, ReactiveFormsModule,
    // ResComponentsCoreModule.forRoot(),
    // ResComponentsMsalIntegrationModule,
    ResearchComponentsCoreModule.forRoot(ConfigurationSettings.APP_REGISTRATION_CLIENTID),
    ResearchComponentsMsalIntegrationModule,
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
        auth: {
          clientId: ConfigurationSettings.APP_REGISTRATION_CLIENTID,
          authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4",
          redirectUri: ConfigurationSettings.APP_UI_URL,
          // validateAuthority : true,
          // cacheLocation : "localStorage",
          postLogoutRedirectUri: ConfigurationSettings.APP_UI_URL + "/login",
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: true, // set to true for IE 11
      },
    }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: [ConfigurationSettings.READ_SCOPE]
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v2.0/me', ['user.read']],
          [ConfigurationSettings.REST_API_URL, [ConfigurationSettings.READ_SCOPE]]
        ])
      }),
    TableModule,
    ButtonModule,
    InputTextModule,
    DialogModule,
    DropdownModule,
    CalendarModule,
    ToastModule,
    MessageModule,
    RadioButtonModule,
    DataViewModule,
    ConfirmDialogModule,
    PanelModule,
    ReactiveFormsModule,
    ButtonModule,
    MultiSelectModule,
    StepsModule,
    MessageModule,
    CheckboxModule,
    FieldsetModule,
    AutoCompleteModule,
    AccordionModule,
    TooltipModule,
    SelectButtonModule,
    HttpClientModule,
    TabViewModule,
    InputTextareaModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }, MsalService, MsalGuard, MsalBroadcastService,  LoadingService, ConfigurationSettings, RoleGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: httpSetHeaders, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }],
  bootstrap: [AppComponent, MsalRedirectComponent],
  //entryComponents: [TabComponent]
})

export class AppModule { }
