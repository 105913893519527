
/**
 * enums for StudyConstants
 */
export enum StudyConstants {
    STUDY_NAME_CONSTANT = "Study Name",
    STUDY_GENERALINFO_CONSTANT = "StudyGeneralInformation",
    ASSAY_SELECTION_CONSTANT = "AssaySelection",
    STUDY_PREVIEW_CONSTANT = "StudyPreviewInformation",
    STUDY_MAX_GENRATIONS_FOR_ARABIDOPSIS = 5,
    STUDY_MAX_GENRATIONS_FOR_OTHERS = 6
}