/**
 * enums for prefix for comment
 */
export enum CommentPrefixConstants {
  ADD_GENERATION_COMMENT = "Gen Added",
  DELETE_GENERATION_COMMENT = "Gen Deleted",
  ASSAY_UPDATED_COMMENT = "Gen Assay Updated",
  ASSAY_DELETED_COMMENT = "Gen Assay Deleted",
  ASSAY_DETAILS_UPDATED_COMMENT = "Gen Assay Details Updated",
  STUDY_REVIEW_COMMENT = "Status Updated to Reviewed",
  STUDY_CANCEL_COMMENT = "Status Updated to Cancelled",
  STUDY_NEEDMOREINFO_COMMENT = "Status updated to Need More Info",
  HYPHEN_COMMENT = " - "
}
