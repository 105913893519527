import { Component, OnInit } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { AssayModel } from './assay.model';
import { AssayService } from 'src/app/services/assay-service/assay.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, RequiredValidator } from '@angular/forms';
import { AssayDetailService } from 'src/app/services/assay-detail-service/assay-detail.service';
import { AssayCategoryTypeModel } from '../assay-detail/assay-category-type.model';
import { SearchableDropdownModel } from 'src/app/services/UtilityService/searchableDropdownModel';
import { SearchableDropdownUtility } from 'src/app/services/UtilityService/searchableDropdownUtility';
import { CropService } from 'src/app/services/crop-service/crop.service';
import { CropModel } from '../crop/crop.model';
import { SamplingWeekService } from 'src/app/services/samplingweek-service/samplingweek.service';
import { SamplingWeekModel } from '../samplingweek/samplingweek.model';
import { AssayTypeService } from 'src/app/services/assayType-service/assayType.service';
import { AssayTypeModel } from '../assayType/assayType.model';
import { UserFunctionConstants } from 'src/app/Constants/user-function-constants';
import { PermissionService } from 'src/app/services/permission-service/permission.service';

@Component({
    selector: 'app-assay',
    templateUrl: './assay.component.html',
    styleUrls: ['./assay.component.css'],
    providers: [AssayService, ConfirmationService, MessageService, AssayDetailService, CropService, SamplingWeekService, AssayTypeService, PermissionService]
})

export class AssayComponent implements OnInit {

    constructor(private fb: UntypedFormBuilder, private assayService: AssayService, private confirmationService: ConfirmationService, private messageService: MessageService, private assayCategoryService: AssayDetailService,
        private cropService: CropService, private samplingweekService: SamplingWeekService,
        private assayTypeService: AssayTypeService, private permissionService: PermissionService) { }
    ngOnInit() {
        this.GetAssays();
        this.assayTableHeaders = [
            { field: 'Name', header: 'Assay Name', display: 'table-cell' },
            { field: 'Crop', header: 'Crop', display: 'table-cell' },
            { field: 'SamplingWeek', header: 'Sampling Logic', display: 'table-cell' },
            { field: 'AssayType', header: 'Assay Type', display: 'table-cell' },
            { field: 'UserFullName', header: 'Created By', display: 'table-cell' }
        ]
        this.initializeAddAssayForm();
        this.GetAssayCategoryTypes();
        this.GetCrops();
        this.GetSamplingWeeks();
        this.GetAssayTypes();
    }

    assayTableHeaders: any[]

    assays: AssayModel[];
    assay = new AssayModel();
    selectedAssay: AssayModel;
    serviceResponse: any;
    isEditable: boolean = false
    dialogHeaderText: any = "Add";

    //Property to display dialog
    displayDialog: boolean = false;


    sortOptions: SelectItem[];
    sortKey: string;
    sortField: string;
    sortOrder: number;

    newAssayCreation: UntypedFormGroup;

    defaultStatus: string;
    submitted = false;
    private initializeAddAssayForm() {
        this.newAssayCreation = this.fb.group({
            assayId: ['0'],
            name: ['', [Validators.required, Validators.maxLength(30)]],
            isActive: ['Active', []],
            cropId: ['', [Validators.required]],
            assayTypeId: ['', Validators.required],
            assayCategoryTypeId: ['', Validators.required],
            isLateStage: [false, Validators.required],
            samplingWeekId: ['', [Validators.required]],
            isCriteriaForPass: [false]
        });
    }

    get createNewAssayControlsObj() { return this.newAssayCreation.controls; }

    newAssay: AssayModel;
    Save() {
        this.submitted = true;
        if (this.newAssayCreation.invalid) {
            return;
        }
        else {
            this.newAssay = this.newAssayCreation.value;
            this.InsertAssay(this.newAssay);
            this.newAssay = {};
            this.ResetForm();
            this.displayDialog = false;
        }
    }

    //Method to update Sampling Week field validation based on Assay Stage
    //Required - If Early Stage
    //Not Required - If Late Stage
    updateValidationForSamplingWeek(value) {
        const sampleWeekControl = this.newAssayCreation.get('samplingWeekId');
        if (value) {
            sampleWeekControl.clearValidators();
            sampleWeekControl.updateValueAndValidity();
        }
        else {
            sampleWeekControl.setValidators([Validators.required]);
            sampleWeekControl.updateValueAndValidity();
        }
    }

    //------------------------------
    //Method to display dialog
    //-----------------------------
    AddnewItem() {
        this.isDisabled = false;
        this.isEditable = false;
        this.assay = {};
        this.ResetForm();
        this.displayDialog = true;

        this.dialogHeaderText = "Add";
    }

    isDisabled: boolean = false;
    //--------------------------------------------------------
    //To open pop up dialog box to edit selected row.
    //-------------------------------------------------------
    Edit(data) {
        this.isEditable = true;
        this.filterSamplingWeeksByCrop(data.CropId, false);
        this.BindDataToFormGroup(data);
        this.newAssayCreation.get('name')['disable']();
        this.displayDialog = true;
        this.isDisabled = true;
        this.dialogHeaderText = "Edit";
    }

    //Method to bind values to controls in form group
    private BindDataToFormGroup(data: any) {
        this.newAssayCreation.get("assayId").setValue(data.AssayId);
        this.newAssayCreation.get("name").setValue(data.Name);
        this.newAssayCreation.get("isActive").setValue(data.IsActive);
        this.newAssayCreation.get("cropId").setValue(data.CropId);
        this.newAssayCreation.get("assayTypeId").setValue(data.AssayTypeId);
        this.newAssayCreation.get("assayCategoryTypeId").setValue(data.AssayCategoryTypeId);
        this.newAssayCreation.get("isLateStage").setValue(data.IsLateStage == false ? 'false' : 'true');
        this.newAssayCreation.get("samplingWeekId").setValue(data.SamplingWeekId);
        this.newAssayCreation.get("isCriteriaForPass").setValue(data.IsCriteriaForPass);
    }


    //------------------------
    //To update one row.
    //------------------------
    Update() {
        this.submitted = true;

        this.newAssay = this.newAssayCreation.value;
        this.newAssay.Name = this.newAssayCreation.getRawValue().name;
        if (this.newAssayCreation.invalid) {
            return;
        }
        else {
            this.UpdateAssay(this.newAssay);
            this.newAssay = {};
            this.ResetForm();
            this.displayDialog = false;
        }
    }

    //-----------------------------------
    //Method to cancel/close dialog
    //-----------------------------------
    Cancel() {
        this.ResetForm();
        this.displayDialog = false;
        this.assay = {};
        this.newAssayCreation.reset();
    }

    //Method to Rest Form
    ResetForm() {
        this.submitted = false;
        this.newAssayCreation.reset();
        this.newAssayCreation.patchValue({ assayId: '0', isLateStage: 'false', isActive: 'Active' });
        this.newAssayCreation.get('name')['enable']();
        this.newAssayCreation.clearAsyncValidators();
    }


    //-----------------------------------------------
    // Insert one row in Assay data table
    //----------------------------------------------
    private InsertAssay(assay) {
        this.assayService.createAssay(assay).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    //-----------------------------------------------------------------------------
    // Get list of Assay from Assays data table
    //-----------------------------------------------------------------------------
    private GetAssays() {
        this.assayService.getAssays().subscribe(response => this.assays = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => { })
    }

    //----------------------------------------------
    // Update selected row and refresh the grid
    //----------------------------------------------
    private UpdateAssay(assay) {
        this.assayService.updateAssay(assay).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while updating. Contact Administrator' })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    assayCategoryTypes: AssayCategoryTypeModel[];
    assayCategoryTypeDropdowns: SearchableDropdownModel[];

    //-----------------------------------------------------------------------------
    // Get list of Assay from Assays data table
    //-----------------------------------------------------------------------------
    private GetAssayCategoryTypes() {
        this.assayCategoryService.getAssayCategoryTypes().subscribe(response => this.assayCategoryTypes = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.assayCategoryTypeDropdowns = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.assayCategoryTypes, "Name", "AssayCategoryTypeId");
            })
    }

    cropList: SearchableDropdownModel[];
    crops: CropModel[];
    private GetCrops() {
        this.cropService.getCrops().subscribe(response => this.crops = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.cropList = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.crops, "Name", "CropId");
            })
    }


    samplingweeks: SamplingWeekModel[];
    samplingWeeksList: SearchableDropdownModel[];

    //-----------------------------------------------------------------------------
    // Get list of SamplingWeek from SamplingWeeks data table
    //-----------------------------------------------------------------------------
    private GetSamplingWeeks() {
        this.samplingweekService.getSamplingWeeks().subscribe(response => this.samplingweeks = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => { })
    }

    //Crop dropdown change event.
    onCropDropdownChange(selectedcrop) {
        this.filterSamplingWeeksByCrop(selectedcrop.value, true);
    }

    /**
     * Method to filter sampling weeks by crop and status
     * @param cropId Crop to filter
     * @param onlyActive Boolean value, if True filters only Active values
     */
    filterSamplingWeeksByCrop(cropId, onlyActive: boolean) {
        console.log(this.samplingweeks);

        let filteredSamplingWeeks = this.samplingweeks.filter(x => x.CropId == cropId);
        if (onlyActive) {
            filteredSamplingWeeks = filteredSamplingWeeks.filter(x => x.IsActive == "Active");
        }
        this.samplingWeeksList = new SearchableDropdownUtility().ConvertToFilterDropdownModel(filteredSamplingWeeks, "Name", "SamplingWeekId");
    }

    assayTypes: AssayTypeModel[];
    assayTypesList: SearchableDropdownModel[];
    //-----------------------------------------------------------------------------
    // Get list of AssayTypes from AssayType data table
    //-----------------------------------------------------------------------------
    private GetAssayTypes() {
        this.assayTypeService.getAssayTypes().subscribe(response => this.assayTypes = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.assayTypesList = new SearchableDropdownUtility().ConvertToFilterDropdownModel(this.assayTypes, "Name", "AssayTypeId");
            })
    }
    /**
 * Method to check logged-in user is access to create study
 */
    isAdmin() {
        return this.permissionService.isUserPermittedToAccess(UserFunctionConstants.MANAGE_ADMINISTRATION);
    }

    //filter assay category type based on assay type
  getAssayCategoryBasedOnAssayType(assayTypeId: any) {
    let assayCategoryTypeList = this.assayCategoryTypes.filter(x => x.AssayTypeId == assayTypeId);
    this.assayCategoryTypeDropdowns = new SearchableDropdownUtility().ConvertToFilterDropdownModel(assayCategoryTypeList, "Name", "AssayCategoryTypeId");
  }
}
