export class AssayModel {
    AssayId?: number
    AssayType?: string
    AssayCategoryTypeId?: number
    AssayCategoryType?: string
    Name?: string
    CropId?: number
    Crop?: string
    SamplingWeekId?: number
    IsCriteriaForPass?: boolean
    IsActive?: string
    IsLateStage?: boolean
    CreatedDate?: Date
    CreatedBy?: number
    ModifiedDate?: Date
    ModifiedBy?: number
    UserFullName?: string
}
