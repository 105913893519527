import { UserModel } from 'src/app/user/user.model';

export class UserRoleModel {
    User: UserModel
    RoleIds: number[]
}

export class ViewUserRoleModel {
    UserRole:UserRoleModel
    Name: string
    IsActive: boolean
    IsPI: boolean;
    IsUser: boolean;
    IsAdmin: boolean;
    IsManager: boolean
}