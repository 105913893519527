
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../../loading/res-loading.service';
import { Router } from '@angular/router';
import { StudyListModel } from './study-list.model';
import { StudyService } from '../../services/study-service/study.service'
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { PermissionService } from 'src/app/services/permission-service/permission.service';
import { UserFunctionConstants } from 'src/app/Constants/user-function-constants';
import { StatusConstants } from 'src/app/Constants/status-constants';
import { RoleConstants } from 'src/app/Constants/role-constants';
import { FilterService } from 'src/app/services/filter-service/filter.service';
import { StudyEvengerRequestStatusModel } from './study-evenger-request-status-model';
import { CropNameConstants } from 'src/app/Constants/crop-name-constants';
import { SecurityService } from 'src/app/services/security-service/security.service';

@Component({
    selector: 'app-study',
    templateUrl: './study-list.component.html',
    styleUrls: ['./study-list.component.scss'],
    providers: [StudyService, MessageService, PermissionService, FilterService, SecurityService]
})

export class StudyListComponent implements OnInit {

    constructor(private studyService: StudyService, private messageService: MessageService,
        private router: Router, private permissionService: PermissionService, private filterService: FilterService,
        private securityService: SecurityService) { }


    ngOnInit() {
      let fromDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
      let toDate = new Date();
      this.GetStudyList(fromDate.toDateString(), toDate.toDateString(), false, true);
      if(sessionStorage["loggedInUserRoles"] == undefined){
      this.getUserRoleAndPermission();
      }else{
        this.showStudyCreate();
        this.setStudyListFilterOPtions();
      }
    }

    getUserRoleAndPermission(){
    this.securityService.authorizeUser()
      .subscribe(data => this.userModel = data,
        error => {
          this.router.navigate(['./unauthorized']);
        },
        () => {
          this.getUserCanCreateStudy(this.userModel);
          this.setStudyListFilterOPtions();
        });
    }

    retireveSearchFilterValue() {
      const currStudyListFilters = sessionStorage.getItem('STUDY_FILTER_SELECTION');
      if (currStudyListFilters !== null && currStudyListFilters !== '{}' && currStudyListFilters !== undefined) {
        const studyFilterList = JSON.parse(currStudyListFilters);
        this.searchFilterSessionList = studyFilterList;
        //studiesToDisplay
        let showStudyValue = this.searchFilterSessionList.find(x => x.fieldName == 'selectedShowStudy')?.value;
        if (showStudyValue !== null && showStudyValue !== undefined) {
          this.studiesToDisplay = showStudyValue;
          this.applyDateRangeFilter(this.selectedDateRange, this.studiesToDisplay, false);
        }
        //drop down
        let dateRangeValue = this.searchFilterSessionList.find(x => x.fieldName == 'selectedDateRange')?.value;
        if (dateRangeValue !== null && dateRangeValue !== undefined) {
          this.selectedDateRange = dateRangeValue;
          this.applyDateRangeFilter(this.selectedDateRange, this.studiesToDisplay, false);
        }
        let cropNameOptionValue = this.searchFilterSessionList.find(x => x.fieldName == 'selectedCropNameOption')?.value;
        if (cropNameOptionValue !== null && cropNameOptionValue !== undefined) {
          this.selectedCropNameOption = cropNameOptionValue;
          this.studyList = this.filterService.filter('CropName', cropNameOptionValue, this.filterColumns, this.dataList, false);
        }
        let statusOptionValue = this.searchFilterSessionList.find(x => x.fieldName == 'selectedStatusOption')?.value;
        if (statusOptionValue !== null && statusOptionValue !== undefined) {
          this.selectedStatusOption = statusOptionValue;
          this.studyList = this.filterService.filter('Status', statusOptionValue, this.filterColumns, this.dataList, false);
        }
        //input field
        let pmoProjectOptionValue = this.searchFilterSessionList.find(x => x.fieldName == 'selectedPMOProjectOption')?.value;
        if (pmoProjectOptionValue !== null && pmoProjectOptionValue !== undefined) {
          this.selectedPMOProjectOption = pmoProjectOptionValue;
          this.studyList = this.filterService.filter('PMOProject', pmoProjectOptionValue, this.filterColumns, this.dataList, false);
        }
        let studyOptionValue = this.searchFilterSessionList.find(x => x.fieldName == 'selectedStudyIdOption')?.value;
        if (studyOptionValue !== null && studyOptionValue !== undefined) {
          this.selectedStudyIdOption = studyOptionValue;
          this.studyList = this.filterService.filter('StudyCode', studyOptionValue, this.filterColumns, this.dataList, false);
        }
        let geneSetOptionValue = this.searchFilterSessionList.find(x => x.fieldName == 'selectedGeneSetOption')?.value;
        if (geneSetOptionValue !== null && geneSetOptionValue !== undefined) {
          this.selectedGeneSetOption = geneSetOptionValue;
          this.studyList = this.filterService.filter('GeneSets', geneSetOptionValue, this.filterColumns, this.dataList, false);
        }
      }
    }

    userModel: any;
    canUserCreateStudy: boolean;
    searchFilterSessionList: any[] = [];
    studies: StudyListModel[];
    StudyStatuses: StudyEvengerRequestStatusModel[];
    studyList: StudyListModel[];
    serviceResponse: any;
    doesStudyRequestStatusesAreLoaded: boolean = false;
    public statusConstants: typeof StatusConstants = StatusConstants;
    public userFunctions: typeof UserFunctionConstants = UserFunctionConstants;

    statusFilterOptions: any[] = [
        { label: 'All', value: '' },
        { label: StatusConstants.SUBMITTED_FOR_REVIEW, value: StatusConstants.SUBMITTED_FOR_REVIEW },
        { label: StatusConstants.REVIEWED, value: StatusConstants.REVIEWED },
        { label: StatusConstants.NEEDMOREINFO, value: StatusConstants.NEEDMOREINFO },
        { label: StatusConstants.CANCELLED, value: StatusConstants.CANCELLED },
        { label: StatusConstants.ARCHIVED, value: StatusConstants.ARCHIVED }
    ];

    cropNameFilterOptions: any[] = [
      { label: 'All', value: '' },
      { label: CropNameConstants.ARABIDOPSIS, value: CropNameConstants.ARABIDOPSIS },
      { label: CropNameConstants.CORN, value: CropNameConstants.CORN },
      { label: CropNameConstants.COTTON, value: CropNameConstants.COTTON },
      { label: CropNameConstants.SOYBEAN, value: CropNameConstants.SOYBEAN }
    ];

    dateRangeFilterOptions: any[];

    sortKey: string;
    sortField: string;
    sortOrder: number;
    studiesToDisplay: string = "All"


    setSearchFilters(filterFieldName: string, studyFilterFormValue: any) {
      let searchFieldFormObj: any = {
        fieldName: filterFieldName,
        value: studyFilterFormValue
      }
      let index = this.searchFilterSessionList.findIndex(x => x.fieldName == filterFieldName);
      if (index == -1 && studyFilterFormValue != null) {
        this.searchFilterSessionList.push(searchFieldFormObj)
      } else if (index != -1) {
        this.searchFilterSessionList[index].value = studyFilterFormValue;
      }

      sessionStorage.setItem('STUDY_FILTER_SELECTION', JSON.stringify(this.searchFilterSessionList));
    }

    /**
     * Method Set Filter dropdown based on role
     */
    private setStudyListFilterOPtions() {

        //Insert DRAFT status if user is PI
        if (this.canUserCreateStudy) {
            this.statusFilterOptions.splice(1, 0, { label: StatusConstants.DRAFT, value: StatusConstants.DRAFT });
        }

        this.dateRangeFilterOptions = [
            { label: 'One Year', value: 1 },
            { label: 'Two Years', value: 2 },
            { label: 'Three Years', value: 3 },
            { label: 'Five Years', value: 5 },
            { label: 'All', value: 'ALL' }
        ]
    }

    /**
     * Method to check logged-in user is access to create study
     */
    showStudyCreate() {
      this.canUserCreateStudy = this.permissionService.isUserPermittedToAccess(UserFunctionConstants.CREATE_STUDY)
      return this.canUserCreateStudy;
    }

  getUserCanCreateStudy(userModel: any) {
    if (userModel.Permissions.indexOf(UserFunctionConstants.CREATE_STUDY) != -1) {
      this.canUserCreateStudy = true

    }
    else{
      this.canUserCreateStudy = false;
    }
    return this.canUserCreateStudy;
  }

    //----------------------------------
    //Method to navigate to create study
    //----------------------------------
    AddnewItem() {
        this.router.navigateByUrl('/createstudy');
    }

    //-----------------------------------------------------------------------------
    // Get list of Study from StudyList data table
    //-----------------------------------------------------------------------------
    private GetStudyList(fromDate: string, toDate: string, isOnlyFavourites: boolean, isfilter: boolean = false)
    {
      this.studyService.getStudyList(fromDate, toDate, isOnlyFavourites).subscribe(response => this.studies = response,
        error => {
          this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
        },
        () => {
          this.getStudyTransformationAndSendToGHStatuses();
          this.studyList = this.studies;
          this.dataList = this.studies;
          if (isfilter) {
            this.retireveSearchFilterValue();
          }
          this.filter('', '', this.filterColumns, this.dataList, false);
        })
    }

    /**
     * show edit study when PI (who created study) clicks on study card
     * show view study when TIC admin clicks on study card
     * @param study
     */
    OpenStudy(study, functionName) {
        if ((study.Status == StatusConstants.DRAFT || study.Status == StatusConstants.NEEDMOREINFO) &&
            this.permissionService.isUserPermittedToAccess(functionName) && study.IsStudyOwner) {
            // show edit study page
            this.router.navigate(['/createstudy/' + study.StudyId]);
        } else {
            // show study view page
            this.router.navigate(['/viewstudy/' + study.StudyId]);
        }
    }

    onStudyFilterChange(filterChangeEvent) {
        if (filterChangeEvent.value == 'All') {
            this.studyList = this.studies;
        }
        else {
            this.studyList = this.studies.filter(x => x.Status == filterChangeEvent.value);
        }
    }

    /**
     * Click event to mark favorite study
     * @param selectedStudy
     */
    markAsFav(selectedStudy: StudyListModel) {
        this.MarkStudyAsFavorite(selectedStudy.StudyId);
        this.studyList = this.studyList.map(function (study) {
            if (study.StudyId === selectedStudy.StudyId) {
                study.IsFavorite = true;
            }
            return study;
        })
    }

    /**
     * Click event to unmark favorite study
     * @param selectedStudy
     */
    unmarkAsFav(selectedStudy: StudyListModel) {
        this.UnMarkStudyAsFavorite(selectedStudy.StudyId);
        this.studyList = this.studyList.map(function (study) {
            if (study.StudyId === selectedStudy.StudyId) {
                study.IsFavorite = false;
            }
            return study;
        })
    }

    httpRequestResponse: any;

    /**
     * Method to call Service to mark study as Favorite
     * @param studyId
     */
    private MarkStudyAsFavorite(studyId) {
        this.httpRequestResponse = null;
        this.studyService.markStudyAsFavorite(studyId).subscribe(response => this.httpRequestResponse = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: this.httpRequestResponse })
            })
    }

    /**
     * Method to call Service to unmark study as Favorite
     * @param studyId
     */
    private UnMarkStudyAsFavorite(studyId) {
        this.httpRequestResponse = null;
        this.studyService.unmarkStudyAsFavorite(studyId).subscribe(response => this.httpRequestResponse = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: this.httpRequestResponse })
            })
    }

    dataList: StudyListModel[];
    showFilter: boolean = true;
    filterColumns: any[] = [
        { 'Text': 'Date Range', 'Column': 'DateRange', 'Type': 'Dropdown' },
        { 'Text': 'Study Id', 'Column': 'StudyCode', 'Type': 'Text' },
        { 'Text': 'CropName', 'Column': 'CropName', 'Type': 'Dropdown' },
        { 'Text': 'GeneSets', 'Column': 'GeneSets', 'Type': 'Text' },
        { 'Text': 'Status', 'Column': 'Status', 'Type': 'Dropdown' },
        { 'Text': 'PMO Project', 'Column': 'PMOProject', 'Type': 'Text' }
    ];

    //-------------------------------------------------------------
    // Method to set visibility of filter
    //-------------------------------------------------------------
    public setShowFilter() {
        if (this.showFilter) {
            this.showFilter = false;
        }
        else {
            this.showFilter = true;
        }
    }

    //-------------------------------------------------------------
    // Method to clear all the filters
    //-------------------------------------------------------------
    private clearFilter() {
        sessionStorage.setItem('STUDY_FILTER_SELECTION', JSON.stringify({}));
        this.filterColumns.forEach(element => {
            if (element.Type == 'Text') {
                var obj = document.getElementById('input' + element.Column + 'Filter');
                (<HTMLInputElement>obj).value = '';
            }
            this.selectedDateRange = 1;
            this.selectedStatusOption = '';
            this.selectedCropNameOption = '';
            this.studiesToDisplay = "All";
        });
        this.filterService.filter("", "", this.filterColumns, this.studyList, true);
        this.applyDateRangeFilter(this.selectedDateRange, this.studiesToDisplay);
    }

    //----------------------------------------------------------------------
    // Method to filter records based on provided column and its value
    //----------------------------------------------------------------------
    public filter(column, value, filterColumns: any[], studies: StudyListModel[], isClearFilters: boolean) {
        this.studyList = this.filterService.filter(column, value, filterColumns, studies, isClearFilters);
    }

    selectedDateRange: any = 1;
    selectedStatusOption: any = '';
    selectedCropNameOption:any = '';
    selectedGeneSetOption: any = '';
    selectedPMOProjectOption: any = '';
    selectedStudyIdOption:any = '';
    /**
     * Date Range Dropdown filter Change method
     * @param event
     */
    applyDateRangeFilter(noOfBackYears, studiesToShow, isfilter: boolean = false) {
        let isOnlyFavourites: boolean = true;
        let toDate = new Date().toDateString();
        let fromDate = "";

        if (studiesToShow == 'All') {
            isOnlyFavourites = false
        }

        if (noOfBackYears != 'ALL') {
            fromDate = new Date(new Date().setFullYear(new Date().getFullYear() - noOfBackYears)).toDateString();
        }

        this.GetStudyList(fromDate, toDate, isOnlyFavourites,isfilter);
    }

    /**
     * Favourites/All radio button change event method
     * @param val1
     */
    showStudiesRadioChange(val) {
        this.applyDateRangeFilter(this.selectedDateRange, val)
    }

     /**
     * Method to call denodo repo and get evenger request transformation and SendtoGH statuses
     */
     getStudyTransformationAndSendToGHStatuses() {
        this.studyService.getStudyTransformationAndSendToGHStatuses().subscribe(response => this.StudyStatuses = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occured while fetching data. Contact Administrator' })
            },
            () => {
                // separate requests based on status
                var requestsInGreenHouse = this.StudyStatuses?.filter(p => p?.IsT0EventsSendtoGH == true);

                // update study with transformation and sentToGH statuses
                this.studyList.forEach(study => {
                    //study.IsTransformationInitiated = requestsInTranformation.filter(s => s.StudyId == study.StudyId).length == 0 ? false : true;
                    study.IsT0EventsSendtoGH = requestsInGreenHouse?.filter(s => s.StudyId == study.StudyId).length == 0 ? false : true;
                });

                this.doesStudyRequestStatusesAreLoaded = true;
            })
    }

}
