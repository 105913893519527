import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { ConfigurationSettings } from "../.././configuration-settings"
import { UserRoleModel } from 'src/app/Administrator/user-role/user-role.model';

@Injectable()
export class UserRoleService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private _http:HttpClient) { }

  public createUserRole(userrole:UserRoleModel[]):any{
    let url=ConfigurationSettings.REST_API_URL + "/user-roles"
    let body= JSON.stringify(userrole);

    return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Created new UserRole"))
            );
    }

  public getUserRoles():any{
    let url=ConfigurationSettings.REST_API_URL + "/user-roles"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched UserRoles")));
    }

  public updateUserRole(userrole:UserRoleModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/user-roles"
    let body= JSON.stringify(userrole);
    return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Updated UserRole"))
            );
    }
}