/**
 * enums for generations
 */
export enum GenerationConstants {
   T0 = "T0",
   T1 = "T1",
   T2 = "T2",
   T3 = "T3",
   T4 = "T4",
   T5 = "T5"
}
